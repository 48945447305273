export const salesTeamsManagementColumnNames = {
  ID_COLUMN_NAME: 'sales_team_id',
  NAME_COLUMN_NAME: 'name',
  SALES_AREA_ID_COLUMN_NAME: 'sales_area_id',
  SALES_AREA_NAME_COLUMN_NAME: 'sales_area_name',
  VISIBLE_COLUMN_NAME: 'visible',
  DELETED_COLUMN_NAME: 'deleted',
};

export const salesTeamsManagementColumnLabels = {
  [salesTeamsManagementColumnNames.ID_COLUMN_NAME]: 'Team ID',
  [salesTeamsManagementColumnNames.NAME_COLUMN_NAME]: 'Team Name',
  [salesTeamsManagementColumnNames.SALES_AREA_ID_COLUMN_NAME]: 'Area ID',
  [salesTeamsManagementColumnNames.SALES_AREA_NAME_COLUMN_NAME]: 'Area Name',
  [salesTeamsManagementColumnNames.VISIBLE_COLUMN_NAME]: 'Visible',
  [salesTeamsManagementColumnNames.DELETED_COLUMN_NAME]: 'Deleted',
};

export const visibleRadioConfig = [
  { label: 'Visible', value: 1, id: 'visible' },
  { label: 'Not Visible', value: 0, id: 'not_visible' },
];

export const deletedRadioConfig = [
  { label: 'Active', value: 0, id: 'active' },
  { label: 'Deleted', value: 1, id: 'deleted' },
];
