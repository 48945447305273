import { createAsyncAction } from '@/redux/root';

export const nameSpace = '@@/sales-teams';

export const requestSalesTeamsAsync = createAsyncAction(`${nameSpace}/REQUEST_SALES_TEAMS`);

export const requestSalesAreasAsync = createAsyncAction(`${nameSpace}/REQUEST_SALES_AREAS`);

export const createSalesTeamAsync = createAsyncAction(`${nameSpace}/CREATE_SALES_TEAM`);

export const updateSalesTeamAsync = createAsyncAction(`${nameSpace}/UPDATE_SALES_TEAM`);
