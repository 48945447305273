import * as Api from '../api';

const api = process.env.REACT_APP_ONB_API;

export const getSalesTeams = () => Api.get({ path: '/api/v1/teams', api });

export const getSalesTeam = (teamId) => Api.get({ path: `/api/v1/teams/${teamId}`, api });

export const createSalesTeam = () => Api.post({ path: '/api/v1/teams', api });

export const updateSalesTeam = (salesTeamId) => Api.put({ path: `/api/v1/teams/${salesTeamId}`, api });
