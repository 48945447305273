import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ConfirmationModal, TooltipText } from '@/components';
import { requestTeamsAsync, requestClearTeamOutcomesAsync } from '@/redux/teams';
import { sptConstants } from '../../lib';
import FilterableTable from '@/components/table/FilterableTable';
import { Icon } from '@/components/common';
import { useFeatureFlag } from 'configcat-react';
import { dashboardConstants } from '@/lib/constants';
import AreaFilter from './AreaFilter';
import { v4 as uuidv4 } from 'uuid';

const columns = [
  {
    align: 'right',
    width: 32,
    text: sptConstants.TEAM_CODE,
    sortable: true,
    id: 'team_id',
  },
  {
    align: 'left',
    text: sptConstants.TEAM_NAME,
    sortable: true,
    id: 'team_name',
  },
  {
    align: 'right',
    text: sptConstants.AREA_ID,
    sortable: true,
    id: 'area_id',
  },
  {
    align: 'left',
    text: sptConstants.AREA_NAME,
    sortable: true,
    id: 'area_name',
  },
  {
    align: 'right',
    text: sptConstants.TOTAL_REPS,
    sortable: true,
    id: 'total_reps',
  },
  {
    align: 'right',
    text: sptConstants.ACTIVE,
    sortable: true,
    id: 'active_customers',
  },
  {
    align: 'right',
    text: sptConstants.INACTIVE,
    sortable: true,
    id: 'inactive_customers',
  },
  {
    align: 'right',
    text: sptConstants.QUALIFIED_ADDRESSES,
    sortable: true,
    id: 'qualified_addresses',
  },
  {
    align: 'left',
    width: 24,
    text: '',
    sortable: false,
    id: 'actions',
  },
];

const { TEAM_CLEAR_OUTCOMES_ENABLED } = dashboardConstants;

const TeamsTable = () => {
  const dispatch = useDispatch();
  const [areaId, setAreaId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);
  const [sortParams, setSortParams] = useState({ name: 'area_id', order: 'asc' });
  const [clearOutcomesConfirmationOpen, setClearOutcomesConfirmationOpen] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [selectedTeamName, setSelectedTeamName] = useState(null);

  // Selectors
  const loading = useSelector((state) => state.loading?.teams?.isLoading || false);
  const teams = useSelector((state) => state.teams.teams);
  const totalCount = teams?.data?.total || 0;

  // Feature flag
  const { value: isTeamClearOutcomesEnabled } = useFeatureFlag(
    TEAM_CLEAR_OUTCOMES_ENABLED,
    false,
  );

  // Confirmation handler
  const onConfirmationApprove = useCallback(() => {
    setClearOutcomesConfirmationOpen(false);
    dispatch(requestClearTeamOutcomesAsync.request({
      teamId: selectedTeamId,
      teamName: selectedTeamName,
    }));
  }, [dispatch, selectedTeamId, selectedTeamName]);

  // Table update handler
  const updateTable = useCallback(() => {
    const params = {
      order: sortParams.order,
      sort_by: sortParams.name,
      limit: pageSize,
      page: selectedPage,
      ...(searchText && { search: searchText }),
      ...(areaId && { area_id: areaId }),
    };

    dispatch(requestTeamsAsync.request(params));
  }, [dispatch, sortParams, pageSize, selectedPage, searchText, areaId]);

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  const teamRows = useMemo(() => {
    const rows = [];
    const data = teams?.data?.data || [];

    data.forEach((thisRow) => {
      const mapUrl = `/sales-planning/team/${thisRow.team_id}`;
      const polygonUrl = `/sales-planning/polygons/team/${thisRow.team_id}`;
      const hasBoundary = !!thisRow.boundary;

      const actionsCell = (
        <div className="inline-flex gap-4 text-center align-middle whitespace-nowrap right-0">
          {hasBoundary ? (
            <>
              <Link to={mapUrl}>
                <TooltipText
                  id={uuidv4()}
                  text={<Icon className="w-5 h-5 cursor-pointer" icon="viewArea" />}
                  message="View Team"
                />
              </Link>
              <Link to={polygonUrl}>
                <TooltipText
                  id={uuidv4()}
                  text={<Icon className="w-5 h-5 cursor-pointer" icon="drawPolygon" />}
                  message="View Polygons"
                />
              </Link>
            </>
          ) : (
            <>
              <TooltipText
                id={uuidv4()}
                text={<Icon className="w-5 h-5 cursor-not-allowed" icon="viewArea" />}
                message="View Team"
              />
              <TooltipText
                id={uuidv4()}
                text={<Icon className="w-5 h-5 cursor-not-allowed" icon="drawPolygon" />}
                message="View Polygons"
              />
            </>
          )}
          {isTeamClearOutcomesEnabled && (
            <TooltipText
              id={uuidv4()}
              text={
                <Icon
                  className="w-5 h-5 text-gray-600 cursor-pointer"
                  icon="clearOutcomes"
                  onClick={(event) => {
                    event.stopPropagation();
                    setSelectedTeamId(thisRow.team_id);
                    setSelectedTeamName(thisRow.team_name);
                    setClearOutcomesConfirmationOpen(true);
                  }}
                />
              }
              message="Clear Outcomes"
            />
          )}
        </div>
      );

      rows.push([
        thisRow.team_id,
        thisRow.team_name,
        thisRow.area_id,
        thisRow.area_name,
        thisRow.statistics.total_reps,
        thisRow.statistics.active_customers,
        thisRow.statistics.inactive_customers,
        thisRow.statistics.total_qualified_addresses,
        actionsCell,
      ]);
    });

    return rows;
  }, [teams, isTeamClearOutcomesEnabled]);

  return (
    <>
      <ConfirmationModal
        isOpened={clearOutcomesConfirmationOpen}
        modalWidth="max-w-[592px] w-full z-[1]"
        onCancel={() => setClearOutcomesConfirmationOpen(false)}
        onAction={onConfirmationApprove}
        title="Clear Team Outcomes"
        message={`All knocking outcomes will be cleared for Team “${selectedTeamName}”. This process could take multiple hours, consider running after knocking hours. This cannot be undone, would you like to proceed?`}
        cancelLabel="Cancel"
        confirmLabel="Clear Outcomes"
      />

      <FilterableTable
        leftControl={
          <AreaFilter
            loading={loading}
            onAreaSelected={(areaId) => {
              setAreaId(areaId);
              setSelectedPage(1);
            }}
          />
        }
        loading={loading}
        columns={columns}
        rows={teamRows}
        pageSize={pageSize}
        setPageSize={setPageSize}
        sortParams={sortParams}
        onSortChange={setSortParams}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        totalCount={totalCount}
        searchPlaceholder={sptConstants.SEARCH_TEAMS}
      />
    </>
  );
};

export default TeamsTable;
