import { CustomFormElement } from '@/components/common';
import moment from 'moment';
import PropTypes from 'prop-types';

const DateOfBirth = (props) => {
  const {
    id,
    name,
    label,
    error,
    disabled,
    onChange,
    formValue,
    colSpan,
    required,
    tooltipMessage,
    formElementWrapperClassName,
    register,
    onBlur,
  } = props;
  const minAge = 16;
  const maxAge = 100;
  const minDate = moment().subtract(maxAge, 'years').startOf('year').toDate();
  const maxDate = moment().subtract(minAge, 'years').endOf('year').toDate();

  return (
    <CustomFormElement
      id={id}
      name={name}
      label={label}
      tooltipMessage={tooltipMessage}
      colSpan={colSpan}
      minDate={minDate}
      maxDate={maxDate}
      type="date"
      showYearDropdown
      formValue={formValue}
      onChange={onChange}
      register={register}
      error={error}
      required={required}
      disabled={disabled}
      formElementWrapperClassName={formElementWrapperClassName}
      onBlur={onBlur}
    />
  );
};

DateOfBirth.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  formValue: PropTypes.any,
  colSpan: PropTypes.number,
  required: PropTypes.bool,
  tooltipMessage: PropTypes.string,
  formElementWrapperClassName: PropTypes.string,
  register: PropTypes.func,
  onBlur: PropTypes.func,
};

export default DateOfBirth;
