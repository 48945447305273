// TODO: SPT:REMOVE

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { createRequestSaga } from '@/redux/helpers';
import { addErrorAction } from '@/redux/errors';

import Api from '../../api';

import {
  requestAreasAsync,
  requestAreaMapAsync,
  requestAreasListAsync,
  requestClearAreaOutcomesAsync,
} from './actions';
import { addToastsAction } from '@/redux/toasts';
import { mapErrorToastsData } from '@/lib/api';
import { toastType } from '@/components';

function* requestAreasSaga({ payload }) {
  const areas = yield call(
    Api.getAreas,
    { ...payload },
  );

  yield put(requestAreasAsync.success(areas));
}

function* requestAreaMapSaga({ payload }) {
  try {
    const allRequests = [];
    allRequests.push(call(Api.getArea(payload)));
    allRequests.push(call(Api.getAreaZipcodePolygons(payload)));

    const allResponses = yield all(allRequests);
    const data = {
      ...allResponses[0].data,
      ...allResponses[1].data,
    };

    yield put(requestAreaMapAsync.success(data));
  } catch (error) {
    yield put(addErrorAction({ errKey: 'areaMap', error: error.response.data.errors }));
  }
}

function* requestClearAreaOutcomesSaga({ payload }) {
  const { areaId, areaName } = payload;

  try {
    yield call(Api.clearAreaOutcomes(areaId));

    yield put(addToastsAction([{
      type: toastType.SUCCESS,
      message: `Request for clearing outcomes for ${areaName} was accepted`,
      details: null,
    }]));
  } catch (error) {
    yield put(addToastsAction(mapErrorToastsData(error)));
    yield put(requestClearAreaOutcomesAsync.failure(error));
  }
}

function* requestAreasListSaga() {
  const response = yield call(
    Api.getAreasList,
  );

  yield put(requestAreasListAsync.success({ areas: response?.data }));
}

export function* areasActionWatcher() {
  yield takeLatest(
    requestAreasAsync.request.type,
    createRequestSaga(requestAreasSaga, {
      keyNew: 'areas',
      errKey: 'areas',
      write: true,
    }),
  );

  yield takeLatest(
    requestAreaMapAsync.request.type,
    createRequestSaga(requestAreaMapSaga, {
      keyNew: 'areaMap',
      errKey: 'areaMap',
      write: false,
    }),
  );

  yield takeLatest(
    requestAreasListAsync.request.type,
    createRequestSaga(requestAreasListSaga, {
      keyNew: 'areasList',
      errKey: 'areasList',
      write: false,
    }),
  );

  yield takeLatest(
    requestClearAreaOutcomesAsync.request.type,
    createRequestSaga(requestClearAreaOutcomesSaga, {
      keyNew: 'clearOutcomes',
      errKey: 'clearOutcomes',
      write: false,
    }),
  );
}
