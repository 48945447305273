// TODO: SPT:REMOVE

const sptConstants = {
  ACTIVE: 'Active',
  ACTIVE_CUSTOMERS: 'Active Customers',
  ADDRESSES: 'Addresses',
  ALL_AREAS: 'All Areas',
  ASSIGNED_POLYGONS: 'Assigned Polygons',
  CURRENT_PINS: 'Current Pins',
  AREA_ID: 'Area ID',
  AREA_NAME: 'Area Name',
  FILTER_BY_AREAS: 'Filter by areas',
  CLOSE_ALL: 'Close All',
  COLLAPSE: 'Collapse',
  EXPAND: 'Expand',
  EXPAND_ALL: 'Expand All',
  CLUSTERS: 'Clusters',
  FILTER: 'Filter',
  INACTIVE: 'Inactive',
  INACTIVE_CUSTOMERS: 'Inactive Customers',
  QUALIFIED_ADDRESSES: 'Qualified Addresses',
  QUALIFIED_ADDRESSES_MAP_TYPE: 'qualifiedAddresses',
  WEEKS_SINCE_KNOCKED: 'Weeks since knocked',
  WEEKS_SINCE_KNOCKED_MAP_TYPE: 'weeksSinceKnocked',
  PIN_SCORE: 'Pin Score',
  PIN_SCORE_QUARTILE: 'Pin Score Quartile',
  PIN_SCORE_MAP_TYPE: 'pinScore',
  NATIONAL_PIN_SCORE_MAP_TYPE: 'nationalPinScore',
  NONE: 'None',
  NONE_MAP_TYPE: 'none',
  SELECTED_CLUSTERS: 'Selected Clusters',
  LEADER: 'Leader',
  MOVERS: 'Movers',
  PIN_MOVER_TYPE: 'mover',
  PIN_REFINANCED_TYPE: 'refi',
  PIN_PREVIOUS_CUSTOMER_TYPE: 'prev',
  PIN_UNQUALIFIED_TYPE: 'grey',
  PIN_BLACK_TYPE: 'black',
  PIN_RED_CROSS_TYPE: 'redcross',
  CURRENT_CUSTOMER: 'Current Customer',
  RECENTLY_REFINANCED: 'Recent Refinanced',
  NEW_MOVER: 'New Mover',
  PREVIOUS_CUSTOMER: 'Previous Customer',
  PIN_LEGEND_BLACK: 'Do Not Knock (Admin Added)',
  PIN_LEGEND_RED_FLAG: 'Do Not Knock (Rep Added)',
  NUMBER_OF_REPS: '# Reps',
  REPS_ASSIGNED: 'Reps Assigned',
  PERCENTAGE: 'Percentage',
  QUALIFIED_PERCENTAGE: 'Qualified Percentage',
  DISPLAY_OUTCOMES: 'Display outcomes',
  PROSPECTS: 'Prospects',
  TOTAL_REPS: 'Reps',
  PREVIEW: 'preview_polygon',
  SEARCH_AREAS: 'Search Areas',
  SEARCH_TEAMS: 'Search Teams',
  TEAM_CODE: 'Team Code',
  TEAM_NAME: 'Team Name',
  TEAM: 'Team',
  TOTAL_ADDRESSES: 'Total Addresses',
  REPS: 'Reps',
  NO_ASSIGNMENTS_ERROR: 'This team has no zipcode assignments.',
  TEAM_COLORS: [
    '#DC2626',
    '#9E6EE6',
    '#72A1E8',
    '#82D5EB',
    '#72E361',
    '#E4AC71',
    '#B7306E',
    '#1F498C',
    '#622ABF',
    '#FDE047',
  ],
  WEEKS_SINCE_KNOCKED_OPTIONS_LABELS: [
    'Unknocked / Cleared',
    '0-2 weeks',
    '3-5 weeks',
    '6-8 weeks',
    '9+ weeks',
  ],
  WEEKS_SINCE_KNOCKED_OPTIONS_KEYS: [
    'Cleared',
    '0-2',
    '3-5',
    '6-8',
    '9+',
  ],
  QUALIFIED_ADDRESS_CHOROPLETH_SCALE: [
    {
      legend: '1',
      color: '#FF0000',
      fillColor: '#FF0000',
      fillOpacity: 0.4,
      weight: 0.5,
    },
    {
      legend: '2',
      color: '#FF4100',
      fillColor: '#FF4100',
      fillOpacity: 0.4,
      weight: 0.5,
    },
    {
      legend: '3',
      color: '#FF6000',
      fillColor: '#FF6000',
      fillOpacity: 0.4,
      weight: 0.5,
    },
    {
      legend: '4',
      color: '#FF7B00',
      fillColor: '#FF7B00',
      fillOpacity: 0.4,
      weight: 0.5,
    },
    {
      legend: '5',
      color: '#FF9300',
      fillColor: '#FF9300',
      fillOpacity: 0.4,
      weight: 0.5,
    },
    {
      legend: '6',
      color: '#FFAA00',
      fillColor: '#FFAA00',
      fillOpacity: 0.4,
      weight: 0.5,
    },
    {
      legend: '7',
      color: '#FFC000',
      fillColor: '#FFC000',
      fillOpacity: 0.4,
      weight: 0.5,
    },
    {
      legend: '8',
      color: '#FFD600',
      fillColor: '#FFD600',
      fillOpacity: 0.4,
      weight: 0.5,
    },
    {
      legend: '9',
      color: '#FFEB00',
      fillColor: '#FFEB00',
      fillOpacity: 0.4,
      weight: 0.5,
    },
    {
      legend: '10',
      color: '#FFFF00',
      fillColor: '#FFFF00',
      fillOpacity: 0.4,
      weight: 0.5,
    },
  ],
  WEEKS_SINCE_KNOCKED_CHOROPLETH_SCALE: [
    {
      legend: 'Unknocked',
      color: '#2B8BC3',
      fillColor: '#2B8BC3',
      fillOpacity: 0.4,
      weight: 0.5,
    },
    {
      legend: '0-2 weeks',
      color: '#0000FF',
      fillColor: '#0000FF',
      fillOpacity: 0.4,
      weight: 0.5,
    },
    {
      legend: '3-5 weeks',
      color: '#FFA500',
      fillColor: '#FFA500',
      fillOpacity: 0.4,
      weight: 0.5,
    },
    {
      legend: '6-8 weeks',
      color: '#008000',
      fillColor: '#008000',
      fillOpacity: 0.4,
      weight: 0.5,
    },
    {
      legend: '9+ weeks',
      color: '#FF0000',
      fillColor: '#FF0000',
      fillOpacity: 0.4,
      weight: 0.5,
    },
  ],
  QUALIFIED_AREAS_CHOROPLETH_SCALE: [
    {
      legend: '1',
      quartile: 1,
      fillColor: '#009BF0',
      fillOpacity: 0.6,
      color: '#FFFFFF',
      weight: 0.4,
    },
    {
      legend: '2',
      quartile: 2,
      fillColor: '#047481',
      fillOpacity: 0.6,
      color: '#FFFFFF',
      weight: 0.4,
    },
    {
      legend: '3',
      quartile: 3,
      fillColor: '#FF7F50',
      fillOpacity: 0.6,
      color: '#FFFFFF',
      weight: 0.4,
    },
    {
      legend: '4',
      quartile: 4,
      fillColor: '#FC0F00',
      fillOpacity: 0.6,
      color: '#FFFFFF',
      weight: 0.4,
    },
    {
      legend: 'Unqualified',
      quartile: 0,
      fillColor: '#808080',
      fillOpacity: 0.6,
      color: '#FFFFFF',
      weight: 0.4,
    },
  ],
  NATIONAL_CHOROPLETH_SCALE: [
    {
      legend: '1',
      quartile: 1,
      fillColor: '#009BF0',
      fillOpacity: 0.6,
      color: '#FFFFFF',
      weight: 0.4,
    },
    {
      legend: '2',
      quartile: 2,
      fillColor: '#047481',
      fillOpacity: 0.6,
      color: '#FFFFFF',
      weight: 0.4,
    },
    {
      legend: '3',
      quartile: 3,
      fillColor: '#FF7F50',
      fillOpacity: 0.6,
      color: '#FFFFFF',
      weight: 0.4,
    },
    {
      legend: '4',
      quartile: 4,
      fillColor: '#FC0F00',
      fillOpacity: 0.6,
      color: '#FFFFFF',
      weight: 0.4,
    },
    {
      legend: 'Unqualified',
      quartile: 0,
      fillColor: '#808080',
      fillOpacity: 0.6,
      color: '#FFFFFF',
      weight: 0.4,
    },
  ],
  TEAM_PINS: [
    {
      legend: 'Qualified address',
      isQualified: true,
      stroke: '#1C64F2',
      fill: '#56BBF2',
    },
    {
      legend: 'Unqualified address',
      isQualified: false,
      stroke: '#333333',
      fill: '#ABABAB',
    },
    {
      legend: 'Sold',
      isQualified: true,
      stroke: '#2A870E',
      fill: '#68CB4A',
    },
  ],
  OUTCOME_PINS: [
    {
      legend: 'Qualified',
      color: 'blue',
      stroke: '#1C64F2',
      fill: '#56BBF2',
    },
    {
      legend: 'Come back later',
      color: 'purple',
      stroke: '#57378E',
      fill: '#805BBF',
    },
    {
      legend: 'Sold',
      color: 'green',
      stroke: '#44952B',
      fill: '#68CB4A',
    },
    {
      legend: '2nd Knock',
      color: 'yellow-2',
      stroke: '#9F580A',
      fill: '#EF840F',
    },
    {
      legend: '3rd Knock',
      color: 'yellow-3',
      stroke: '#EF840F',
      fill: '#9F580A',
    },
    {
      legend: 'Not Home',
      color: 'yellow',
      stroke: '#C27803',
      fill: '#FCE96A',
    },
    {
      legend: 'Other',
      color: 'red',
      stroke: '#DF2517',
      fill: '#EE675D',
    },
    {
      legend: 'Commercial',
      color: 'pink',
      stroke: '#E9318E',
      fill: '#F17EB8',
    },
    {
      color: 'grey',
      stroke: '#818181',
      fill: '#ABABAB',
    },
  ],
  OUTCOME_PIN_TYPES: [
    {
      name: 'Default',
      type: null,
      hasNote: false,
    },
    {
      name: 'Note added',
      type: null,
      hasNote: true,
    },
    {
      name: 'Unqualified address',
      type: 'grey',
      hasNote: false,
    },
  ],
  QUALIFIED_AREAS_PINS: [
    {
      legend: '1',
      quartile: 1,
      stroke: '#1C64F2',
      fill: '#009BF0',
    },
    {
      legend: '2',
      quartile: 2,
      stroke: '#04535c',
      fill: '#047481',
    },
    {
      legend: '3',
      quartile: 3,
      stroke: '#FF7F50',
      fill: '#FFB296',
    },
    {
      legend: '4',
      quartile: 4,
      stroke: '#870900',
      fill: '#FC0F00',
    },
    {
      legend: 'Unqualified',
      quartile: 0,
      stroke: '#333333',
      fill: '#ABABAB',
    },
  ],
  NATIONAL_PINS: [
    {
      legend: '1',
      quartile: 1,
      stroke: '#1C64F2',
      fill: '#009BF0',
    },
    {
      legend: '2',
      quartile: 2,
      stroke: '#04535c',
      fill: '#047481',
    },
    {
      legend: '3',
      quartile: 3,
      stroke: '#FF7F50',
      fill: '#FFB296',
    },
    {
      legend: '4',
      quartile: 4,
      stroke: '#870900',
      fill: '#FC0F00',
    },
    {
      legend: 'Unqualified',
      quartile: 0,
      stroke: '#FFFFFF',
      fill: '#808080',
    },
  ],
  POLYGONS: [
    {
      legend: 'Deactivated',
      color: '#4A4A4A',
    },
    {
      legend: 'Assigned',
      color: '#008B33',
    },
    {
      legend: 'Unassigned',
      color: '#8430B7',
    },
  ],
  TEAM_COLOR_UNASSIGNED: '#808080',
  POLYGON_COLOR_ASSIGNED: '#008B33',
  POLYGON_COLOR_UNASSIGNED: '#8430B7',
  POLYGON_COLOR_DEACTIVATED: '#4a4a4a',
  POLYGON_SELECTED: '#f5cb42',
  TOP_10_PERCENTAGE: 'Top 10 Percentage',
  TOP_15_PERCENTAGE: 'Top 15 Percentage',
  VIEW_MAP: 'View Map',
  VIEW_AREAS: 'View Areas',
  VIEW_TEAMS: 'View Teams',
  VIEW_POLYGONS: 'View Polygons',
  CLEAR_OUTCOMES: 'Clear Outcomes',
  ZIP_COUNT: 'Zip Count',
  OPEN_STREET_MAP: 'OpenStreetMap',
  GOOGLE_MAPS: 'Google Maps',
  GOOGLE_HYBRID: 'Google Hybrid',
  GOOGLE_SATELLITE: 'Google Satellite',
  HIDE_PINS_LABEL: 'Hide Pins',
};

export default sptConstants;
