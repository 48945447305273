import { createReducer } from '@/redux/root';
import { nameSpace, requestSalesAreasAsync } from '@/redux/sales-areas';

const manageSalesAreasInitialState = {
  salesAreas: [],
  salesAreasTotal: 0,
};

export const manageSalesAreasReducer = createReducer(nameSpace, manageSalesAreasInitialState, {
  [requestSalesAreasAsync.success]: ({ state, action: { payload } }) => {
    state.salesAreas = payload.data.map(({ attributes }) => ({
      sales_area_id: attributes.area_id,
      ...attributes,
    }));
    state.salesAreasTotal = payload.meta.total;
  },
});

