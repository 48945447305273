// TODO: SPT:REMOVE

import { call, put, select, all, takeLatest } from 'redux-saga/effects';
import { createRequestSaga } from '@/redux/helpers';
import { addToastsAction } from '@/redux/toasts';
import { toastType } from '@/components';

import Api from '../../api';

import {
  assignTeamZipsAsync,
  requestRepsListAsync,
  requestTeamAsync,
  requestTeamCellsAsync,
  requestTeamPinsAsync,
  requestTeamsAsync,
  requestTeamsListAsync,
  requestTeamStatisticsAsync,
  unassignTeamZipsAsync,
  requestClearTeamOutcomesAsync,
  requestDeactivateTeamPolygonsAsync,
  requestDeleteTeamPolygonsAsync,
} from './actions';

import { addErrorAction } from '@/redux/errors';
import { sptConstants } from '@/modules/SalesPlanning/lib';
import { mapErrorToastsData } from '@/lib/api';

function* requestRepsListSaga({ payload }) {
  try {
    const teamMap = yield call(
      Api.getRepsList(payload),
    );

    yield put(requestRepsListAsync.success(teamMap));
  } catch (error) {
    yield put(addErrorAction({ errKey: 'teamReps', error: error.response.data.errors }));
  }
}

function* requestTeamSaga({ payload }) {
  try {
    const teams = yield call(
      Api.getTeam(payload),
    );

    if (teams.data.boundary) {
      yield put(requestTeamAsync.success(teams));
    } else {
      // No boundary means no assignments and an error must be thrown
      yield put(addErrorAction({ errKey: 'team', error: sptConstants.NO_ASSIGNMENTS_ERROR }));
    }
  } catch (error) {
    yield put(addErrorAction({ errKey: 'team', error: error.response.data.errors }));
  }
}

function* requestTeamsSaga({ payload }) {
  const teams = yield call(
    Api.getTeams,
    { ...payload },
  );

  yield put(requestTeamsAsync.success(teams));
}

function* requestTeamCellsSaga({ payload }) {
  const limit = 4000;

  try {
    const { team_id, resolution, boundary } = payload;
    const body = {
      ...(typeof resolution !== 'undefined' && { resolution: resolution }),
      ...(typeof boundary !== 'undefined' && {
        boundary: {
          'type': 'Polygon',
          'coordinates': boundary,
        },
      }),
      limit,
      page: 1,
    };

    const firstPage = yield call(
      Api.getTeamCells(team_id),
      body,
    );

    const totalPages = firstPage.data.total_pages;

    let allCellsFeatures = firstPage?.data?.boundary.features ?? [];

    if (firstPage.data.total_pages > 1) {
      const requests = [];
      for (let page = 2; page <= totalPages; page++) {
        requests.push(call(
          Api.getTeamCells(team_id),
          { ...body, page: page },
        ));
      }

      const teamCellsList = yield all(requests);

      for (const teamCellsSet of teamCellsList) {
        allCellsFeatures = allCellsFeatures.concat(teamCellsSet?.data?.boundary.features);
      }
    }

    const data = {
      boundary: {
        type: firstPage?.data.boundary?.type,
        features: allCellsFeatures,
      },
      center: firstPage?.data.center,
    };

    yield put(requestTeamCellsAsync.success(data));
  } catch (error) {
    yield put(addErrorAction({ errKey: 'teamCells', error: error.response.data.errors }));
  }
}

function* requestTeamPinsSaga({ payload }) {
  try {
    const { team_id, boundary } = payload;
    const body = {
      ...(typeof boundary !== 'undefined' && {
        boundary: boundary,
      }),
    };

    const { data } = yield call(
      Api.getTeamPins(team_id),
      body,
    );

    yield put(requestTeamPinsAsync.success(data));
  } catch (error) {
    yield put(addErrorAction({ errKey: 'teamPins', error: error.response.data.errors }));
  }
}

function* requestTeamsListSaga({ payload }) {
  try {
    const teamsList = yield call(
      Api.getTeamsList,
      { ...payload },
    );

    yield put(requestTeamsListAsync.success(teamsList));

    for (const teamData of teamsList.data) {
      const teamStatistics = yield call(
        Api.getTeamsStatistics(teamData.team_id),
      );

      yield put(requestTeamStatisticsAsync.success(teamStatistics));
    }
  } catch (error) {
    yield put(addErrorAction({ errKey: 'teamsList', error: error.response.data.errors }));
  }
}

function* requestTeamStatisticsSaga({ payload }) {
  const { team_id } = payload;
  const teamStatistics = yield call(
    Api.getTeamsStatistics(team_id),
  );

  yield put(requestTeamStatisticsAsync.success(teamStatistics));
}

const getAreaTeams = (state) => state.teams.areaTeams;
function* loadTeamStatistics() {
  const areaTeams = yield select(getAreaTeams);
  for (const teamId in areaTeams) {
    const teamStatistics = yield call(
      Api.getTeamsStatistics(teamId),
    );

    yield put(requestTeamStatisticsAsync.success(teamStatistics));
  }
}

function* assignTeamZipsSaga({ payload }) {
  try {
    const response = yield call(
      Api.assignTeamZips(payload.team),
      { zips: payload.zips },
    );
    yield put(assignTeamZipsAsync.success(response));

    yield loadTeamStatistics();
    yield call(payload.callback(response));
  } catch (error) {
    yield put(addErrorAction({ errKey: 'teamZips', error: error.response.data.errors }));
  }
}

function* unassignTeamZipsSaga({ payload }) {
  try {
    const response = yield call(
      Api.unassignTeamZips(payload.team),
      { zips: payload.zips },
    );
    yield put(unassignTeamZipsAsync.success(response));

    yield loadTeamStatistics();
    yield call(payload.callback(response));
  } catch (error) {
    yield put(addErrorAction({ errKey: 'teamZips', error: error.response.data.errors }));
  }
}

function* requestClearTeamOutcomesSaga({ payload }) {
  const { teamId, teamName } = payload;

  try {
    yield call(Api.clearTeamOutcomes(teamId));

    yield put(addToastsAction([{
      type: toastType.SUCCESS,
      message: `Request for clearing outcomes for ${teamName} was accepted`,
      details: null,
    }]));
  } catch (error) {
    yield put(addToastsAction(mapErrorToastsData(error)));
  }
}

function* deactivateTeamPolygonsSaga({ payload }) {
  const { team_id, callback } = payload;

  try {
    yield call(
      Api.deactivateTeamPolygons(team_id),
    );

    yield put(addToastsAction([{
      type: toastType.SUCCESS,
      message: 'Polygons have been successfully deactivated.',
      details: null,
    }]));
    yield call(callback);
  } catch (error) {
    yield put(addToastsAction([{
      type: toastType.ERROR,
      message: 'Failed to deactivate polygons.',
      details: null,
    }]));
    yield call(callback);
  }
}

function* deleteTeamPolygonsSaga({ payload }) {
  const { team_id, callback } = payload;

  try {
    yield call(
      Api.deleteTeamPolygons(team_id),
    );

    yield put(addToastsAction([{
      type: toastType.SUCCESS,
      message: 'Polygons have been successfully deleted.',
      details: null,
    }]));
    yield call(callback);
  } catch (error) {
    yield put(addToastsAction([{
      type: toastType.ERROR,
      message: 'Failed to delete polygons.',
      details: null,
    }]));
    yield call(callback);
  }
}

export function* teamsActionWatcher() {
  yield takeLatest(
    requestRepsListAsync.request.type,
    createRequestSaga(requestRepsListSaga, {
      keyNew: 'repsList',
      errKey: 'repsList',
      write: false,
    }),
  );

  yield takeLatest(
    requestTeamAsync.request.type,
    createRequestSaga(requestTeamSaga, {
      keyNew: 'team',
      errKey: 'team',
      write: false,
    }),
  );

  yield takeLatest(
    requestTeamsAsync.request.type,
    createRequestSaga(requestTeamsSaga, {
      keyNew: 'teams',
      errKey: 'teams',
      write: false,
    }),
  );

  yield takeLatest(
    requestTeamCellsAsync.request.type,
    createRequestSaga(requestTeamCellsSaga, {
      keyNew: 'teamCells',
      errKey: 'teamCells',
      write: false,
    }),
  );

  yield takeLatest(
    requestTeamPinsAsync.request.type,
    createRequestSaga(requestTeamPinsSaga, {
      keyNew: 'teamPins',
      errKey: 'teamPins',
      write: false,
    }),
  );

  yield takeLatest(
    requestTeamsListAsync.request.type,
    createRequestSaga(requestTeamsListSaga, {
      keyNew: 'teams',
      errKey: 'teams',
      write: false,
    }),
  );

  yield takeLatest(
    requestTeamStatisticsAsync.request.type,
    createRequestSaga(requestTeamStatisticsSaga, {
      keyNew: 'teams',
      errKey: 'teams',
      write: false,
    }),
  );

  yield takeLatest(
    assignTeamZipsAsync.request.type,
    createRequestSaga(assignTeamZipsSaga, {
      keyNew: 'teamZips',
      errKey: 'teamZips',
      write: false,
    }),
  );

  yield takeLatest(
    unassignTeamZipsAsync.request.type,
    createRequestSaga(unassignTeamZipsSaga, {
      keyNew: 'teamZips',
      errKey: 'teamZips',
      write: false,
    }),
  );

  yield takeLatest(
    requestClearTeamOutcomesAsync.request.type,
    createRequestSaga(requestClearTeamOutcomesSaga, {
      keyNew: 'clearOutcomes',
      errKey: 'clearOutcomes',
      write: false,
    }),
  );

  yield takeLatest(
    requestDeactivateTeamPolygonsAsync.request.type,
    createRequestSaga(deactivateTeamPolygonsSaga, {
      keyNew: 'deactivateTeamPolygons',
      errKey: 'deactivateTeamPolygons',
      write: false,
    }),
  );

  yield takeLatest(
    requestDeleteTeamPolygonsAsync.request.type,
    createRequestSaga(deleteTeamPolygonsSaga, {
      keyNew: 'deleteTeamPolygons',
      errKey: 'deleteTeamPolygons',
      write: false,
    }),
  );
}
