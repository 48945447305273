import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { sptConstants } from '../../lib';
import { areasSelector, areasTotalSelector, requestAreasAsync, requestClearAreaOutcomesAsync } from '@/redux/areas-new';
import FilterableTable from '@/components/table/FilterableTable';
import { Icon, TooltipText } from '@/components/common';
import { ConfirmationModal } from '@/components';
import { areasLoadingSelector } from '@/redux/loading';
import { useFeatureFlag } from 'configcat-react';
import { dashboardConstants } from '@/lib/constants';
import { v4 as uuidv4 } from 'uuid';

const columns = [
  {
    align: 'right',
    width: 24,
    text: sptConstants.AREA_ID,
    sortable: true,
    id: 'area_id',
  },
  {
    align: 'left',
    text: sptConstants.AREA_NAME,
    sortable: true,
    id: 'area_name',
  },
  {
    align: 'right',
    text: sptConstants.ACTIVE,
    sortable: true,
    id: 'active_customers',
  },
  {
    align: 'right',
    text: sptConstants.INACTIVE,
    sortable: true,
    id: 'inactive_customers',
  },
  {
    align: 'right',
    text: sptConstants.QUALIFIED_ADDRESSES,
    sortable: true,
    id: 'qualified_addresses',
  },
  {
    align: 'left',
    width: 24,
    text: '',
    sortable: false,
    id: 'actions',
  },
];

const {
  AREA_CLEAR_OUTCOMES_ENABLED,
} = dashboardConstants;

const AreasTable = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);
  const [sortParams, setSortParams] = useState({ name: 'area_id', order: 'asc' });
  const [clearOutcomesConfirmationOpen, setClearOutcomesConfirmationOpen] = useState(false);
  const [selectedAreaId, setSelectedAreaId] = useState(null);
  const [selectedAreaName, setSelectedAreaName] = useState(null);

  const areas = useSelector(areasSelector);
  const areasTotal = useSelector(areasTotalSelector);
  const areAreasLoading = useSelector(areasLoadingSelector);

  const { value: isAreaClearOutcomesEnabled } = useFeatureFlag(
    AREA_CLEAR_OUTCOMES_ENABLED,
    false,
  );

  const onConfirmationApprove = useCallback(() => {
    setClearOutcomesConfirmationOpen(false);

    dispatch(requestClearAreaOutcomesAsync.request({ areaId: selectedAreaId, areaName: selectedAreaName }));
  }, [dispatch, setClearOutcomesConfirmationOpen, selectedAreaId, selectedAreaName]);

  const updateTable = useCallback(() => {
    const params = {
      order: sortParams.order,
      sort_by: sortParams.name,
      limit: pageSize,
      page: selectedPage,
    };

    if (searchText) {
      params.search = searchText;
    }

    dispatch(requestAreasAsync.request(params));
  }, [dispatch, pageSize, searchText, selectedPage, sortParams.name, sortParams.order]);

  const openClearOutcomesConfirmation = useCallback((areaId, areaName) => {
    setSelectedAreaId(areaId);
    setSelectedAreaName(areaName);

    setClearOutcomesConfirmationOpen(true);
  }, [setSelectedAreaId, setSelectedAreaName, setClearOutcomesConfirmationOpen]);

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  const areaRows = useMemo(() => {
    const rows = [];

    areas?.map((thisArea) => {
      const {
        area_id,
        area_name,
        statistics: { active_customers, inactive_customers, total_qualified_addresses } = {},
      } = thisArea ?? {};
      const mapUrl = `/sales-planning/area/${thisArea.area_id}`;
      const polygonUrl = `/sales-planning/polygons/area/${thisArea.area_id}`;

      rows.push([
        area_id,
        area_name,
        active_customers,
        inactive_customers,
        total_qualified_addresses,
        <div key={area_id} className="inline-flex gap-4 text-center align-middle whitespace-nowrap right-0">
          <Link to={mapUrl}>
            <TooltipText
              id={uuidv4()}
              text={<Icon className="w-5 h-5 cursor-pointer" icon="viewArea" />}
              message="View Area"
            />
          </Link>
          <Link to={polygonUrl}>
            <TooltipText
              id={uuidv4()}
              text={<Icon className="w-5 h-5 cursor-pointer" icon="drawPolygon" />}
              message="View Polygons"
            />
          </Link>
          {isAreaClearOutcomesEnabled && (
            <TooltipText
              id={uuidv4()}
              text={
                <Icon
                  className="w-5 h-5 cursor-pointer"
                  icon="clearOutcomes"
                  onClick={(event) => {
                    event.stopPropagation();
                    openClearOutcomesConfirmation(area_id, area_name);
                  }}
                />
              }
              message="Clear Outcomes"
            />
          )}
        </div>,
      ]);
    });

    return rows;
  }, [areas, openClearOutcomesConfirmation, isAreaClearOutcomesEnabled]);

  return (
    <>
      <ConfirmationModal
        isOpened={clearOutcomesConfirmationOpen}
        modalWidth="max-w-[592px] w-full z-[1]"
        onCancel={() => setClearOutcomesConfirmationOpen(false)}
        onAction={onConfirmationApprove}
        title="Clear Outcomes"
        message={`All knocking outcomes will be cleared for Area “${selectedAreaName}”. This process could take multiple hours, consider running after knocking hours. This cannot be undone, would you like to proceed?`}
        cancelLabel="Cancel"
        confirmLabel="Clear Outcomes"
      />
      <FilterableTable
        loading={areAreasLoading}
        columns={columns}
        rows={areaRows}
        pageSize={pageSize}
        setPageSize={setPageSize}
        sortParams={sortParams}
        onSortChange={setSortParams}
        searchText={searchText}
        searchPlaceholder={sptConstants.SEARCH_AREAS}
        setSearchText={setSearchText}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        totalCount={areasTotal}
      />
    </>
  );
};

export default AreasTable;
