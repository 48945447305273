import { useState, useCallback, useEffect, useRef } from 'react';
import { addFsExcludeClass } from '@/lib/utils';
import { CustomFormElement } from '@/components';
import NotesFooter from './NotesFooter';
import NotesHeader from './NotesHeader';
import NoteCard from './NoteCard';
import { PaperAirplaneIcon } from '@heroicons/react/outline';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestLedgerNotesAsync,
  createNoteAsync,
  ledgerNotesSelector,
} from '@/modules/Housing/redux/ledger';
import PropTypes from 'prop-types';

const NotesForm = ({ ledgerId, note, setNoteValue }) => {
  const dispatch = useDispatch();
  const ledgerNotes = useSelector(ledgerNotesSelector);
  const notesContainerRef = useRef(null);
  const [noteSubmitted, setNoteSubmitted] = useState(false);

  useEffect(() => {
    if (ledgerId) {
      dispatch(requestLedgerNotesAsync.request({ ledgerId }));
    }
  }, [ledgerId, dispatch]);

  useEffect(() => {
    if (noteSubmitted && notesContainerRef.current) {
      notesContainerRef.current.scrollTop = notesContainerRef.current.scrollHeight;
      setNoteSubmitted(false);
    }
  }, [ledgerNotes, noteSubmitted]);

  const submitNote = useCallback(() => {
    if (note?.trim() && ledgerId) {
      dispatch(createNoteAsync.request({
        data: { ledgerRecordId: ledgerId, note: note },
        successCallback: () => {
          setNoteValue('');
          setNoteSubmitted(true);
        },
      }));
    }
  }, [dispatch, ledgerId, note, setNoteValue]);

  const handleKeydown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submitNote();
    }
  };

  return (
    <div className="relative h-full">
      <NotesHeader />
      <div ref={notesContainerRef} className="sm:h-[334px] overflow-y-auto">
        {ledgerNotes?.map((note) => (
          <NoteCard
            key={note.note_id}
            note={note.note}
            author={note.creator_name}
            at_created={note.created_at}
          />
        ))}
      </div>
      <NotesFooter>
        <div className="relative">
          <CustomFormElement
            colSpan={3}
            id="note"
            name="note"
            type="text"
            value={note}
            onChange={({ target: { value } }) => setNoteValue(value)}
            onKeyDown={handleKeydown}
            placeholder="Add a note"
            className={addFsExcludeClass('pr-10')}
          />
          {ledgerId && (
            <div
              className="absolute py-3 px-4 right-0 top-0 cursor-pointer"
              onClick={submitNote}
            >
              <PaperAirplaneIcon color="gray" className="h-4 w-4" />
            </div>
          )}
        </div>
      </NotesFooter>
    </div>
  );
};

NotesForm.propTypes = {
  ledgerId: PropTypes.number,
  note: PropTypes.string,
  setNoteValue: PropTypes.func,
};

export default NotesForm;
