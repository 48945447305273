// TODO: SPT:REMOVE

import { createAsyncAction } from '@/redux/root';

export const nameSpace = '@@/areas-new';

export const requestAreasAsync = createAsyncAction(`${nameSpace}/REQUEST_AREAS`);

export const requestAreaMapAsync = createAsyncAction(`${nameSpace}/REQUEST_AREA_MAP`);

export const requestAreasListAsync = createAsyncAction(`${nameSpace}/REQUEST_AREAS_LIST`);

export const requestClearAreaOutcomesAsync = createAsyncAction(`${nameSpace}/REQUEST_CLEAR_AREA_OUTCOMES`);
