// TODO: SPT:REMOVE

import { CustomButton, Icon, TooltipText } from '@/components';
import { DRAW_MODE } from '../../lib';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const LassoControl = ({ drawMode, changeDrawMode, icon, className }) => {
  return (
    <CustomButton
      onClick={changeDrawMode}
      color={drawMode === DRAW_MODE.CREATE ? 'green' : 'white'}
      className={classNames(
        className,
        'border-2 border-black/25 rounded focus:ring-0 focus:ring-offset-0',
      )}
    >
      <TooltipText
        id="draw-polygon"
        text={<Icon icon={icon ? icon : 'lasso'} />}
        message="Draw Polygon"
      />
    </CustomButton>
  );
};

LassoControl.propTypes = {
  drawMode: PropTypes.number,
  changeDrawMode: PropTypes.func,
  icon: PropTypes.string,
  className: PropTypes.string,
};

export default LassoControl;
