import { EyeIcon, DownloadIcon } from '@heroicons/react/outline';
import ReactTooltip from 'react-tooltip';
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ImageModal, Icon } from '@/components';

const FilePreview = ({
  displayText,
  previewSrc,
  isFileLoading,
  cursorClass,
  onDeleteClick,
  disabled,
}) => {
  const [imageOpen, setImageOpen] = useState(false);

  const handleViewClick = useCallback(() => {
    if (!isFileLoading) {
      setImageOpen(true);
    }
  }, []);

  const handleDownloadClick = useCallback(() => {
    if (previewSrc && displayText && !isFileLoading) {
      const link = document.createElement('a');
      link.href = previewSrc;
      link.download = displayText;
      link.click();
    }
  }, [previewSrc, displayText]);

  const handleImageClose = useCallback(() => {
    setImageOpen(false);
  }, []);

  return (
    displayText && (
      <div className="flex justify-between items-center border-solid border bg-gray-50 border-gray-200 rounded-lg mt-4">
        <div className="px-4 text-sm font-medium overflow-auto no-scrollbar py-4">{displayText}</div>
        <div className="flex items-center px-4 gap-4 border-l h-full rounded-r-lg py-4">
          <div data-tip="View">
            <EyeIcon
              className={`w-5 h-5 fill-none stroke-gray-400 m-auto ${cursorClass}`}
              {...(!isFileLoading && { onClick: handleViewClick })}
            />
          </div>
          <div data-tip="Download">
            <DownloadIcon
              className={`w-5 h-5 fill-none stroke-gray-400 m-auto ${cursorClass}`}
              {...(!isFileLoading && { onClick: handleDownloadClick })}
            />
          </div>
          <div data-tip="Replace">
            <Icon
              icon="replaceIcon"
              className={`w-4 h-4 text-gray-400 m-auto ${cursorClass} ${disabled ? 'pointer-events-none' : ''}`}
              {...(!isFileLoading && !disabled && { onClick: onDeleteClick })}
            />
          </div>
          <ReactTooltip place="top" type="dark" effect="solid" />
        </div>
        {previewSrc && imageOpen ? (
          <ImageModal image={previewSrc} title={displayText} onClose={handleImageClose} isOpen={imageOpen} />
        ) : null}
      </div>
    )
  );
};

FilePreview.propTypes = {
  displayText: PropTypes.string,
  previewSrc: PropTypes.string,
  isFileLoading: PropTypes.bool,
  cursorClass: PropTypes.string,
  onDeleteClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default FilePreview;
