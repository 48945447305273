import { DropdownButton } from '@/modules/Housing/components/common';
import { CustomButton, Loader } from '@/components/common';
import SeasonFilter from '../components/SeasonFilter';
import LedgersTable from '../components/LedgersTable';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
  isExportLedgersLoadingSelector,
  isImportLedgersLoadingSelector,
  isImportLedgersTemplateLoadingSelector,
  isLedgerListLoadingSelector,
} from '@/modules/Housing/redux/loading';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import LedgerModal from '../components/Ledger/LedgerModal';
import {
  importLedgersAsync,
  importLedgersTemplateAsync,
  exportLedgersAsync,
  resetSelectedLedgerAction,
  requestLedgersAsync,
  ledgerListSelector,
  ledgerTotalSelector,
} from '@/modules/Housing/redux/ledger';
import {
  requestBranchesSummariesAsync,
  requestTeamsSummariesAsync,
  branchesSummariesSelector,
  teamsSummariesSelector,
} from '@/modules/Housing/redux/area';
import {
  requestDealersAsync,
  requestPartnershipsAsync,
  dealersSelector,
  partnershipsSelector,
} from '@/modules/Housing/redux/partnership';
import {
  requestPaymentTypesAsync,
  requestComplexSummariesAsync,
  requestPaymentMethodsAsync,
  complexSummariesSelector,
  paymentMethodsSelector,
  paymentTypesSelector,
} from '@/modules/Housing/redux/apartment';
import { parseFilters } from '@/modules/Housing/modules/Ledger/lib';
import { seasonSummariesSelector, requestSeasonSummaries } from '@/modules/Housing/redux/season';
import { useSortTable } from '@/hooks';

const initialPageSize = 10;
const initialPage = 1;
const downloadOptions = [
  {
    format: 'csv',
    label: 'Download CSV',
    action: exportLedgersAsync.request,
  },
  {
    format: 'xlsx',
    label: 'Download Excel',
    action: exportLedgersAsync.request,
  },
  {
    format: 'csv',
    label: 'Download CSV Import Template',
    action: importLedgersTemplateAsync.request,
  },
  {
    format: 'xlsx',
    label: 'Download Excel Import Template',
    action: importLedgersTemplateAsync.request,
  },
];
const uploadOptions = [
  {
    format: 'csv',
    mimetype: 'text/csv,text/plain',
    label: 'Upload CSV',
  },
  {
    format: 'xlsx',
    mimetype:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    label: 'Upload Excel',
  },
];

const Ledger = ({ archived }) => {
  const dispatch = useDispatch();

  const [selectedFileOption, setSelectedFileOption] = useState('');
  const [triggerUpload, setTriggerUpload] = useState(false);
  const fileInput = useRef();

  const [pageSize, setPageSize] = useState(initialPageSize);
  const [selectedPage, setSelectedPage] = useState(initialPage);
  const [filters, setFilters] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [recruitingSeasonId, setRecruitingSeasonId] = useState();
  const { getSortParam, sortQueryParams, onSortParamsChange } = useSortTable();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const seasonOptions = useSelector(seasonSummariesSelector);
  const branches = useSelector(branchesSummariesSelector);
  const teams = useSelector(teamsSummariesSelector);
  const dealers = useSelector(dealersSelector);
  const partnerships = useSelector(partnershipsSelector);
  const typeOfPayments = useSelector(paymentTypesSelector);
  const complexes = useSelector(complexSummariesSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);

  const ledgersTotal = useSelector(ledgerTotalSelector);
  const ledgers = useSelector(ledgerListSelector);

  const importLedgersLoading = useSelector(isImportLedgersLoadingSelector);
  const exportLedgersLoading = useSelector(isExportLedgersLoadingSelector);
  const importLedgersTemplateLoading = useSelector(isImportLedgersTemplateLoadingSelector);
  const isLedgerTableLoading = useSelector(isLedgerListLoadingSelector);

  const baseParams = useMemo(() => ({
    archived,
    ...(sortQueryParams && { sort: sortQueryParams }),
    ...parseFilters(filters),
    ...(searchValue && { search_query: searchValue }),
    ...(recruitingSeasonId && { recruiting_season_id: recruitingSeasonId }),
  }), [archived, sortQueryParams, filters, searchValue, recruitingSeasonId]);

  useEffect(() => {
    const params = {
      page: {
        number: selectedPage,
        size: pageSize,
      },
      ...baseParams,
    };
    dispatch(requestLedgersAsync.request(params));
  }, [dispatch, baseParams, selectedPage, pageSize]);

  // Reset to page 1 when filters/search change (baseParams) or page size changes
  useEffect(() => {
    setSelectedPage(initialPage);
  }, [baseParams, pageSize]);

  useEffect(() => {
    dispatch(requestSeasonSummaries.request());
    dispatch(requestDealersAsync.request());
    dispatch(requestBranchesSummariesAsync.request());
    dispatch(requestPaymentTypesAsync.request());
    dispatch(requestPaymentMethodsAsync.request());
  }, [dispatch]);

  useEffect(() => {
    const recruitingSeason = { ...(recruitingSeasonId && { recruiting_season_id: recruitingSeasonId }) };
    dispatch(requestPartnershipsAsync.request(recruitingSeason));
    dispatch(requestTeamsSummariesAsync.request(recruitingSeason));
    dispatch(requestComplexSummariesAsync.request(recruitingSeason));
  }, [dispatch, recruitingSeasonId]);

  useEffect(() => {
    if (triggerUpload) {
      fileInput?.current?.click();
      setTriggerUpload(false);
    }
  }, [triggerUpload]);

  const refreshLedgers = () => {
    setSelectedPage(initialPage);
    setFilters([]);
    setSearchValue('');
  };

  const onPageChange = ({ selected }) => {
    setSelectedPage(selected);
  };

  const handleFileUpload = useCallback((event) => {
    const file = event.target.files[0];
    dispatch(importLedgersAsync.request({
      format: selectedFileOption?.format,
      file,
      successCallback: refreshLedgers,
    }));
    event.target.value = null;
  }, [dispatch, selectedFileOption?.format]);

  const handleUploadTypeChange = useCallback((option) => {
    setTriggerUpload(true);
    setSelectedFileOption(option);
  }, []);

  const handleLedgerDownload = useCallback((option) => {
    if (option) {
      dispatch(option.action({ format: option.format, ...baseParams }));
    }
  }, [baseParams, dispatch]);

  const onLedgerModalClose = useCallback(() => {
    dispatch(resetSelectedLedgerAction());
    setIsModalOpen(false);
  }, [dispatch]);

  return (
    <div className="grow p-6">
      <div className="mb-4 flex justify-between items-center">
        <span className="text-gray-900 text-[32px] font-normal leading-10">
          {archived ? 'Ledger Archive' : 'Ledger records'}
        </span>
        <div className="justify-end items-center gap-4 inline-flex">
          <SeasonFilter
            options={seasonOptions}
            setRecruitingSeasonId={setRecruitingSeasonId}
            onChangeSeason={() => setFilters([])}
          />
          <CustomButton
            color={'white'}
            onClick={() => setIsModalOpen(true)}
            className={
              'px-2 py-1 rounded-2xl border border-gray-200 justify-start flex gap-1 items-center focus:ring-0'
            }
          >
            <div className="text-right text-gray-900 text-xs font-normal font-['Inter'] leading-none">
              Add new Ledger
            </div>
          </CustomButton>
          {exportLedgersLoading || importLedgersTemplateLoading ? (
            <Loader />
          ) : (
            <DropdownButton
              options={downloadOptions}
              label={downloadOptions[0].label}
              onChange={handleLedgerDownload}
            />
          )}
          {importLedgersLoading ? (
            <Loader />
          ) : (
            <DropdownButton
              options={uploadOptions}
              label={uploadOptions[0].label}
              onChange={handleUploadTypeChange}
            />
          )}
          <input
            type="file"
            ref={fileInput}
            className="hidden"
            accept={selectedFileOption?.mimetype}
            onChange={handleFileUpload}
          />
        </div>
      </div>

      <LedgersTable
        sort={{ getSortParam, onSortParamsChange }}
        isLoading={isLedgerTableLoading}
        ledgers={ledgers}
        filters={filters}
        setFilters={setFilters}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        pageSize={pageSize}
        setPageSize={setPageSize}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        initialPage={initialPage}
        onPageChange={onPageChange}
        archived={archived}
        setIsModalOpen={setIsModalOpen}
        dealers={dealers}
        branches={branches}
        teams={teams}
        partnerships={partnerships}
        typeOfPayments={typeOfPayments}
        complexes={complexes}
        paymentMethods={paymentMethods}
        ledgersTotal={ledgersTotal}
        refreshLedgers={refreshLedgers}
      />
      <LedgerModal
        isOpen={isModalOpen}
        onClose={onLedgerModalClose}
        refreshLedgers={refreshLedgers}
        recruitingSeasonId={recruitingSeasonId}
      />
    </div>
  );
};

Ledger.propTypes = {
  archived: PropTypes.bool,
};

export default Ledger;
