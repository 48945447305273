import * as yup from 'yup';
import {
  REQUIRED,
  MIN_2_CHARS,
  MAX_255_CHARS,
  EMAIL_REGEX,
  PHONE_REGEX,
  PHONE_VALIDATION_MESSAGE,
  POSTAL_CODE_REGEX,
  EMAIL_VALIDATION_MESSAGE,
  MAX_11_CHARS,
  MIN_5_DIGITS,
  MAX_100_CHARS,
  ADDRESS_VALIDATION_MESSAGE,
} from '@/lib/validations';

import { addressesRadioConfig } from '@/lib/configs';

const conditionalAddressSchema = (fieldName) => {
  return yup.string().when('isDifferentAddress', {
    is: (val) => val === addressesRadioConfig[0].value,
    then: yup.string()
      .required(REQUIRED)
      .checkWhiteSpacesOnly(REQUIRED)
      .matches(/.{2,}/, {
        excludeEmptyString: true,
        message: MIN_2_CHARS,
      })
      .max(255, MAX_255_CHARS)
      .checkAddress(ADDRESS_VALIDATION_MESSAGE.replace(':address:', fieldName)),
    otherwise: yup.string().nullable().notRequired(),
  });
};

export const combinedValidationSchema = (userExists) => yup.object().shape({
  firstName: yup
    .string()
    .required(REQUIRED)
    .checkWhiteSpacesOnly(REQUIRED)
    .matches(/.{2,}/, {
      excludeEmptyString: true,
      message: MIN_2_CHARS,
    })
    .max(255, MAX_255_CHARS),
  lastName: yup
    .string()
    .required(REQUIRED)
    .checkWhiteSpacesOnly(REQUIRED)
    .matches(/.{2,}/, {
      excludeEmptyString: true,
      message: MIN_2_CHARS,
    })
    .max(255, MAX_255_CHARS),
  email: yup
    .string()
    .required(REQUIRED)
    .checkWhiteSpacesOnly(REQUIRED)
    .min(2, MIN_2_CHARS)
    .max(255, MAX_255_CHARS)
    .test(
      'email-regex',
      EMAIL_VALIDATION_MESSAGE,
      (value) => value && value.toString().match(EMAIL_REGEX),
    ),
  phone: yup
    .string()
    .required(REQUIRED)
    .checkWhiteSpacesOnly(REQUIRED)
    .matches(PHONE_REGEX, {
      excludeEmptyString: true,
      message: PHONE_VALIDATION_MESSAGE,
    }),
  addressOne: yup.string()
    .required(REQUIRED)
    .checkWhiteSpacesOnly(REQUIRED)
    .checkAddress(ADDRESS_VALIDATION_MESSAGE.replace(':address:', 'Mailing Address'))
    .matches(/.{2,}/, {
      excludeEmptyString: true,
      message: MIN_2_CHARS,
    })
    .max(255, MAX_255_CHARS),
  addressCity: yup
    .string()
    .required(REQUIRED)
    .checkWhiteSpacesOnly(REQUIRED)
    .checkAddress(ADDRESS_VALIDATION_MESSAGE.replace(':address:', 'City'))
    .matches(/.{2,}/, {
      excludeEmptyString: true,
      message: MIN_2_CHARS,
    })
    .max(255, MAX_255_CHARS),
  addressCountry: yup
    .string()
    .required(REQUIRED)
    .checkWhiteSpacesOnly(REQUIRED),
  addressState: yup
    .string()
    .required(REQUIRED)
    .checkWhiteSpacesOnly(REQUIRED),
  addressZip: yup
    .string()
    .required(REQUIRED)
    .checkWhiteSpacesOnly(REQUIRED)
    .matches(POSTAL_CODE_REGEX, {
      excludeEmptyString: true,
      message: MIN_5_DIGITS,
    })
    .max(11, MAX_11_CHARS),
  isDifferentAddress: yup
    .string()
    .required(REQUIRED)
    .checkWhiteSpacesOnly(REQUIRED),
  currentAddressOne: conditionalAddressSchema('Current Street Address'),
  currentAddressCity: conditionalAddressSchema('City'),
  currentAddressCountry: yup.string().when('isDifferentAddress', {
    is: (val) => val === addressesRadioConfig[0].value,
    then: yup
      .string()
      .required(REQUIRED)
      .checkWhiteSpacesOnly(REQUIRED),
  }),
  currentAddressState: yup.string().when('isDifferentAddress', {
    is: (val) => val === addressesRadioConfig[0].value,
    then: yup.string()
      .when('currentAddressCountry', {
        is: (value) => Boolean(value),
        then: yup
          .string()
          .required(REQUIRED)
          .checkWhiteSpacesOnly(REQUIRED),
      }),
  }),
  currentAddressZip: yup.string().when('isDifferentAddress', {
    is: (val) => val === addressesRadioConfig[0].value,
    then: yup.string()
      .required(REQUIRED)
      .checkWhiteSpacesOnly(REQUIRED)
      .matches(POSTAL_CODE_REGEX, {
        excludeEmptyString: true,
        message: MIN_5_DIGITS,
      })
      .max(11, MAX_11_CHARS),
    otherwise: yup.string().nullable().notRequired(),
  }),
  password: yup.string().when([], {
    is: () => !userExists,
    then: yup.string().required(REQUIRED).min(2, MIN_2_CHARS),
    otherwise: yup.string().notRequired(),
  }),
  repeatPassword: yup.string().when([], {
    is: () => !userExists,
    then: yup.string().required(REQUIRED).oneOf([yup.ref('password'), null], 'Passwords must match'),
    otherwise: yup.string().notRequired(),
  }),
  workdayId: yup
    .string()
    .max(100, MAX_100_CHARS),
  pestRoutesId: yup.string(),
  mdHolder: yup
    .boolean(),
  dealer_id: yup
    .string()
    .required(REQUIRED)
    .checkWhiteSpacesOnly(REQUIRED),
  team_id: yup
    .string()
    .nullable(),
  permissionGroup: yup
    .string()
    .required(REQUIRED)
    .checkWhiteSpacesOnly(REQUIRED),
  active: yup.boolean(),
  visible: yup.boolean(),
  unsubscribed: yup.boolean(),
  areaManagement: yup.boolean(),
  sales_channel_ids: yup.array().min(1, 'At least one channel is required.').required(REQUIRED),
});
