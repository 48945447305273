import { createSelector } from 'reselect';

const manageSalesAreasSlice = (state) => state.manageSalesAreas;

export const salesAreasSelector = createSelector(
  manageSalesAreasSlice,
  (state) => state?.salesAreas || [],
);

export const salesAreasTotalSelector = createSelector(
  manageSalesAreasSlice,
  (state) => state?.salesAreasTotal,
);
