import { Table } from '@/components';
import { useSelector, useDispatch } from 'react-redux';
import { manageSalesTeamsLoadingSelector } from '@/redux/loading';
import {
  manageSalesTeamsSelector,
  manageSalesTeamsTotalSelector,
  requestSalesAreasAsync,
  requestSalesTeamsAsync,
  salesAreasSelector,
} from '@/redux/sales-teams';
import { getHeadRows, parseSalesTeamsRows } from '../components/utils';
import { useCallback, useEffect, useState } from 'react';
import { SearchBar, Button } from '@/components/common';
import { AddFilterButton, FilterDropdownButton } from '@/modules/Housing/components/common';
import { useSortTable } from '@/hooks';
import { SalesTeamManagementForm } from '../components/index';

const initialPageSize = 10;
const initialPage = 1;

const visibleOptions = [{ name: 'Visible', value: 1 }, { name: 'Not Visible', value: 0 }];
const deletedOptions = [{ name: 'Active', value: 0 }, { name: 'Deleted', value: 1 }];

const SalesTeamsManagementTable = () => {
  const loading = useSelector(manageSalesTeamsLoadingSelector);
  const salesTeamsTotal = useSelector(manageSalesTeamsTotalSelector);
  const salesTeams = useSelector(manageSalesTeamsSelector);
  const salesAreas = useSelector(salesAreasSelector);

  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(initialPageSize);
  const [selectedPage, setSelectedPage] = useState(initialPage);
  const [filters, setFilters] = useState([]);
  const [filterValueOptions, setFilterValueOptions] = useState([]);
  const [salesTeamsRows, setSalesTeamsRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [salesAreaOptions, setSalesAreaOptions] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const { getSortParam, sortQueryParams, onSortParamsChange } = useSortTable();

  const onPageChange = useCallback(({ selected }) => {
    setSelectedPage(selected);
  }, []);

  const getSalesTeams = useCallback(() => {
    const params = {
      page: {
        number: selectedPage,
        size: pageSize,
      },
    };

    if (searchQuery) {
      params.search_query = searchQuery;
    }

    if (sortQueryParams) {
      params.order = sortQueryParams;
    }

    if (!params.order) {
      delete params.order;
    }

    if (filters.length > 0) {
      filters.map((filter) => {
        const filterValue = filter.type.value !== 'sales_area_ids'
          ? filter.value[0].value
          : filter.value.map((filterValue) => filterValue.value);

        if (filterValue !== undefined && filterValue !== null) {
          params[filter.type.value] = filterValue;
        } else {
          delete params[filter.type.value];
        }
      });
    }

    dispatch(requestSalesTeamsAsync.request(params));
  }, [dispatch, searchQuery, filters, selectedPage, pageSize, sortQueryParams]);

  const getSalesAreas = useCallback(() => {
    dispatch(requestSalesAreasAsync.request());
  }, [dispatch]);

  const onSearchClick = useCallback(({ searchText }) => {
    setSearchQuery(searchText);
    setSelectedPage(initialPage);
  }, []);

  const onTeamCreateClick = useCallback(() => {
    setSelectedTeam(null);
    setIsFormOpen(true);
  }, []);

  const onTeamEditClick = useCallback((salesTeam) => {
    setSelectedTeam(salesTeam);
    setIsFormOpen(true);
  }, []);

  const onTeamFormClose = useCallback(() => {
    setIsFormOpen(false);
    setSelectedTeam(null);
  }, [setIsFormOpen]);

  const filterTypeOptions = [
    {
      onClick: () => setFilterValueOptions(salesAreaOptions),
      label: 'Sales Area',
      value: 'sales_area_ids',
      type: 'dropdown',
    },
    {
      onClick: () => setFilterValueOptions(visibleOptions),
      label: 'Visible',
      value: 'visible',
      type: 'dropdownRadio',
    },
    {
      onClick: () => setFilterValueOptions(deletedOptions),
      label: 'Deleted',
      value: 'deleted',
      type: 'dropdownRadio',
    },
  ];

  useEffect(() => {
    const options = [];
    salesAreas.map((salesArea) => {
      options.push({
        name: salesArea.name,
        value: salesArea.sales_area_id,
      });
    });
    setSalesAreaOptions(options);
  }, [setSalesAreaOptions, salesAreas]);

  useEffect(() => {
    getSalesAreas();
  }, [getSalesAreas]);

  useEffect(() => {
    getSalesTeams();
  }, [getSalesTeams, filters, pageSize, selectedPage, sortQueryParams]);

  useEffect(() => {
    if (!loading) {
      setSalesTeamsRows(parseSalesTeamsRows(salesTeams, salesAreas, onTeamEditClick));
    }
  }, [loading, salesTeams, salesAreas, setSalesTeamsRows, onTeamEditClick]);

  return (
    <>
      {!loading && (
        <div className="p-2 gap-2 border-b bg-white self-stretch flex items-center justify-between">
          <SearchBar
            inputName={'search_query'}
            onSearchClick={onSearchClick}
            searchText={searchQuery}
            placeholder={'Search Sales Teams'}
            disabled={Boolean(loading)}
            inputClassName="w-52 p-2.5 text-sm text-gray-500 bg-white grow border border-gray-300 border-r-transparent rounded-l-md focus:z-10 focus:border-aptivegreen focus:border-r-aptivegreen focus:outline-none focus:ring-1 focus:ring-aptivegreen"
          />
          <div className="mr-4 flex items-center gap-6">
            {filters.map((filter, index) => {
              let options, type;
              switch (filter.type.value) {
                case 'visible':
                  options = visibleOptions;
                  type = 'dropdownRadio';
                  break;
                case 'deleted':
                  options = deletedOptions;
                  type = 'dropdownRadio';
                  break;
                case 'sales_area_ids':
                  options = salesAreaOptions;
                  type = 'dropdown';
                  break;
                default:
                  break;
              }

              return (
                <FilterDropdownButton
                  buttonClassName="border border-gray-200 rounded-lg px-4 py-2 gap-1 inline-flex items-center"
                  iconClassName="w-4 h-4 text-gray-600"
                  labelClassName="text-gray-600 text-based font-normal sm:text-xs leading-none"
                  filterValueOptions={options}
                  filters={filters}
                  setFilters={setFilters}
                  key={index}
                  index={index}
                  type={type}
                />
              );
            })}

            <AddFilterButton
              labelClassName="text-gray-600 text-right font-normal sm:text-xs leading-none border-none shadow-none"
              buttonClassName="items-center"
              iconClassName="w-0 h-0"
              filterTypeOptions={filterTypeOptions}
              filterValueOptions={filterValueOptions}
              setFilters={setFilters}
              filters={filters}
              label={'+ Add Filters'}
            />
            <div className="flex items-center gap-4">
              <Button
                color="default"
                onClick={() => setFilters([])}
                className="text-gray-600 text-right font-normal sm:text-xs leading-none !border-none !rounded-none !shadow-none focus:!ring-0"
              >
                Clear Filters
              </Button>
              <Button
                color="green"
                onClick={onTeamCreateClick}
                className="px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-aptivegreen"
              >
                Create Team
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className="px-8">
        <div className="mt-10">
          <Table
            isStickyHeader
            loading={loading}
            thead={{
              rows: getHeadRows({ getSortParam, onSortChange: onSortParamsChange }),
            }}
            tbody={{
              rows: salesTeamsRows,
            }}
            paginator={{
              rowCount: salesTeamsTotal,
              pageSize: pageSize,
              setPageSize: setPageSize,
              onPageChange: onPageChange,
              selectedPage: selectedPage,
              initialPage: initialPage,
            }}
          />
        </div>
      </div>
      <SalesTeamManagementForm
        isOpen={isFormOpen}
        onTeamFormClose={onTeamFormClose}
        editingTeam={selectedTeam}
        reloadSalesTeams={getSalesTeams}
      />
    </>
  );
};

export default SalesTeamsManagementTable;
