import { dashboardConstants } from '@/lib';
import { SortLabel } from '@/components';
import {
  salesTeamsManagementColumnNames,
  salesTeamsManagementColumnLabels,
} from '../lib/constants/salesTeamsManagement';

const {
  ID_COLUMN_NAME,
  NAME_COLUMN_NAME,
  SALES_AREA_ID_COLUMN_NAME,
  SALES_AREA_NAME_COLUMN_NAME,
  VISIBLE_COLUMN_NAME,
  DELETED_COLUMN_NAME,
} = salesTeamsManagementColumnNames;

export const getHeadRows = ({ getSortParam, onSortChange }) => (
  [
    {
      value: salesTeamsManagementColumnLabels[ID_COLUMN_NAME],
      name: ID_COLUMN_NAME,
      align: 'right',
      className: 'whitespace-nowrap',
    },
    {
      value: (
        <SortLabel
          sortName={NAME_COLUMN_NAME}
          sortOrder={getSortParam(NAME_COLUMN_NAME)?.order}
          onSortChange={onSortChange}
          position={getSortParam(NAME_COLUMN_NAME)?.index}
        >
          {salesTeamsManagementColumnLabels[NAME_COLUMN_NAME]}
        </SortLabel>
      ),
      name: NAME_COLUMN_NAME,
      align: 'left',
      className: 'whitespace-nowrap',
    },
    {
      value: salesTeamsManagementColumnLabels[SALES_AREA_ID_COLUMN_NAME],
      name: SALES_AREA_ID_COLUMN_NAME,
      align: 'left',
      className: 'whitespace-nowrap',
    },
    {
      value: salesTeamsManagementColumnLabels[SALES_AREA_NAME_COLUMN_NAME],
      name: SALES_AREA_NAME_COLUMN_NAME,
      align: 'left',
      className: 'whitespace-nowrap',
    },
    {
      value: salesTeamsManagementColumnLabels[VISIBLE_COLUMN_NAME],
      name: VISIBLE_COLUMN_NAME,
      align: 'left',
      className: 'whitespace-nowrap',
    },
    {
      value: salesTeamsManagementColumnLabels[DELETED_COLUMN_NAME],
      name: DELETED_COLUMN_NAME,
      align: 'left',
      className: 'whitespace-nowrap',
    },
  ]
);

export const parseSalesTeamsRows = (rows, salesAreas, onTeamEditClick) => (
  rows.length ? rows.map((row) => {
    const salesArea = salesAreas.find((sales_area) => sales_area.sales_area_id === row[SALES_AREA_ID_COLUMN_NAME]);

    return [
      {
        value: row[ID_COLUMN_NAME],
        align: 'right',
      },
      {
        value: row[NAME_COLUMN_NAME]
          ? (
            <button
              type="button"
              onClick={() => onTeamEditClick(row)}
              className="text-primary-300 font-medium text-left cursor-pointer focus:outline-none"
            >
              {row[NAME_COLUMN_NAME]}
            </button>
          )
          : '-',
        align: 'left',
      },
      {
        value: row[SALES_AREA_ID_COLUMN_NAME] ? row[SALES_AREA_ID_COLUMN_NAME] : '-',
        align: 'left',
      },
      {
        value: salesArea ? salesArea.name : '-',
        align: 'left',
      },
      {
        value: row[VISIBLE_COLUMN_NAME]
          ? 'Yes'
          : 'No',
        align: 'left',
      },
      {
        value: row[DELETED_COLUMN_NAME]
          ? 'Yes'
          : 'No',
        align: 'left',
      },
    ];
  }) : [
    {
      value: dashboardConstants.NO_DATA_TO_DISPLAY,
      align: 'center',
      colSpan: 6,
      className: 'py-8',
    },
  ]
);
