import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader, Button } from '@/components';
import { StatelessSearchDropdown } from '@/components/common/inputs';
import { useDispatch, useSelector } from 'react-redux';
import { requestAreasListAsync, selectAreasList } from '@/redux/areas-new';
import { sptConstants } from '../../lib';

const AreaFilter = ({ loading, onAreaSelected }) => {
  const dispatch = useDispatch();
  const areasList = useSelector(selectAreasList);
  const [selection, setSelection] = useState(null);

  useEffect(() => {
    dispatch(requestAreasListAsync.request());
  }, [dispatch]);

  const areaOptions = useMemo(() => {
    const options = areasList.map(({ area_id, area_name }) => ({
      name: area_name,
      value: area_id,
    }));

    return [{ name: sptConstants.ALL_AREAS, value: '' }, ...options];
  }, [areasList]);

  return loading ? (
    <Loader />
  ) : (
    <StatelessSearchDropdown
      displayProp="name"
      className="w-full md:w-56"
      label={sptConstants.FILTER_BY_AREAS}
      items={areaOptions}
      onChange={setSelection}
      selected={selection}
      button={
        <Button onClick={() => onAreaSelected(selection?.value || '')}>
          {sptConstants.FILTER}
        </Button>
      }
    />
  );
};

AreaFilter.propTypes = {
  loading: PropTypes.bool.isRequired,
  onAreaSelected: PropTypes.func.isRequired,
};

export default AreaFilter;
