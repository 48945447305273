// TODO: SPT:REMOVE

import { toastType } from '@/components/common';

export const getPolygonStatData = (response) => {
  const {
    statistics: {
      percentage,
      total_addresses: totalAddresses,
      total_qualified_addresses: qualifiedAddresses,
      total_reps: totalReps,
    },
    reps,
    pins,
    last_knocks,
  } = response?.data ?? {};

  const repIds = reps?.map((rep) => rep?.rep_id);

  return {
    percentage,
    totalAddresses,
    qualifiedAddresses,
    totalReps,
    reps: repIds,
    pins,
    lastKnocks: last_knocks,
  };
};

export const getUpdatedPolygonStatData = (response) => {
  const {
    statistics: {
      percentage,
      total_addresses: totalAddresses,
      total_qualified_addresses: qualifiedAddresses,
      total_reps: totalReps,
    },
    reps,
    last_knocks,
  } = response?.data?.statistics ?? {};

  const repIds = reps?.map((rep) => rep?.rep_id);

  return {
    percentage,
    totalAddresses,
    qualifiedAddresses,
    totalReps,
    reps: repIds,
    lastKnocks: last_knocks,
  };
};

export const exceededPolygonLimitToastData = () => {
  const polygonLimitMessage = 'Polygon limit exceeded';
  const polygonLimitDetails = 'Please pan or zoom to a new area to reduce the number of polygons.';

  const toast = getToast(toastType.WARNING, polygonLimitMessage, polygonLimitDetails, true);

  return [toast];
};

export const getCreatePolygonToastData = (response) => {
  const { polygon_id } = response?.polygon ?? {};
  const toast = getToast(toastType.SUCCESS, `Polygon #${polygon_id} is created.`);

  return [toast];
};

export const getAssignPolygonToastData = (response) => {
  const { polygon_id } = response?.polygon ?? {};
  const toast = getToast(toastType.SUCCESS, `Polygon #${polygon_id} is assigned.`);

  return [toast];
};

export const getClearOutcomesToastData = () => {
  const toast = getToast(toastType.SUCCESS, 'Outcomes are cleared');

  return [toast];
};

export const getActivatePolygonToastData = (response) => {
  const { polygon_id } = response?.polygon ?? {};
  const toast = getToast(toastType.SUCCESS, `Polygon #${polygon_id} is activated.`);

  return [toast];
};

export const getAssignPolygonData = (response, reps) => {
  return Object.entries(response).map(([key, value]) => {
    if (value?.success) {
      if (value
        ?.polygon
        ?.unlicensed_reps
        ?.length
      ) {
        return getToast(toastType.ERROR, `Polygon #${key}: some reps can't be updated.`);
      } else {
        return getToast(toastType.SUCCESS, `Polygon #${key}: reps are updated.`);
      }
    } else {
      const [errorMessage] = value?.errors ?? {};

      return getToast(toastType.ERROR, `Polygon #${key}: reps can't be updated.`, errorMessage);
    }
  });
};

export const getUnassignPolygonData = (response) => {
  return Object.entries(response).map(([key, value]) => {
    if (value?.success) {
      return getToast(toastType.SUCCESS, `Polygon #${key} is unassigned.`);
    } else {
      const [errorMessage] = value?.errors ?? {};

      return getToast(toastType.ERROR, `Polygon #${key} can't be unassigned.`, errorMessage);
    }
  });
};

const generatePolygonToasts = (polygons, successMessage, failureMessage) => {
  const successfulPolygons = polygons.filter((response) => response?.success);
  const failedPolygons = polygons.filter((response) => !response?.success);

  const toasts = [];

  if (successfulPolygons.length > 0) {
    toasts.push(getToast(toastType.SUCCESS, `${successfulPolygons.length} ${successMessage}`));
  }

  if (failedPolygons.length > 0) {
    const allErrors = failedPolygons
      .map(({ polygon_id, error }) => `Polygon #${polygon_id}: ${error}`)
      .filter(Boolean)
      .join('\n');
    toasts.push(getToast(toastType.ERROR, `${failedPolygons.length} polygons ${failureMessage}`, allErrors));
  }

  return toasts;
};

export const getDeactivatePolygonToastsData = (polygons) => {
  return generatePolygonToasts(
    polygons,
    'have been deactivated.',
    'can\'t be deactivated.',
  );
};

export const getDeletePolygonToastsData = (polygons) => {
  return generatePolygonToasts(
    polygons,
    'have been deleted.',
    'can\'t be deleted.',
  );
};

const getToast = (type, message, details, autoClose) => ({
  type,
  message,
  details: details ?? null,
  autoClose,
});

export const getPolygonIds = (response) => {
  return Object.keys(response).map((id) => Number(id));
};

export const getSuccessfulPolygonIds = (response) => {
  return response.filter((item) => item.success).map(({ polygon_id }) => Number(polygon_id));
};

export const getUnlicensedRepsForPolygons = (response) => {
  let licensingInfo = {};

  for (const polygonId in response) {
    const licensingData = response[polygonId]?.polygon?.unlicensed_reps;

    for (const repId in licensingData) {
      const licensingRepInfo = licensingData[repId];
      licensingInfo = mapLicensingInfoItem(licensingInfo, licensingRepInfo);
    }
  }

  return licensingInfo;
};

export const getUnlicensedRepsForPolygon = (response) => {
  let licensingInfo = {};
  const licensingData = response?.unlicensed_reps;

  for (const repId in licensingData) {
    const licensingRepInfo = licensingData[repId];
    licensingInfo = mapLicensingInfoItem(licensingInfo, licensingRepInfo);
  }

  return licensingInfo;
};

const mapLicensingInfoItem = (currentLicensingInfo, licensingRepInfo) => {
  currentLicensingInfo[licensingRepInfo.rep_id] ??= {
    rep_id: licensingRepInfo.rep_id,
    rep_full_name: licensingRepInfo.rep_full_name,
    is_license_hard_forced: licensingRepInfo.is_license_hard_forced,
  };

  return currentLicensingInfo;
};

export const getPolygonDispositions = (response) => {
  const { data, total = 0 } = response ?? {};

  const items = [];
  data?.forEach(({
    polygon_id,
    team_name,
    not_knocked,
    knocked_once,
    knocked_twice,
    completed,
  }) => {
    items.push([
      polygon_id,
      team_name,
      not_knocked,
      knocked_once,
      knocked_twice,
      completed,
    ]);
  });

  return { items, total };
};
