import person from '@/assets/person.png';
import { Loader } from '@/components';
import { addFsExcludeClass } from '@/lib/utils';
import { selectStates } from '@/redux/addresses';
import { connect } from 'react-redux';
import { UserAddIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

const FormHeader = (
  {
    user,
    userIsLoading,
    openFullProfile,
  },
) => {
  return (
    <div className="px-4 py-6 sm:px-6 bg-gray-100">
      <div className="flex items-start justify-between">
        <div className="flex items-center mb-4 space-x-4 lg:space-x-6">
          {user && (
            <img
              className={addFsExcludeClass('w-16 h-16 rounded-full lg:w-20 lg:h-20')}
              src={user?.profile_img ? user?.profile_img : person}
              alt=""
            />
          )}
          <div className="space-y-1 text-lg font-medium leading-6 ">
            {
              userIsLoading ? <Loader /> :
                user ? (
                  <>
                    <h3 className={addFsExcludeClass()}>{`${user?.first_name} ${user?.last_name}`}</h3>
                    <ul className="list-disc flex flex-row space-x-8 text-xs text-gray-400">
                      <li className="list-none">{user?.role}</li>
                    </ul>
                  </>
                ) : (
                  <div className="flex items-center space-x-4 lg:space-x-6">
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-aptivegreen">
                      <UserAddIcon
                        color="white"
                        className="w-6 h-6"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="space-y-1 text-lg font-bold leading-6 ">
                      <h3>Create New User</h3>
                    </div>
                  </div>
                )
            }
          </div>
        </div>
        {user?.role === 'Rep' && (
          <div
            className="flex items-center mt-6 space-x-4 lg:space-x-6 text-sm cursor-pointer"
            onClick={openFullProfile}
          >
            View full profile
          </div>
        )}
      </div>
    </div>
  );
};

FormHeader.propTypes = {
  user: PropTypes.object,
  userIsLoading: PropTypes.bool.isRequired,
  openFullProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  states: selectStates(state),
});

export default connect(mapStateToProps)(FormHeader);
