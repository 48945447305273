import { formatPhone } from '@/lib';
import { createReducer } from '@/redux/root';
import { clearUser, clearUsersSearch, nameSpace, requestUserAsync, updateUserSalesChannelsAsync } from './actions';
import { requestUsersAsync, requestUserGroupsAsync } from '@/redux/users';

const manageUsersInitialState = {
  users: [],
  total: 0,
};

const manageUserGroupsInitialState = {
  userGroups: [],
  total: 0,
};

const manageUserDetailsInitialState = {
  user: null,
  loading: false,
};

export const manageUsersReducer = createReducer(nameSpace, manageUsersInitialState, {
  [requestUsersAsync.success]: ({ state, action: { payload } }) => {
    state.users = payload.data.map(({ attributes }) => ({
      id: attributes.user_id,
      name: attributes.name,
      email: attributes.email,
      phone_number: formatPhone(attributes.mobile),
      experience_name: attributes.experience_name,
      status: attributes.status,
      md_holder: attributes.md_holder,
      archived: attributes.archived,
      channel_ids: attributes.channel_ids,
    }))
    state.total = payload.meta.total;
  },
  [clearUsersSearch]: ({ state }) => {
    state.users = [],
    state.total = 0;
  },
  [updateUserSalesChannelsAsync.success]: ({ state, action: { payload } }) => {
    const { userId, salesChannelIds } = payload;

    const changedUserIndex = state.users.findIndex((user) => user.id === userId);
    state.users[changedUserIndex].channel_ids = salesChannelIds;
  },
});

export const manageUserDetailsReducer = createReducer(nameSpace, manageUserDetailsInitialState, {
  [requestUserAsync.request]: ({ state }) => {
    state.user = null;
  },
  [requestUserAsync.success]: ({ state, action: { payload } }) => {
    state.user = payload.user;
  },
  [requestUserAsync.failure]: ({ state }) => {
    state.user = null;
  },
  [clearUser]: ({ state }) => {
    state.user = null;
  },
});


export const manageUserGroupsReducer = createReducer(nameSpace, manageUserGroupsInitialState, {
  [requestUserGroupsAsync.success]: ({ state, action: { payload } }) => {
    state.userGroups = payload.data.map(({ attributes }) => ({
      user_group_id: attributes.user_group_id,
      name: attributes.name,
    }))
    state.total = payload.meta.total;
  },
});
