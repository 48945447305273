import { createSelector } from 'reselect';

const errorsStateSelector = (state) => state.errors.errors;

export const loginErrorsSelector = createSelector(
  errorsStateSelector,
  (state) => {
    // TODO: Unify error handling.
    //       Currently, errors can be `null`, `undefined`, `string` or `array`.
    const errors = state.login;

    return errors && (Array.isArray(errors) ? errors : [{ message: errors }]).map(error => error.message);
  },
);

export const contractsLinkErrorSelector = createSelector(
  errorsStateSelector,
  state => state?.contractsLink,
);

export const addRepErrorsSelector = createSelector(
  errorsStateSelector,
  state => state?.reps
);

export const saveSalesTeamErrorsSelector = createSelector(
  errorsStateSelector,
  state => state?.saveSalesTeams,
);

export const updateRepErrorsSelector = createSelector(
  errorsStateSelector,
  state => state?.updateRep
);

export const forgotPasswordErrorsSelector = createSelector(
  errorsStateSelector,
  state => state?.forgotPassword
);

export const deleteContractErrorsSelector = createSelector(
  errorsStateSelector,
  state => state?.deleteContract
);

export const deleteUserErrorsSelector = createSelector(
  errorsStateSelector,
  state => state?.deleteUser
);

export const restoreUserErrorsSelector = createSelector(
  errorsStateSelector,
  state => state?.restoreUser
);

export const resetPasswordErrorsSelector = createSelector(
  errorsStateSelector,
  state => state?.resetPassword
);

export const selectPolygonPreviewError = (state) => state.errors.errors?.polygonPreview;// TODO: SPT:REMOVE

const selectCreatePolygonError = (state) => state.errors.errors?.createPolygon;// TODO: SPT:REMOVE

const selectUpdatePolygonBoundaryError = (state) => state.errors.errors?.updateBoundary;// TODO: SPT:REMOVE

const selectActivatePolygonError = (state) => state.errors.errors?.activatePolygon;// TODO: SPT:REMOVE

const selectUpdateRepsError = (state) => state.errors.errors?.updateReps;// TODO: SPT:REMOVE

const selectClearOutcomesError = (state) => state.errors.errors?.clearOutcomes;// TODO: SPT:REMOVE

export const selectPolygonError = createSelector( // TODO: SPT:REMOVE
  [
    selectPolygonPreviewError,
    selectCreatePolygonError,
    selectUpdatePolygonBoundaryError,
    selectActivatePolygonError,
    selectUpdateRepsError,
    selectClearOutcomesError,
  ],
  (
    preview,
    create,
    update,
    activate,
    assign,
    clearOutcomes,
  ) => [preview, create, update, activate, assign, clearOutcomes]
    .find((error) => Boolean(error))?.[0] ?? null,
);
