import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { CustomFormElement } from '@/components/common';
import { useDispatch, useSelector } from 'react-redux';
import { isAdminSelector } from '@/redux/auth';
import { addFsExcludeClass } from '@/lib/utils';
import { defaultSelectOption } from '@/lib/configs';
import { ledgerConstants } from '@/modules/Housing/lib';
import { dealersSelector, requestDealersAsync } from '@/modules/Housing/redux/partnership';
import { onboardingConstants } from '@/lib';
import { salesChannelsLoadingSelector } from '@/redux/loading';
import { manageSalesTeamsSelector, requestSalesTeamsAsync } from '@/redux/sales-teams';
import { requestSalesChannelsAsync, salesChannelsSelector } from '@/modules/AdminTools/redux/planBuilder/sales-channels';
import { isDealersLoadingSelector } from '@/modules/Housing/redux/loading';

const {
  DEALER_NAME,
  DEALER_LABEL,
  TEAM_NAME,
  TEAM_LABEL,
} = ledgerConstants;

const {
  ACTIVE_NAME,
  ACTIVE_LABEL,
  VISIBLE_LABEL,
  VISIBLE_NAME,
  GROUP_NAME,
  GROUP_LABEL,
  APP_ACCESS_NAME,
  APP_ACCESS_LABEL,
  UNSUBSCRIBED_NAME,
  UNSUBSCRIBED_LABEL,
  AREA_MANAGEMENT_NAME,
  AREA_MANAGEMENT_LABEL,
  AREA_MANAGEMENT_TOOLTIP_LABEL,
  SALES_CHANNELS_FIELD_NAME,
} = onboardingConstants;

const AccessPermissions = ({
  onChangeHandler,
  user,
  groups,
  salesManagementUserInfo,
  setSalesChannelsChanged,
}) => {
  const salesTeams = useSelector(manageSalesTeamsSelector);
  const isAdmin = useSelector(isAdminSelector);
  const dealers = useSelector(dealersSelector);
  const areSalesChannelsLoading = useSelector(salesChannelsLoadingSelector);
  const salesChannels = useSelector(salesChannelsSelector);
  const isDealersLoading = useSelector(isDealersLoadingSelector);

  const dispatch = useDispatch();

  const {
    setValue,
    getValues,
    register,
    formState: { errors },
  } = useFormContext();

  const [teams, setTeams] = useState([]);
  const selectedSalesChannels = useWatch({ name: SALES_CHANNELS_FIELD_NAME, defaultValue: [] });

  const getSalesTeams = useCallback(() => {
    const params = {
      page: {
        number: 1,
        size: 500,
      },
      visible: 1,
    };

    dispatch(requestSalesTeamsAsync.request(params));
  }, [dispatch]);

  useEffect(() => {
    if (salesTeams?.length > 0) {
      setTeams(
        salesTeams.map((team) => ({
          value: team.sales_team_id,
          name: team.name,
        })),
      );
    }
  }, [salesTeams]);

  useEffect(() => {
    if (!dealers?.length) {
      dispatch(requestDealersAsync.request());
    }
    if (!salesTeams?.length) {
      getSalesTeams();
    }
    if (user && !salesChannels?.length) {
      dispatch(requestSalesChannelsAsync.request());
    }
  }, [dispatch, getSalesTeams, user, dealers, salesTeams, salesChannels]);

  const handleDealerChange = useCallback(
    (event) => {
      const { value } = event.target;
      setValue(DEALER_NAME, value, { shouldValidate: true, shouldDirty: true });

      setValue(TEAM_NAME, null, { shouldValidate: true, shouldDirty: true });
    },
    [setValue],
  );

  const groupOptions = useMemo(() => {
    return groups.map((group) => ({
      value: group.user_group_id,
      name: group.name,
    }));
  }, [groups]);

  useEffect(() => {
    if (Array.isArray(salesManagementUserInfo?.channel_ids)) {
      const currentValues = getValues(SALES_CHANNELS_FIELD_NAME) || [];

      if (JSON.stringify(currentValues) === '[]' || JSON.stringify(currentValues) === JSON.stringify(salesManagementUserInfo.channel_ids)) {
        setValue(SALES_CHANNELS_FIELD_NAME, salesManagementUserInfo.channel_ids, { shouldValidate: true });
      }
    }
  }, [salesManagementUserInfo?.channel_ids, setValue, getValues]);

  const onChangeChannels = (event) => {
    const { name, value } = event.target;

    setValue(name, value, { shouldValidate: true });
    setSalesChannelsChanged(true);
  };

  const salesChannelOptions =
    salesChannels?.map(({ id, name, status }) => ({
      value: id,
      label: status ? name : `${name} (Inactive)`,
    })) || [];

  return (
    <>
      <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
        <CustomFormElement
          colSpan={3}
          id={DEALER_NAME}
          name={DEALER_NAME}
          label={DEALER_LABEL}
          type="select"
          selectOptions={[...defaultSelectOption, ...dealers]}
          onChange={handleDealerChange}
          register={register}
          error={errors?.dealer_id}
          className={addFsExcludeClass()}
          disabled={!isAdmin}
          loading={isDealersLoading}
          required
        />
        <CustomFormElement
          colSpan={3}
          id={TEAM_NAME}
          name={TEAM_NAME}
          label={TEAM_LABEL}
          type="select"
          selectOptions={[...defaultSelectOption, ...teams]}
          onChange={onChangeHandler}
          register={register}
          error={errors?.team_id}
          className={addFsExcludeClass()}
          disabled={!isAdmin}
        />
        <CustomFormElement
          id={GROUP_NAME}
          colSpan={3}
          name={GROUP_NAME}
          label={GROUP_LABEL}
          type="select"
          selectOptions={[...defaultSelectOption, ...groupOptions]}
          onChange={onChangeHandler}
          register={register}
          error={errors?.permissionGroup}
          className={addFsExcludeClass()}
          required
          disabled={!isAdmin}
        />
        {user && (
          <CustomFormElement
            label="Sales Channels"
            id={SALES_CHANNELS_FIELD_NAME}
            name={SALES_CHANNELS_FIELD_NAME}
            type="multiSelect"
            isMulti={true}
            error={errors?.sales_channel_ids}
            loading={areSalesChannelsLoading}
            placeholder="-- Select Sales Channels --"
            options={salesChannelOptions}
            onChange={onChangeChannels}
            value={selectedSalesChannels}
            required
            register={register}
            className={addFsExcludeClass()}
            formElementWrapperClassName="text-black"
            disabled={!isAdmin}
          />
        )}
      </div>
      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 mt-6">
        <CustomFormElement
          colSpan={3}
          id={ACTIVE_NAME}
          name={ACTIVE_NAME}
          label={ACTIVE_LABEL}
          type="checkbox"
          className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-aptivegreen"
          onChange={onChangeHandler}
          register={register}
          error={errors?.active}
          disabled={!isAdmin}
          labelPosition="after"
          isInlineWithLabel={true}
          formElementWrapperClassName="flex items-center space-x-2"
        />
        <CustomFormElement
          colSpan={3}
          id={VISIBLE_NAME}
          name={VISIBLE_NAME}
          label={VISIBLE_LABEL}
          type="checkbox"
          className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-aptivegreen"
          onChange={onChangeHandler}
          register={register}
          error={errors?.visible}
          disabled={!isAdmin}
          labelPosition="after"
          isInlineWithLabel={true}
          formElementWrapperClassName="flex items-center space-x-2"
        />
        <CustomFormElement
          colSpan={3}
          id={APP_ACCESS_NAME}
          name={APP_ACCESS_NAME}
          label={APP_ACCESS_LABEL}
          type="checkbox"
          className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-aptivegreen"
          onChange={onChangeHandler}
          register={register}
          error={errors?.appAccess}
          disabled={!isAdmin}
          labelPosition="after"
          isInlineWithLabel={true}
          formElementWrapperClassName="flex items-center space-x-2"
        />
        <CustomFormElement
          colSpan={3}
          id={AREA_MANAGEMENT_NAME}
          name={AREA_MANAGEMENT_NAME}
          label={AREA_MANAGEMENT_LABEL}
          tooltipMessage={AREA_MANAGEMENT_TOOLTIP_LABEL}
          type="checkbox"
          className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-aptivegreen"
          onChange={onChangeHandler}
          register={register}
          error={errors?.areaManagement}
          disabled={!isAdmin}
          labelPosition="after"
          isInlineWithLabel={true}
          formElementWrapperClassName="flex items-center space-x-2"
        />
        <CustomFormElement
          colSpan={3}
          id={UNSUBSCRIBED_NAME}
          name={UNSUBSCRIBED_NAME}
          label={UNSUBSCRIBED_LABEL}
          type="checkbox"
          className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-aptivegreen"
          onChange={onChangeHandler}
          register={register}
          error={errors?.areaManagement}
          disabled={!isAdmin}
          labelPosition="after"
          isInlineWithLabel={true}
          formElementWrapperClassName="flex items-center space-x-2"
        />
      </div>
    </>
  );
};

AccessPermissions.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  user: PropTypes.object,
  groups: PropTypes.array.isRequired,
  salesManagementUserInfo: PropTypes.object,
  setSalesChannelsChanged: PropTypes.func,
};

export default AccessPermissions;
