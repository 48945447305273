import PropTypes from 'prop-types';

const ChildRadioGroupWrapper = ({ children }) => (
  <div
    className="flex items-start p-5 bg-gray-50 border-t-0 border-l border-r border-b  shadow-sm rounded-b-lg"
  >
    {children}
  </div>
);

ChildRadioGroupWrapper.propTypes = {
  children: PropTypes.object,
};

export default ChildRadioGroupWrapper;
