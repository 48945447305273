import * as yup from 'yup';
import {
  salesTeamsManagementColumnNames,
  salesTeamsManagementColumnLabels,
} from '@/modules/dashboard/components/SalesTeamsManagement/lib/constants/salesTeamsManagement';

const {
  NAME_COLUMN_NAME,
  SALES_AREA_ID_COLUMN_NAME,
  SALES_AREA_NAME_COLUMN_NAME,
  VISIBLE_COLUMN_NAME,
  DELETED_COLUMN_NAME,
} = salesTeamsManagementColumnNames;

export const validationSchema = yup.object().shape({
  [NAME_COLUMN_NAME]: yup.string()
    .label(salesTeamsManagementColumnLabels[NAME_COLUMN_NAME])
    .checkWhiteSpacesOnly(`${salesTeamsManagementColumnLabels[NAME_COLUMN_NAME]} is required.`)
    .min(2)
    .max(96),
  [SALES_AREA_ID_COLUMN_NAME]: yup.string()
    .label(salesTeamsManagementColumnLabels[SALES_AREA_ID_COLUMN_NAME])
    .required(`${salesTeamsManagementColumnLabels[SALES_AREA_NAME_COLUMN_NAME]} is required.`),
  [VISIBLE_COLUMN_NAME]: yup.string()
    .label(salesTeamsManagementColumnLabels[VISIBLE_COLUMN_NAME])
    .checkWhiteSpacesOnly(`${salesTeamsManagementColumnLabels[VISIBLE_COLUMN_NAME]} is required.`)
    .oneOf(['0', '1']),
  [DELETED_COLUMN_NAME]: yup.string()
    .label(salesTeamsManagementColumnLabels[DELETED_COLUMN_NAME])
    .checkWhiteSpacesOnly(`${salesTeamsManagementColumnLabels[DELETED_COLUMN_NAME]} is required.`)
    .oneOf(['0', '1']),
});
