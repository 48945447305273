import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import {
  FeatureGroup,
  GeoJSON,
  useMapEvents,
  Pane,
} from 'react-leaflet';
import { LatLng } from 'leaflet';
import { requestMultiplePolygonStatisticsAsync, requestPolygonStatisticsAsync, requestPreviewAsync } from '@/redux/polygons';
import { selectPolygonPreviewError } from '@/redux/errors';
import { selectLoadingPolygonPreview } from '@/redux/loading';
import { Freedraw, LoaderControl } from '../../controls';
import RepBubbleCluster from './RepBubbleCluster';
import { DRAW_MODE, sptConstants, doPolygonsIntersect } from '../../../lib';
import { TeamMapContext } from '../../../providers/TeamMapProvider';

const TeamPolygons = ({
  setIsPolygonDialogOpen,
  closeDialog,
  teamId,
  selectedPolygon,
  setSelectedPolygon,
  polygons,
  onPolygonSelect,
  onPolygonEdit,
  selectMultiplePolygons,
  multipleSelection,
}) => {
  const dispatch = useDispatch();
  const isPreviewLoading = useSelector(selectLoadingPolygonPreview);
  const previewError = useSelector(selectPolygonPreviewError);

  const freedrawRef = useRef(null);
  const [mapPolygons, setMapPolygons] = useState(polygons);
  const [showReps, setShowReps] = useState(false);
  const [concavePolygon, setConcavePolygon] = useState(true);
  const [selectorPolygon, setSelectorPolygon] = useState(null);

  const { drawMode, setDrawMode, handleDrawModeChange, pinMode } = useContext(TeamMapContext);

  useEffect(() => {
    setDrawMode(DRAW_MODE.EDIT);
  }, [pinMode, setDrawMode]);

  const polygonStyle = {
    fillOpacity: 0.5,
    weight: 2,
  };

  const map = useMapEvents({
    popupopen: (event) => {
      if (drawMode === DRAW_MODE.CREATE) {
        event.popup.remove();
      }
    },
    zoomend: () => {
      if (!map) {
        return;
      }

      if (map.getZoom() >= 13) {
        setShowReps(true);
      } else {
        setShowReps(false);
      }
    },
  });

  useEffect(() => {
    assignPolygonStyles(polygons);
  }, [polygons]);

  useEffect(() => {
    const isExistingPolygon = selectedPolygon && !selectedPolygon.isPreview;

    if (isExistingPolygon) {
      createEditablePolygon(selectedPolygon);
    } else if (!selectedPolygon){
      clearFreedraw();
    }
  }, [selectedPolygon]);

  useEffect(() => {
    if (previewError) {
      clearFreedraw();
    }
  }, [previewError]);

  useEffect(() => {
    if (freedrawRef.current) {
      // Changes cursor depending on draw mode
      switch (drawMode) {
        case DRAW_MODE.CREATE:
          freedrawRef.current.map._container.style.cursor = 'crosshair';
          break;
        default:
          freedrawRef.current.map._container.style.cursor = '';
          break;
      }
    }

    if (DRAW_MODE.EDIT === drawMode) {
      setConcavePolygon(false);
    } else {
      setConcavePolygon(true);
    }
  }, [drawMode]);

  const assignPolygonStyles = (polygons) => {
    const finalPolygons = [];
    for (const thisPolygon of polygons) {
      const coloredPolygon = { ...thisPolygon };
      if (!thisPolygon.active) {
        coloredPolygon.fillColor = sptConstants.POLYGON_COLOR_DEACTIVATED;
        coloredPolygon.weight = 0.3;
      } else if (thisPolygon.reps_count > 0) {
        coloredPolygon.fillColor = sptConstants.POLYGON_COLOR_ASSIGNED;
        coloredPolygon.weight = 2;
      } else {
        coloredPolygon.fillColor = sptConstants.POLYGON_COLOR_UNASSIGNED;
        coloredPolygon.weight = 2;
      }
      finalPolygons.push(coloredPolygon);
    }

    setMapPolygons(finalPolygons);
  };

  const clearFreedraw = () => {
    if (freedrawRef.current && freedrawRef.current.size()) {
      freedrawRef.current.clear();
    }
  };

  useEffect(() => {
    if (drawMode === DRAW_MODE.CREATE) {
      if (selectedPolygon || multipleSelection.length) {
        clearFreedraw();
        closeDialog();
        setDrawMode(DRAW_MODE.CREATE);
      }
    }
  }, [drawMode]);

  const handleMarkersEdit = useCallback((event, polygon) => {
    // Callback is only called if it is an edit on an existing
    const allowCallback = (freedrawRef.current.size() && event.eventType === 'edit');
    if (allowCallback) {
      const newPolygon = { ...polygon, boundary: formatFreedrawPolygon(event.latLngs[0]).geometry };
      onPolygonEdit(newPolygon);
      setSelectedPolygon(newPolygon);
    }

    setDrawMode(DRAW_MODE.EDIT);
  }, [onPolygonEdit, setDrawMode, setSelectedPolygon]);

  const handlePreviewMarkersEdit = useCallback((event) => {
    // Callback is only called if:
    //  1. The polygon is being edited
    //  2. The polygon is being made for the first time
    //   (making previewPolygon a freedraw component)
    const allowCallback =
      (freedrawRef.current.size() && event.eventType === 'edit') ||
      (event.eventType === 'create' && event.latLngs[0]);

    if (allowCallback) {
      const newPolygon = formatFreedrawPolygon(event.latLngs[0]);
      dispatch(requestPreviewAsync.request({
        team_id: teamId,
        boundary: newPolygon.geometry,
      }));
      setSelectedPolygon({ ...newPolygon, isPreview: true });
    }

    if (freedrawRef.current.size() || event.eventType === 'clear') {
      setDrawMode(DRAW_MODE.EDIT);
    } else {
      handleDrawModeChange();
    }
  }, []);

  const handleMarkersDraw = useCallback((event) => {
    if (freedrawRef.current.size()) {
      const polygonPointArray = event.latLngs[0].map((point) => [point.lng, point.lat]);
      const newSelectorPolygon = turf.polygon([polygonPointArray]);
      setSelectorPolygon(newSelectorPolygon);
    }
  }, []);

  const selectPolygonsFromLasso = () => {
    const newSelections = polygons
      .filter((polygon) => doPolygonsIntersect(polygon.boundary, selectorPolygon));

    if (newSelections.length > 0) {
      setSelectedPolygon(polygons.find((polygon) => polygon.polygon_id === newSelections[0]));
    }

    clearFreedraw();

    if (newSelections.length) {
      const polygonIds = newSelections.map((polygon) => Number(polygon.polygon_id));
      dispatch(requestMultiplePolygonStatisticsAsync.request({
        polygon_ids: polygonIds,
      }));
      selectMultiplePolygons(polygonIds);

      setDrawMode(DRAW_MODE.EDIT);
      setIsPolygonDialogOpen(true);
    }
  };

  useEffect(() => {
    if (selectorPolygon && !selectedPolygon) {
      selectPolygonsFromLasso();
    }
  }, [selectorPolygon]);

  const formatFreedrawPolygon = (latLngs) => {
    const formattedLatLngs = [];
    latLngs.forEach((thisPoint) => {
      formattedLatLngs.push([thisPoint.lng, thisPoint.lat]);
    });

    return turf.polygon([formattedLatLngs]);
  };

  // Creates an editable polygon (which is a FreeDraw component)
  const createEditablePolygon = (polygon) => {
    const coordinates = polygon.boundary.coordinates[0].map((coord) => {
      return new LatLng(coord[1], coord[0]);
    });

    clearFreedraw();
    freedrawRef.current.create(coordinates, {
      ...polygonStyle,
      fillColor: polygon.fillColor,
      fillOpacity: 0,
      color: sptConstants.POLYGON_SELECTED,
      weight: 3,
    });
  };

  return (
    <div>
      {isPreviewLoading && <LoaderControl />}

      <Pane name={'teamPolygons'} style={{ zIndex: 500 }}>
        <Freedraw
          mode={drawMode}
          concavePolygon={concavePolygon}
          eventHandlers={{
            markers: (event) => {
              if (event.eventType === 'clear') {
                setDrawMode(DRAW_MODE.EDIT);

                return;
              }

              if (selectedPolygon && !selectedPolygon.isPreview) {
                handleMarkersEdit(event, selectedPolygon);
              } else if (pinMode) {
                handlePreviewMarkersEdit(event);
              }

              if (!pinMode) {
                handleMarkersDraw(event);
              }
            },
          }}
          ref={freedrawRef}
          color={selectedPolygon ? selectedPolygon?.fillColor : sptConstants.POLYGON_COLOR_UNASSIGNED}
        />

        {mapPolygons && mapPolygons.map((polygon) => {
          if (!selectedPolygon || (selectedPolygon.polygon_id !== polygon.polygon_id)) {
            const selected = multipleSelection.includes(polygon.polygon_id);
            const polygonColor = polygon.fillColor || sptConstants.POLYGON_COLOR_UNASSIGNED;

            const style = {
              ...polygonStyle,
              fillColor: polygonColor,
              fillOpacity: selected ? 0 : polygonStyle.fillOpacity,
              color: selected ? sptConstants.POLYGON_SELECTED : polygonColor,
              weight: selected ? 3 : polygon.weight,
            };

            return (
              <FeatureGroup key={polygon.polygon_id}>
                <GeoJSON
                  pathOptions={style}
                  data={polygon.boundary}
                  eventHandlers={{
                    click: (event) => {
                      const polygonId = polygon.polygon_id;
                      if (multipleSelection.length && (event.originalEvent.ctrlKey || event.originalEvent.metaKey)) {
                        const newSelections = [...multipleSelection];
                        const selected = multipleSelection.includes(polygonId);
                        if (selected) {
                          const remainingSelection = multipleSelection.filter((polygon) => polygon !== polygonId);
                          selectMultiplePolygons(remainingSelection);
                        } else {
                          newSelections.push(polygonId);
                          setSelectedPolygon(polygon);
                          selectMultiplePolygons(newSelections);
                          dispatch(requestPolygonStatisticsAsync.request({
                            polygon_id: polygonId,
                          }));
                        }
                      } else {
                        setSelectedPolygon(polygon);
                        onPolygonSelect(polygon);
                        selectMultiplePolygons([polygonId]);
                      }
                    },
                  }}
                >
                  {showReps && (
                    <RepBubbleCluster
                      reps={polygon.reps}
                      lat={polygon.center_latitude}
                      lng={polygon.center_longitude}
                    />
                  )}
                </GeoJSON>
              </FeatureGroup>
            );
          }
        })}
      </Pane>
    </div>
  );
};

TeamPolygons.propTypes = {
  setIsPolygonDialogOpen: PropTypes.func,
  closeDialog: PropTypes.func,
  teamId: PropTypes.string,
  enableLassoControl:  PropTypes.bool,
  selectedPolygon: PropTypes.object,
  setSelectedPolygon: PropTypes.func,
  multipleSelection: PropTypes.array,
  selectMultiplePolygons: PropTypes.func,
  polygons: PropTypes.array,
  onPolygonSelect: PropTypes.func,
  onPolygonEdit: PropTypes.func,
};

export default TeamPolygons;
