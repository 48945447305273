import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Loader, SideDrawerWrapper, WizardProfileModal, ConfirmationModal } from '@/components';
import { BasicInfo } from './UserTabs/BasicInfo';
import { AccessPermissions } from './UserTabs/AccessPermissions';
import { dashboardConstants, formatPhone, formatPostalCode, onboardingConstants } from '@/lib';
import { usersManagementConstants } from '@/modules/dashboard/components/UsersManagement/lib/constants';
import {
  createUserAsync,
  updateUserAsync,
  requestUserAsync,
  clearUser,
  updateUserSalesChannelsAsync,
} from '@/redux/users';
import { removeAllErrorsAction } from '@/redux/errors';
import { FormProvider, useForm } from 'react-hook-form';
import { combinedValidationSchema } from '@/modules/dashboard/components/UsersManagement/forms/ValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { isAdminSelector } from '@/redux/auth';
import FormHeader from './FormHeader';
import { selectStates } from '@/redux/addresses';
import { userFormDataAdapter } from '@/modules/dashboard/components/UsersManagement/lib/helpers/userForm';
import { ledgerConstants } from '@/modules/Housing/lib';
import { userLoadingSelector } from '@/redux/loading';

const { BASIC_INFO, ACCESS_PERMISSIONS } = dashboardConstants;
const {
  QUIT_FORM_CONFIRMATION_BUTTON,
  RETURN_TO_FORM_BUTTON,
  QUIT_FORM_CONFIRMATION_TITLE,
  QUIT_FORM_CONFIRMATION_MESSAGE,
} = usersManagementConstants;

const {
  DEALER_NAME,
  TEAM_NAME,
} = ledgerConstants;

const {
  EMAIL_NAME,
  PHONE_NAME,
} = dashboardConstants;

const {
  FIRST_NAME,
  LAST_NAME,
  ADDRESS_ONE,
  CURRENT_ADDRESS_ONE,
  CURRENT_ADDRESS_CITY,
  CURRENT_ADDRESS_STATE,
  CURRENT_ADDRESS_ZIP,
  CURRENT_ADDRESS_COUNTRY,
  IS_CURRENT_ADDRESS_DIFFER,
  ADDRESS_CITY,
  ADDRESS_COUNTRY,
  ADDRESS_STATE,
  ADDRESS_ZIP,
  WORKDAY_ID,
  PEST_ROUTES_ID,
  MD_HOLDER,
  ACTIVE_NAME,
  VISIBLE_NAME,
  APP_ACCESS_NAME,
  GROUP_NAME,
  UNSUBSCRIBED_NAME,
  AREA_MANAGEMENT_NAME,
  PASSWORD_NAME,
  REPEAT_PASSWORD_NAME,
  SALES_CHANNELS_FIELD_NAME,
} = onboardingConstants;

const UserForm = ({
  onClose,
  isOpen,
  selectedUser,
  salesManagementUserInfo,
  groups,
}) => {
  const [selectedTab, setSelectedTab] = useState(BASIC_INFO);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [fullProfileOpen, setFullProfileOpen] = useState(false);
  const [salesChannelsChanged, setSalesChannelsChanged] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.manageUserDetails.user);
  const userIsLoading = useSelector(userLoadingSelector);
  const isAdmin = useSelector(isAdminSelector);
  const states = useSelector(selectStates);
  const prevUserIdRef = useRef(null);
  const isUserLoading = useSelector((state) => state.manageUserDetails.loading);

  useEffect(() => {
    if (!selectedUser) {
      prevUserIdRef.current = null;
      dispatch(clearUser());

      return;
    }

    if (
      selectedUser?.user_id &&
      selectedUser.user_id !== prevUserIdRef.current &&
      !isUserLoading &&
      (!user || user.id !== selectedUser.user_id)
    ) {
      prevUserIdRef.current = selectedUser.user_id;
      dispatch(requestUserAsync.request(selectedUser.user_id));
    }
  }, [selectedUser, dispatch, isUserLoading, user]);

  const methods = useForm({
    defaultValues: { ...user },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(combinedValidationSchema(!!user)),
    context: { isAdmin: true },
  });

  const { handleSubmit, formState: { errors }, setValue, getValues, reset } = methods;

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const basicInfoFields = [
        FIRST_NAME,
        LAST_NAME,
        EMAIL_NAME,
        PHONE_NAME,
        ADDRESS_ONE,
        ADDRESS_CITY,
        ADDRESS_COUNTRY,
        ADDRESS_STATE,
        ADDRESS_ZIP,
        CURRENT_ADDRESS_ONE,
        CURRENT_ADDRESS_CITY,
        CURRENT_ADDRESS_STATE,
        CURRENT_ADDRESS_ZIP,
        CURRENT_ADDRESS_COUNTRY,
        PASSWORD_NAME,
        REPEAT_PASSWORD_NAME,
        IS_CURRENT_ADDRESS_DIFFER,
      ];

      const accessPermissionsFields = [
        DEALER_NAME,
        TEAM_NAME,
        GROUP_NAME,
        ACTIVE_NAME,
        VISIBLE_NAME,
        APP_ACCESS_NAME,
        UNSUBSCRIBED_NAME,
        AREA_MANAGEMENT_NAME,
        SALES_CHANNELS_FIELD_NAME,
      ];

      const hasBasicInfoErrors = basicInfoFields.some((field) => errors[field]);
      const hasAccessPermissionsErrors = accessPermissionsFields.some((field) => errors[field]);

      if (hasBasicInfoErrors) {
        setSelectedTab(BASIC_INFO);
      } else if (hasAccessPermissionsErrors) {
        setSelectedTab(ACCESS_PERMISSIONS);
      }
    }
  }, [errors]);

  const onFormClose = useCallback((withReload = false) => {
    reset(
      Object.keys(getValues()).reduce((acc, key) => {
        const value = getValues()[key];

        if (Array.isArray(value)) {
          acc[key] = [];
        } else if (typeof value === 'boolean') {
          acc[key] = false;
        } else if (typeof key === IS_CURRENT_ADDRESS_DIFFER) {
          acc[key] = 'no';
        } else {
          acc[key] = '';
        }

        return acc;
      }, {}),
    );
    setValue(SALES_CHANNELS_FIELD_NAME, salesManagementUserInfo?.channel_ids || []);
    setSalesChannelsChanged(false);
    dispatch(clearUser());
    onClose(withReload);
    setIsChanged(false);
    setConfirmationOpen(false);
    setSelectedTab(BASIC_INFO);
  }, [
    onClose,
    setSelectedTab,
    setIsChanged,
    reset,
    dispatch,
    getValues,
    salesManagementUserInfo?.channel_ids,
    setValue,
  ]);

  const handleSubmitForm = useCallback( async () => {
    const sendingData = userFormDataAdapter(getValues());
    if (user) {
      const salesChannelsUpdated = !salesChannelsChanged
        || await new Promise((resolve) => {
          dispatch(updateUserSalesChannelsAsync.request({
            userId: user.id,
            salesChannelIds: getValues(SALES_CHANNELS_FIELD_NAME),
            successCallback: () => {
              setSalesChannelsChanged(false);
              resolve(true);
            },
            errorCallback: () => {
              resolve(false);
            },
          }));
        });

      if (!salesChannelsUpdated) {
        return;
      }

      dispatch(updateUserAsync.request({
        userId: user.id,
        data: sendingData,
        successCallback: () => onFormClose(true),
      }));
    } else {
      dispatch(createUserAsync.request({
        data: sendingData,
        successCallback: () => onFormClose(true),
      }));
    }
  }, [user, dispatch, getValues, salesChannelsChanged, setSalesChannelsChanged, onFormClose]);

  useEffect(() => {
    if (user) {
      reset({
        [FIRST_NAME]: user.first_name || '',
        [LAST_NAME]: user.last_name || '',
        [EMAIL_NAME]: user.email || '',
        [PHONE_NAME]: user.phone_number ? formatPhone(user.phone_number) : '',
        [ADDRESS_ONE]: user.permanent_address || '',
        [ADDRESS_COUNTRY]: user.permanent_country || '',
        [ADDRESS_CITY]: user.permanent_city || '',
        [ADDRESS_STATE]: user.permanent_state || '',
        [ADDRESS_ZIP]: user.permanent_zip ? formatPostalCode(user.permanent_zip) : '',
        [IS_CURRENT_ADDRESS_DIFFER]: user.is_different_address || 'no',
        [CURRENT_ADDRESS_ONE]: user.street || '',
        [CURRENT_ADDRESS_COUNTRY]: user.country || '',
        [CURRENT_ADDRESS_CITY]: user.city || '',
        [CURRENT_ADDRESS_STATE]: user.state || '',
        [CURRENT_ADDRESS_ZIP]: user.zip ? formatPostalCode(user.zip) : '',
        [WORKDAY_ID]: user.workday_id || '',
        [PEST_ROUTES_ID]: user.pest_routes_id || '',
        [MD_HOLDER]: user.md_holder || false,
        [DEALER_NAME]: user.dealer_id || '',
        [TEAM_NAME]: user.team_id || '',
        [GROUP_NAME]: user.permission_group || '',
        [ACTIVE_NAME]: user.active || false,
        [VISIBLE_NAME]: user.visible || false,
        [APP_ACCESS_NAME]: user.app_access || false,
        [UNSUBSCRIBED_NAME]: user.unsubscribed || false,
        [AREA_MANAGEMENT_NAME]: user.area_management || false,
        [PASSWORD_NAME]: '',
        [REPEAT_PASSWORD_NAME]: '',
      });
    }
  }, [user, reset, states]);

  const onChangeHandler = useCallback((event) => {
    const { name, value, type, checked } = event.target;

    setValue(name, type === 'checkbox' ? checked : value, { shouldValidate: true, shouldDirty: true });

    setIsChanged(true);
  }, [setValue]);

  const onBlurTrimSpace = useCallback((event, name) => {
    onChangeHandler({
      target: {
        name,
        value: event.target.value.trim(),
      },
      type: event.type,
    });
  }, [onChangeHandler]);

  const renderTab = useCallback((tab) => {
    switch (tab) {
      case BASIC_INFO:
        return (
          <BasicInfo
            user={user}
            onChangeHandler={onChangeHandler}
            states={states}
            onBlurTrimSpace={onBlurTrimSpace}
          />
        );
      case ACCESS_PERMISSIONS:
        return (
          <AccessPermissions
            user={user}
            groups={groups}
            onChangeHandler={onChangeHandler}
            salesManagementUserInfo={salesManagementUserInfo}
            setSalesChannelsChanged={setSalesChannelsChanged}
          />
        );
      default:
        return (
          <BasicInfo
            user={user}
            onChangeHandler={onChangeHandler}
            states={states}
            onBlurTrimSpace={onBlurTrimSpace}
          />
        );
    }
  }, [onChangeHandler, user, groups, salesManagementUserInfo, onBlurTrimSpace, states]);

  const updateSelectedTab = (selectedTab) => {
    setSelectedTab(selectedTab);
  };

  const onCancelClick = () => {
    dispatch(removeAllErrorsAction());
    if (isChanged) {
      setConfirmationOpen(true);
    } else {
      onFormClose();
    }
  };

  const tabItems = [
    BASIC_INFO,
    ACCESS_PERMISSIONS,
  ];

  const openFullProfile = () => {
    setFullProfileOpen(true);
  };

  const onFullProfileClose = () => {
    onFormClose();
    setFullProfileOpen(false);
  };

  const renderTabButtons = () => {
    return (
      <div className="border-b border-gray-200 pt-4">
        <div className="px-6">
          <nav className="flex -mb-px space-x-6">
            {
              tabItems.map((item, index) => {
                return (
                  <button
                    type="button"
                    key={index}
                    onClick={() => updateSelectedTab(item)}
                    className={`${selectedTab === item
                      ? 'border-aptivegreen text-black font-bold border-b-4'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 font-medium border-b-2'
                    } whitespace-nowrap pb-4 px-1 text-sm`}
                  >
                    {item}
                  </button>
                );
              })
            }
          </nav>
        </div>
      </div>
    );
  };

  return (
    <SideDrawerWrapper isOpened={isOpen} onCloseModal={onCancelClick} large>
      <FormHeader
        user={user}
        userIsLoading={userIsLoading}
        openFullProfile={openFullProfile}
      />
      {renderTabButtons()}
      {userIsLoading ? (
        <div className="flex items-center justify-center h-40">
          <Loader />
        </div>
      ) :
        (
          <div className="flex-1 px-6 pt-5 pb-24 border-b border-gray-200 sm:px-6">
            <FormProvider {...methods}>
              <form noValidate className="w-full" onSubmit={handleSubmit(handleSubmitForm)}>
                {renderTab(selectedTab)}
                <div className="max-w-xl w-screen h-20 bg-white absolute right-0 bottom-0 border border-t border-gray-200">
                  <div className="sm:col-span-6 flex justify-end absolute right-2 bottom-2">
                    <button
                      onClick={onCancelClick}
                      type="button"
                      className="border border-gray-200 inline-flex justify-center mr-2 px-4 py-2 text-base bg-white font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-aptivegreen-500 sm:w-40 sm:text-sm"
                    >
                      Cancel
                    </button>
                    <button
                      disabled={!isAdmin}
                      type="submit"
                      className="border border-gray-200 inline-flex justify-center mr-2 px-4 py-2 text-white bg-blue-600 font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-aptivegreen-500 sm:w-40 sm:text-sm"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        )}
      <ConfirmationModal
        isOpened={confirmationOpen}
        modalWidth="max-w-[592px] w-full"
        onAction={onFormClose}
        onCancel={() => setConfirmationOpen(false)}
        title={QUIT_FORM_CONFIRMATION_TITLE}
        message={QUIT_FORM_CONFIRMATION_MESSAGE}
        cancelLabel={RETURN_TO_FORM_BUTTON}
        confirmLabel={QUIT_FORM_CONFIRMATION_BUTTON}
      />
      <WizardProfileModal
        isOpen={fullProfileOpen}
        onClose={onFullProfileClose}
        isPersonalWizard={false}
        userId={user?.id}
      />
    </SideDrawerWrapper>
  );
};

UserForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectedUser: PropTypes.object,
  salesManagementUserInfo: PropTypes.object,
  groups: PropTypes.array.isRequired,
};

export default UserForm;
