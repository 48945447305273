import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Api from '@/api';
import { addFsExcludeClass } from '@/lib/utils';
import { CustomFormElement } from '@/components/common';
import { ledgerConstants } from '@/modules/Housing/lib';
import { addToastsAction } from '@/redux/toasts';
import { mapErrorToastsData } from '@/lib/api';

const { MD_USER_ID_NAME, MD_USER_ID_LABEL, PARTNERSHIP_NAME } = ledgerConstants;

const MdSearch = ({ canEditField, errors, onMdChange }) => {
  const { control, setValue } = useFormContext();
  const dispatch = useDispatch();

  const [timeoutId, setTimeoutId] = useState(null);
  const [options, setOptions] = useState([]);
  const [isMdHolderLoading, setIsMdHolderLoading] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(true);

  const repId = useWatch({ control, name: MD_USER_ID_NAME });
  const partnershipId = useWatch({ control, name: PARTNERSHIP_NAME });

  const [params, setParams] = useState({
    page: {
      number: 1,
      size: 50,
    },
    filter: {
      md_holder: 1,
    },
  });

  useEffect(() => {
    if (partnershipId == params.filter.partner_id) {
      return;
    }

    setOptions([]);
    setHasMorePages(true);

    setParams((prevParams) => {
      const updatedFilter = { ...prevParams.filter };

      if (!partnershipId) {
        delete updatedFilter.partner_id;
      } else {
        updatedFilter.partner_id = partnershipId;
      }

      return {
        ...prevParams,
        page: {
          ...prevParams.page,
          number: 1,
        },
        filter: updatedFilter,
      };
    });
  }, [partnershipId]);

  const [error, setError] = useState(null);

  useEffect(() => {
    if (errors?.[MD_USER_ID_NAME]) {
      setError(errors[MD_USER_ID_NAME]);
    }
  }, [errors]);

  const onSearch = (name) => {
    if ((name === '' && params.filter.search === undefined) || name == params.filter.search) {
      return;
    }

    if (!name) {
      setParams((prevParams) => {
        const updatedFilter = { ...prevParams.filter };
        delete updatedFilter.search;

        return {
          ...prevParams,
          page: {
            ...prevParams.page,
            number: 1,
          },
          filter: updatedFilter,
        };
      });

      return;
    }

    setParams((prevParams) => ({
      ...prevParams,
      filter: {
        ...prevParams.filter,
        search: name,
      },
    }));
  };

  const fetchMdUsers = useCallback(() => {
    if (!params.filter?.partner_id) {
      return;
    }

    clearTimeout(timeoutId);
    setIsMdHolderLoading(true);

    const delayedRequest = setTimeout(() => {
      const getUsers = Api.getUsers();

      getUsers(params)
        .then((response) => {
          setError(null);
          setIsMdHolderLoading(false);

          const data = response?.data?.map((user) => ({
            value: +user?.id,
            label: user?.attributes?.name,
          })) ?? [];
          let newOptions;

          if (params.page.number === 1) {
            newOptions = data;
          } else {
            newOptions = [...options, ...data];
          }

          setOptions(newOptions);

          if (response?.meta?.total <= newOptions.length) {
            setHasMorePages(false);
          }
        })
        .catch((error) => {
          // Unpack Axios error
          const status = error?.response?.status;
          const message = error?.response?.data?.message || error.message;
          const details = error?.response?.data?.errors;

          setError({
            status,
            message,
            details,
          });
          setIsMdHolderLoading(false);

          setOptions([]);

          dispatch(addToastsAction(mapErrorToastsData(error)));
        });
    }, 500); // (.5 seconds) Adjust the delay time as needed

    setTimeoutId(delayedRequest);

    return () => clearTimeout(delayedRequest);
  }, [dispatch, params]);

  useEffect(() => {
    fetchMdUsers();
  }, [fetchMdUsers]);

  const handleChange = ({ target: { value } }) => {
    onMdChange(value);
  };

  const loadMoreOptions = useCallback(() => {
    if (!hasMorePages) {
      return;
    }

    setParams({
      ...params,
      page: {
        ...params.page,
        number: params.page.number + 1,
      },
    });
  }, [hasMorePages, params]);

  return (
    <CustomFormElement
      colSpan={3}
      options={options}
      onChange={handleChange}
      value={repId || null}
      onInputChange={onSearch}
      filterOption={() => true}
      placeholder={
        isMdHolderLoading ?
          'Loading...' :
          partnershipId ? '-- Select --' : '-- Select Partnership first --'
      }
      id={MD_USER_ID_NAME}
      name={MD_USER_ID_NAME}
      label={MD_USER_ID_LABEL}
      type="multiSelect"
      disabled={!canEditField(MD_USER_ID_NAME) || !partnershipId}
      hasError={error !== null}
      className={addFsExcludeClass()}
      isClearable
      isLoading={isMdHolderLoading}
      onMenuScrollToBottom={loadMoreOptions}
      styles={{
        singleValue: (provided) => ({ ...provided, color: 'black' }),
        placeholder: (provided) => ({
          ...provided,
          color: !canEditField(MD_USER_ID_NAME) || !partnershipId ? 'inherit': 'black',
        }),
      }}
    />
  );
};

MdSearch.propTypes = {
  canEditField: PropTypes.func.isRequired,
  errors: PropTypes.object,
  onMdChange: PropTypes.func.isRequired,
};

export default MdSearch;
