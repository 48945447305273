import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CustomButtonGroup, Loader } from '@/components/common';
import FileInput from './FileInput';
import FilePreview from './FilePreview';
import heic2any from 'heic2any';

const CustomFile = ({
  inputFileName,
  inputFileId,
  id,
  name,
  url,
  localFile,
  fileType,
  register,
  onChange,
  isFileLoading,
  onSaveClick,
  onCancelClick,
  disabled,
  acceptTypes,
  dontShowActions,
  withPreview,
  fileName,
  hasError,
  baseClasses,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [previewSrc, setPreviewSrc] = useState(null);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);

  useEffect(() => {
    if (localFile) {
      setIsPreviewLoading(true);
      heic2any({ blob: localFile, toType: 'jpeg' })
        .then((convertedBlob) => {
          setPreviewSrc(URL.createObjectURL(convertedBlob));
          setIsPreviewLoading(false);
        })
        .catch(() => {
          setPreviewSrc(URL.createObjectURL(localFile));
          setIsPreviewLoading(false);
        });
    } else if (url) {
      setPreviewSrc(url);
    }
  }, [localFile, url]);

  const displayText = useMemo(() => {
    const fileUrlIsSetInDB = fileName?.length > 0;
    const localFileSelected = localFile?.name?.length > 0;

    if (localFileSelected) {
      return localFile?.name;
    } else if (fileUrlIsSetInDB) {
      return fileName;
    }
  }, [fileName, localFile]);

  const fileTypes = acceptTypes?.length
    ? acceptTypes.map((format) => `${fileType}/${format}`).join(',')
    : `${fileType}/*`;

  const fileFormats = acceptTypes?.length
    ? acceptTypes.join(', ')
    : '';

  const showActions = useMemo(() => {
    return !dontShowActions && localFile?.name?.length > 0 && !isFileLoading;
  }, [dontShowActions, isFileLoading, localFile]);

  const onDeleteClick = () => {
    if (!disabled && !isFileLoading && !isPreviewLoading) {
      onCancelClick(inputFileName);
      onCancelClick(name);
    }
  };

  const cursorClass = isFileLoading || isPreviewLoading ? 'cursor-not-allowed' : 'cursor-pointer';

  return (
    <>
      <FileInput
        inputFileName={inputFileName}
        inputFileId={inputFileId}
        name={name}
        id={id}
        fileType={fileType}
        register={register}
        onChange={onChange}
        disabled={disabled || isPreviewLoading}
        fileTypes={fileTypes}
        fileFormats={fileFormats}
        isFileLoading={isFileLoading || isPreviewLoading}
        isDragging={isDragging}
        setIsDragging={setIsDragging}
        cursorClass={cursorClass}
        previewSrc={previewSrc}
        withPreview={withPreview}
        localFile={localFile}
        fileName={fileName}
        isPreviewLoading={isPreviewLoading}
        baseClasses={baseClasses}
        hasError={hasError}
      />
      <FilePreview
        displayText={displayText}
        previewSrc={previewSrc}
        isFileLoading={isFileLoading || isPreviewLoading}
        cursorClass={cursorClass}
        onDeleteClick={onDeleteClick}
        disabled={disabled}
      />
      {(fileName?.length > 0 || localFile?.name?.length > 0) && isFileLoading && (
        <Loader className="p-4 justify-start" color="#2F73DA" />
      )}
      {showActions && (
        <CustomButtonGroup
          color="aptiveblue"
          saveText="Upload"
          cancelText="Cancel"
          wrapperClassName="mt-4"
          onSaveClick={onSaveClick}
          onCancelClick={onDeleteClick}
        />
      )}
    </>
  );
};

CustomFile.propTypes = {
  inputFileName: PropTypes.string.isRequired,
  inputFileId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  localFile: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  fileType: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isFileLoading: PropTypes.bool,
  onSaveClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  acceptTypes: PropTypes.arrayOf(PropTypes.string),
  dontShowActions: PropTypes.bool,
  withPreview: PropTypes.bool,
  fileName: PropTypes.string,
  hasError: PropTypes.bool,
  baseClasses: PropTypes.shape({
    errorClasses: PropTypes.string,
    standardClasses: PropTypes.string,
  })
};

export default CustomFile;
