import { useCallback, useEffect, useState } from 'react';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import { CustomButton, ConfirmationModal } from '@/components';
import { Button } from '@/modules/Housing/components/common';
import LedgerFooter from './LedgerFooter';
import LedgerHeader from './LedgerHeader';
import LedgerInfo from './LedgerInfo';
import LedgerHistory from './LedgerHistory';
import { ledgerValidationSchema } from './LedgerValidationSchema';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import {
  createLedgerAsync,
  editLedgerAsync,
  archiveLedgerAsync,
  unArchiveLedgerAsync,
  ledgerSelector,
  createNoteAsync,
} from '@/modules/Housing/redux/ledger';
import PropTypes from 'prop-types';
import NotesForm from '../Notes/NotesForm';
import { useStableCallback } from '@/hooks';
import { PageLoader } from '@/components/common';
import { isLedgerFormLoadingSelector } from '@/modules/Housing/redux/loading';
import { formatNumberToCurrencyString, formatDate } from '@/lib/utils';
import { useLedgerEditable } from '@/modules/Housing/hooks';
import { ledgerConstants } from '@/modules/Housing/lib';

const {
  APARTMENT_IDS_NAME,
  APARTMENT_ID_NAME,
  TEAM_NAME,
  BRANCH_NAME,
  DEALER_NAME,
  PARTNERSHIP_NAME,
  MD_USER_ID_NAME,
} = ledgerConstants;

const LedgerForm = ({
  onClose,
  refreshLedgers,
  recruitingSeasonId,
}) => {
  const dispatch = useDispatch();
  const isLedgerFormLoading = useSelector(isLedgerFormLoadingSelector);
  const ledger = useSelector(ledgerSelector);
  const [noteValue, setNoteValue] = useState('');
  const [isSearchTab, setIsSearchTab] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [mdConfirmationOpen, setMdConfirmationOpen] = useState(false);
  const [mdValue, setMdValue] = useState(null);
  const [hasMdConfirmed, setHasMdConfirmed] = useState(false);

  const methods = useForm({
    defaultValues: {
      ...ledger,
      amount_paid: formatNumberToCurrencyString(ledger.amount_paid, 2),
      amount_to_pay: formatNumberToCurrencyString(ledger.amount_to_pay, 2),
      furniture_damaged: formatNumberToCurrencyString(
        ledger.furniture_damaged,
        2,
      ),
      rep_utilities: formatNumberToCurrencyString(ledger.rep_utilities, 2),
      apartment_charges: formatNumberToCurrencyString(
        ledger.apartment_charges,
        2,
      ),
      date_due: formatDate(ledger.date_due, 'YYYY-MM-DD'),
      date_paid: formatDate(ledger.date_paid, 'YYYY-MM-DD'),
      is_overnight_payment_request : ledger.is_overnight_payment_request,
      note: null,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(ledgerValidationSchema(ledger.id)),
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    setValue,
    getValues,
    reset,
  } = methods;

  const onFormClose = () => {
    reset();
    onClose();
    refreshLedgers();
  };

  const createLedgerNote = (ledgerId) => {
    if (noteValue === '') {
      onFormClose();
    } else {
      dispatch(createNoteAsync.request({
        data: { ledgerRecordId: ledgerId, note: noteValue },
        successCallback: onFormClose,
      }));
    }
  };

  const doArchive = () => {
    if (ledger.is_deleted) {
      dispatch(unArchiveLedgerAsync.request({ ledgerId: ledger.id, successCallback: onFormClose }));

      return;
    }
    dispatch(archiveLedgerAsync.request({ ledgerId: ledger.id, successCallback: onFormClose }));
  };

  const handleClickArchive = () => {
    setConfirmationOpen(true);
  };

  const onConfirmationCancel = () => {
    setConfirmationOpen(false);
  };

  const onConfirmationOk = () => {
    setConfirmationOpen(false);
    doArchive();
  };

  const onMdChangeConfirm = () => {
    setValue(MD_USER_ID_NAME, mdValue, { shouldValidate: true, shouldDirty: true });
    setMdConfirmationOpen(false);
    setMdValue(null);
    setHasMdConfirmed(true);
  };

  const onMdChangeCancel = () => {
    setValue(MD_USER_ID_NAME, ledger.md_user_id, { shouldValidate: true, shouldDirty: true });
    setMdConfirmationOpen(false);
    setMdValue(null);
  };

  const handleSubmitForm = useStableCallback(() => {
    if (ledger.id) {
      dispatch(editLedgerAsync.request({
        ledgerRecordId: ledger.id,
        data: {
          ...getValues(),
          recruiting_season_id: recruitingSeasonId,
        },
        successCallback: () => createLedgerNote(ledger.id),
      }));
    } else {
      const values = getValues();

      if (values[MD_USER_ID_NAME] === null) {
        delete values[MD_USER_ID_NAME];
      }

      dispatch(createLedgerAsync.request({
        data: {
          ...values,
          recruiting_season_id: recruitingSeasonId,
        },
        successCallback: (ledgerId) => createLedgerNote(ledgerId),
      }));
    }
  });

  const resetApartmentField = useCallback(() => {
    if (!ledger.id) {
      setValue(APARTMENT_IDS_NAME, [], { shouldValidate: true, shouldDirty: true });
    } else {
      setValue(APARTMENT_ID_NAME, null, { shouldValidate: true, shouldDirty: true });
    }
  }, [ledger.id, setValue]);

  const handleChange = useCallback((event) => {
    const { name, value, type, checked } = event.target;

    setValue(name, type === 'checkbox' ? checked : value, { shouldValidate: true, shouldDirty: true });
  }, [setValue]);

  const onPartnershipChange = useCallback((event) => {
    const { value } = event.target;
    setValue(PARTNERSHIP_NAME, value, { shouldValidate: true, shouldDirty: true });

    setValue(MD_USER_ID_NAME, null, { shouldValidate: true, shouldDirty: true });
  }, [setValue]);

  const handleMdChange = (value) => {
    if (ledger.id && !hasMdConfirmed) {
      setMdConfirmationOpen(true);
      setMdValue(value);
    } else {
      setValue(MD_USER_ID_NAME, value, { shouldValidate: true, shouldDirty: true });
    }
  };

  const handleTeamChange = useCallback((event) => {
    const { value } = event.target;
    setValue(TEAM_NAME, value, { shouldValidate: true, shouldDirty: true });

    resetApartmentField();
  }, [setValue, resetApartmentField]);

  const handleBranchChange = useCallback((event) => {
    const { value } = event.target;
    setValue(BRANCH_NAME, value, { shouldValidate: true, shouldDirty: true });

    setValue(TEAM_NAME, null, { shouldValidate: true, shouldDirty: true });
    resetApartmentField();
  }, [setValue, resetApartmentField]);

  const handleDealerChange = useCallback((event) => {
    const { value } = event.target;
    setValue(DEALER_NAME, value, { shouldValidate: true, shouldDirty: true });

    setValue(TEAM_NAME, null, { shouldValidate: true, shouldDirty: true });
    setValue(PARTNERSHIP_NAME, null, { shouldValidate: true, shouldDirty: true });
    resetApartmentField();
  }, [setValue, resetApartmentField]);

  const { canEditField } = useLedgerEditable({ ledgerId: ledger.id, immutableFields: ledger.immutable_fields });

  const handleClickHistory = () => {
    setIsSearchTab(true);
  };

  return (
    <div className="w-[989px]">
      <FormProvider {...methods}>
        <form noValidate onSubmit={handleSubmit(handleSubmitForm)}>
          <LedgerHeader>
            <div className="flex justify-between">
              <div className="flex items-center">
                <OfficeBuildingIcon color="gray" className="mr-2 w-6 h-6" />
                {ledger.id ? (
                  <h1 className="font-semibold text-lg">
                    Ledger record: #
                    {ledger.id}
                  </h1>
                ) : (
                  <h1 className="font-semibold text-lg">
                    Add new ledger record
                  </h1>
                )}
              </div>
              {ledger.id && (
                <div className="flex items-center gap-8">
                  {!isSearchTab && (
                    <Button
                      color={'default'}
                      className={
                        'font-inter text-base font-normal leading-6 text-right text-gray-700'
                      }
                      onClick={handleClickHistory}
                    >
                      View history
                    </Button>
                  )}
                  <Button
                    color={'default'}
                    className={
                      'font-inter text-base font-normal leading-6 text-right text-gray-700 border border-gray-300 rounded-md px-3 py-2'
                    }
                    onClick={handleClickArchive}
                  >
                    {ledger.is_deleted ? 'Unarchive ledger' : 'Archive ledger'}
                  </Button>
                </div>
              )}
            </div>
          </LedgerHeader>
          <div className="flex flex-wrap overflow-hidden">
            {!isSearchTab ? (
              <>
                <div className="p-6 w-full sm:w-2/3 sm:h-[474px] overflow-hidden sm:overflow-y-auto">
                  {isLedgerFormLoading ? (
                    <PageLoader />
                  ) : (
                    <LedgerInfo
                      onPartnershipChange={onPartnershipChange}
                      onTeamChange={handleTeamChange}
                      onBranchChange={handleBranchChange}
                      onDealerChange={handleDealerChange}
                      onChangeHandler={handleChange}
                      onMdChange={handleMdChange}
                      canEditField={canEditField}
                      ledgerId={ledger.id}
                      payStatusId={ledger.pay_status_id}
                      recruitingSeasonId={recruitingSeasonId}
                    />
                  )}
                </div>
                <div className="w-full sm:w-1/3 overflow-hidden">
                  <NotesForm setNoteValue={setNoteValue} note={noteValue} ledgerId={ledger.id} />
                </div>
              </>
            ) : (
              <LedgerHistory
                ledgerId={ledger.id}
                onClickBack={() => setIsSearchTab(false)}
              />
            )}
          </div>
          <LedgerFooter>
            <div className="flex">
              <div className="ml-auto">
                <CustomButton
                  color="white"
                  onClick={onClose}
                  className={'mr-4 text-gray-600 hover:bg-gray-100'}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  type="submit"
                  color="active"
                  disabled={!isValid || !isDirty}
                >
                  Submit
                </CustomButton>
              </div>
            </div>
          </LedgerFooter>
        </form>
      </FormProvider>
      <ConfirmationModal
        isOpened={confirmationOpen}
        modalWidth="max-w-[592px] w-full"
        onCancel={onConfirmationCancel}
        onAction={onConfirmationOk}
        title={`Are you sure you want to ${ledger.is_deleted ? 'unarchive' : 'archive'} this ledger?`}
        message={
          ledger.is_deleted ? 'By unarchiving this ledger, you will see it in the list of ledgers.'
            : 'By archiving this ledger, you will no longer see it in the list of ledgers.'}
        cancelLabel={'Cancel'}
        confirmLabel={`${ledger.is_deleted ? 'Unarchive' : 'Archive'}`}
        confirmButtonColor="aptiveblue"
      />
      <ConfirmationModal
        isOpened={mdConfirmationOpen}
        modalWidth="max-w-[592px] w-full"
        onCancel={onMdChangeCancel}
        onAction={onMdChangeConfirm}
        title="Confirm MD Change"
        message="Are you sure you want to change the MD?"
        confirmButtonColor="aptiveblue"
        confirmLabel={'Confirm'}
      />
    </div>
  );
};

LedgerForm.propTypes = {
  onClose: PropTypes.func,
  refreshLedgers: PropTypes.func,
  recruitingSeasonId: PropTypes.number,
};

export default LedgerForm;
