export const salesAreasManagementConstants = {
  CREATE_AREA_BUTTON_LABEL: 'Create Area',
  CREATE_AREA_HEADER: 'Create Area',
  EDIT_AREA_HEADER: 'Edit Area',
  AREA_ID_LABEL: 'Area ID',
  AREA_ID_VALUE: 'sales_area_id',
  AREA_NAME_LABEL: 'Area Name',
  AREA_NAME_VALUE: 'name',
  AREA_LAT_LABEL: 'Latitude',
  AREA_LAT_VALUE: 'lat',
  AREA_LONG_LABEL: 'Longitude',
  AREA_LONG_VALUE: 'long',
  AREA_TIMEZONE_LABEL: 'Timezone',
  AREA_TIMEZONE_VALUE: 'timezone_id',
  AREA_VISIBLE_LABEL: 'Visible',
  AREA_VISIBLE_VALUE: 'visible',
};
