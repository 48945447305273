import Api from '@/api';
import { createRequestSaga } from '@/redux/helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  requestUsersAsync,
  requestUserGroupsAsync,
  updateUserMdStatusAsync,
  restoreUserAsync,
  deleteUserAsync,
  updateUserSalesChannelsAsync,
  createUserAsync,
  requestUserAsync,
  updateUserAsync,
} from './actions';
import { addToastsAction } from '@/redux/toasts';
import { mapErrorToastsData } from '@/lib/api';
import { toastType } from '@/components';
import { HttpStatusCode } from 'axios';

function* requestUsersSaga({ payload }) {
  const isActive = payload?.filter?.is_active || false;
  const { successCallback, errorCallback, ...data } = payload;

  try {
    const response = yield call(
      Api.getUsers(isActive),
      { ...data },
    );

    yield put(requestUsersAsync.success(response));

    if (successCallback) {
      yield call(successCallback, response);
    }
  } catch (error) {
    yield put(addToastsAction(mapErrorToastsData(error)));

    if (errorCallback) {
      yield call(errorCallback, error);
    }

    throw error;
  }
}

function* requestUserSaga({ payload }) {
  if (!payload){
    return;
  }
  const { data } = yield call(
    Api.getUser(payload),
  );

  yield put(requestUserAsync.success({
    user: {
      ...data?.attributes,
      id: data?.attributes?.user_id,
    },
  }));
}

function* requestUserGroupsSaga({ payload }) {
  try {
    const response = yield call(
      Api.getUserGroups(),
      { ...payload },
    );

    yield put(requestUserGroupsAsync.success(response));
  } catch (error) {
    yield put(addToastsAction(mapErrorToastsData(error)));

    throw error;
  }
}

function* requestUpdateMdStatusSaga({ payload }) {
  const { userId } = payload;
  const result = yield call(
    Api.updateMdHolderStatus(userId),
  );

  yield put(updateUserMdStatusAsync.success(result));
  yield call(payload.callback);
}

function* updateUserSalesChannelsSaga({ payload }) {
  const { userId, salesChannelIds, errorCallback, successCallback } = payload;

  try {
    yield call(
      Api.updateUserSalesChannels(userId),
      { sales_channel_ids: salesChannelIds },
    );

    yield put(updateUserSalesChannelsAsync.success({ userId, salesChannelIds }));

    if (successCallback) {
      yield call(successCallback);
    }
  } catch (error) {
    yield put(addToastsAction(mapErrorToastsData(error)));

    if (errorCallback) {
      yield call(errorCallback, error);
    }

    throw error;
  }
}

function* createUserSaga({ payload: { data, successCallback, failureCallback } }) {
  try {
    const result = yield call(Api.createUser, data);

    yield put(createUserAsync.success(result));

    yield put(addToastsAction([{
      type: toastType.SUCCESS,
      message: 'Created user successfully',
      details: null,
    }]));

    yield call(successCallback);
  } catch (error) {
    const { response: { status, data: { errors } } } = error;

    if (status === HttpStatusCode.UnprocessableEntity){
      yield put(addToastsAction(mapErrorToastsData(error)));
    } else {
      yield put(addToastsAction([{
        type: toastType.ERROR,
        message:  'Server failed to create user',
        details: null,
      }]));
    }

    yield call(failureCallback);
  }
}

function* updateUserSaga({ payload: { userId, data, successCallback, failureCallback } }) {
  try {
    const result = yield call(
      Api.updateUser(userId),
      data,
    );

    yield put(updateUserAsync.success(result));

    yield put(addToastsAction([{
      type: toastType.SUCCESS,
      message: 'Updated user successfully',
      details: null,
    }]));

    yield call(successCallback);
  } catch (error) {
    const { response: { status, data: { errors } } } = error;

    if (status === HttpStatusCode.UnprocessableEntity){
      yield put(addToastsAction(mapErrorToastsData(error)));
    } else {
      yield put(addToastsAction([{
        type: toastType.ERROR,
        message:  'Server failed to update user',
        details: null,
      }]));
    }

    yield call(failureCallback);
  }
}

function* restoreUserSaga({ payload }) {
  const { userId } = payload;
  const result = yield call(
    Api.restoreUser(userId),
  );

  yield put(restoreUserAsync.success(result));
  yield call(payload.callback);
}

function* deleteUserSaga({ payload }) {
  const { userId } = payload;
  const result = yield call(
    Api.deleteUser(userId),
  );

  yield put(deleteUserAsync.success(result));
  yield call(payload.callback);
}

export function* usersActionWatcher() {
  yield takeLatest(
    requestUsersAsync.request,
    createRequestSaga(requestUsersSaga, {
      keyNew: 'users',
      errKey: 'users',
      write: false,
    }),
  );
  yield takeLatest(
    requestUserAsync.request.type,
    createRequestSaga(requestUserSaga, {
      keyNew: 'user',
      errKey: 'user',
      write: false,
    }),
  );
  yield takeLatest(
    requestUserGroupsAsync.request,
    createRequestSaga(requestUserGroupsSaga, {
      keyNew: 'userGroups',
      errKey: 'userGroups',
      write: false,
    }),
  );
  yield takeLatest(
    deleteUserAsync.request.type,
    createRequestSaga(deleteUserSaga, {
      keyNew: 'deleteUser',
      errKey: 'deleteUser',
      write: true,
    }),
  );
  yield takeLatest(
    restoreUserAsync.request.type,
    createRequestSaga(restoreUserSaga, {
      keyNew: 'restoreUser',
      errKey: 'restoreUser',
      write: true,
    }),
  );
  yield takeLatest(
    createUserAsync.request.type,
    createRequestSaga(createUserSaga, {
      keyNew: 'createUser',
      errKey: 'createUser',
      write: true,
    }),
  );
  yield takeLatest(
    updateUserAsync.request.type,
    createRequestSaga(updateUserSaga, {
      keyNew: 'updateUser',
      errKey: 'updateUser',
      write: true,
    }),
  );
  yield takeLatest(
    updateUserMdStatusAsync.request.type,
    createRequestSaga(requestUpdateMdStatusSaga, {
      keyNew: 'updateMdStatus',
      errKey: 'updateMdStatus',
      write: true,
    }),
  );
  yield takeLatest(
    updateUserSalesChannelsAsync.request.type,
    createRequestSaga(updateUserSalesChannelsSaga, {
      keyNew: 'updateUserSalesChannels',
      errKey: 'updateUserSalesChannels',
      write: true,
    }),
  );
}
