import { useMemo } from 'react';
import classNames from 'classnames';
import { Icon, Loader } from '..';
import {
  FormLabel,
  TextArea,
  CustomInput,
  CustomInputSelect,
  CustomErrorMessage,
  CustomRadioGroup,
  CustomSelect,
  CustomFile,
  CustomFileWithPreview,
  CustomDate,
  CustomNumber,
  MaskedInput,
  generateBaseClasses,
} from '.';
import { SearchSelectInput } from '@/components/common/inputs';
import { Select } from '@/components/common';
import CurrencyInput from '@/components/common/Form/CurrencyInput';
import PropTypes from 'prop-types';

const CustomFormElement = (props) => {
  const {
    id,
    type,
    label,
    error,
    colSpan,
    loading,
    required,
    isFileLoading,
    tooltipMessage,
    formElementWrapperClassName,
    elementWrapperClassName,
    baseWrapperClassName,
    isInlineWithLabel = false,
    labelPosition = 'before',
    requiredSymbolPosition = 'after',
  } = props;
  const formElementWrapperClasses = useMemo(
    () =>
      classNames(formElementWrapperClassName, `sm:col-span-${colSpan || 3}`),
    [colSpan, formElementWrapperClassName],
  );

  const elementWrapperClasses = useMemo(
    () =>
      classNames(
        'mt-0.5 block rounded-md',
        { 'shadow-sm': type !== 'checkbox' },
        elementWrapperClassName,
      ),
    [elementWrapperClassName, type],
  );

  const baseClasses = useMemo(
    () => generateBaseClasses(type, baseWrapperClassName),
    [type, baseWrapperClassName],
  );

  const ChildElement = {
    textArea: TextArea,
    checkbox: CustomInput,
    file: CustomFile,
    fileWithPreview: CustomFileWithPreview,
    text: CustomInput,
    textSelect: CustomInputSelect,
    number: CustomNumber,
    password: CustomInput,
    date: CustomDate,
    select: CustomSelect,
    currency: CurrencyInput,
    multiSelect: Select,
    selectSearch: SearchSelectInput,
    radio: CustomRadioGroup,
    masked: MaskedInput,
  }[type];

  return (
    <div className={formElementWrapperClasses}>
      <div
        className={classNames(
          'flex',
          isInlineWithLabel
            ? labelPosition === 'before' ? 'flex-row gap-2' : 'flex-row-reverse gap-2'
            : labelPosition === 'before' ? 'flex-col' : 'flex-col-reverse',
        )}
      >
        <div className="flex items-center">
          {label?.length > 0 && (
            <FormLabel
              label={label}
              required={required}
              htmlFor={id}
              requiredSymbolPosition={requiredSymbolPosition}
            />
          )}
          <div className={`ml-1 ${tooltipMessage ? 'block' : 'hidden'}`}>
            <Icon id={`${id}-icon`} place="top" icon="info" message={tooltipMessage} />
          </div>
        </div>
        {loading ? (
          <Loader className="mt-0.5 h-[38px] items-center justify-start px-3" />
        ) : (
          <div className={elementWrapperClasses}>
            <ChildElement
              baseClasses={baseClasses}
              hasError={!!error}
              {...props}
            />
          </div>
        )}
      </div>
      {error?.message && !isFileLoading && (
        <CustomErrorMessage text={error?.message} />
      )}
    </div>
  );
};

CustomFormElement.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf([
    'textArea',
    'checkbox',
    'file',
    'fileWithPreview',
    'text',
    'textSelect',
    'number',
    'password',
    'date',
    'select',
    'currency',
    'multiSelect',
    'selectSearch',
    'radio',
    'masked',
  ]),
  label: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  colSpan: PropTypes.number,
  loading: PropTypes.bool,
  required: PropTypes.bool,
  isFileLoading: PropTypes.bool,
  tooltipMessage: PropTypes.string,
  formElementWrapperClassName: PropTypes.string,
  elementWrapperClassName: PropTypes.string,
  baseWrapperClassName: PropTypes.string,
  isInlineWithLabel: PropTypes.bool,
  labelPosition: PropTypes.oneOf(['before', 'after']),
  requiredSymbolPosition: PropTypes.oneOf(['before', 'after']),
};

export default CustomFormElement;
