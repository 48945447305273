import PropTypes from 'prop-types';
import classNames from 'classnames';

const FormLabel = ({
  label,
  htmlFor,
  required,
  requiredSymbolPosition,
}) => label.length > 0 && (
  <div className="flex text-sm font-medium text-gray-700">
    <label
      htmlFor={htmlFor}
      className={classNames(
        'flex flex-inline gap-1',
        requiredSymbolPosition === 'before' && 'flex-row-reverse',
      )}
    >
      <div>{label}</div>
      {required && (
        <div className="text-red-500">*</div>
      )}
    </label>
  </div>
);

FormLabel.propTypes = {
  label: PropTypes.string,
  htmlFor: PropTypes.string,
  required: PropTypes.bool,
  requiredSymbolPosition: PropTypes.oneOf(['before', 'after']),
};

export default FormLabel;
