import { createAction, createAsyncAction } from '@/redux/root';

export const nameSpace = '@@/manage-users';

export const requestUsersAsync = createAsyncAction(`${nameSpace}/REQUEST_USERS`);

export const requestUserAsync = createAsyncAction(`${nameSpace}/REQUEST_USER`);

export const requestUserGroupsAsync = createAsyncAction(`${nameSpace}/REQUEST_USER_GROUPS`);

export const updateUserMdStatusAsync = createAsyncAction(`${nameSpace}/UPDATE_USER_MD_STATUS`);

export const clearUsersSearch = createAction(`${nameSpace}/CLEAR_REQUEST_USERS`);

export const restoreUserAsync = createAsyncAction(`${nameSpace}/RESTORE_USER`);

export const deleteUserAsync = createAsyncAction(`${nameSpace}/DELETE_USER`);

export const createUserAsync = createAsyncAction(`${nameSpace}/CREATE_USER`);

export const updateUserSalesChannelsAsync = createAsyncAction(`${nameSpace}/UPDATE_USER_SALES_CHANNELS`);

export const updateUserAsync = createAsyncAction(`${nameSpace}/UPDATE_USER`);

export const clearUser = createAction(`${nameSpace}/CLEAR_USER`);

