export const normalizeNumber = (value, originalValue) => {
  if (originalValue === undefined || originalValue === null) {
    return null;
  }

  if (typeof originalValue === 'string' && originalValue.trim() === '') {
    return null;
  }

  if (typeof value !== 'number' || isNaN(value)) {
    return null;
  }

  return value;
};
