// TODO: SPT:REMOVE

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { polygonsTableColumns } from '../../lib';
import { useFeatureFlag } from 'configcat-react';
import { dashboardConstants } from '@/lib/constants';
import {
  requestPolygonDispositionsAsync,
  selectPolygonDispositions,
  selectPolygonDispositionsCount,
} from '@/redux/polygons';
import {
  requestDeactivateTeamPolygonsAsync,
  requestDeleteTeamPolygonsAsync,
} from '@/redux/teams';
import PageableTable from '@/components/table/PageableTable';
import { Button, ConfirmationModal } from '@/components';
import {
  selectLoadingPolygonDispositions,
  selectLoadingTeampolygonsDeactivate,
  selectLoadingTeampolygonsDelete,
} from '@/redux/loading';
import { getSalesTeam } from '@/api/modules/sales-teams';

const TeamPolygonsTable = () => {
  const polygonRows = useSelector(selectPolygonDispositions);
  const totalCount = useSelector(selectPolygonDispositionsCount);
  const isLoadingDispositions = useSelector(selectLoadingPolygonDispositions);
  const isLoadingDeactivate = useSelector(selectLoadingTeampolygonsDeactivate);
  const isLoadingDelete = useSelector(selectLoadingTeampolygonsDelete);
  const { value: isTeamDeactivateDeletePolygonsEnabled } = useFeatureFlag(
    dashboardConstants.TEAM_DEACTIVATE_DELETE_POLYGONS_FEATURE_FLAG_NAME,
    false,
  );

  const [sortParams, setSortParams] = useState({ name: 'polygon_id', order: 'asc' });
  const [pageSize, setPageSize] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState({
    title: '',
    message: '',
    confirmLabel: '',
    confirmButtonColor: '',
    onAction: null,
    loading: false,
  });

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [teamName, setName] = useState('');

  useEffect(() => {
    getSalesTeam(id)().then(({ name }) => setName(name));
  }, [id]);

  const updateTable = useCallback(() => {
    const params = {
      id,
      type: 'team',
      order: sortParams.order,
      sort_by: sortParams.name,
      limit: pageSize,
      page: selectedPage,
    };

    dispatch(requestPolygonDispositionsAsync.request(params));
  }, [id, sortParams, pageSize, selectedPage, dispatch]);

  useEffect(() => {
    updateTable();
  }, [updateTable]);

  const handleOpenModal = (modal) => {
    setModalConfig(modal);
    setModalOpen(true);
  };

  const handleDeactivate = () => {
    dispatch(requestDeactivateTeamPolygonsAsync.request({
      team_id: id,
      callback: () => {
        setModalOpen(false);
        updateTable();
      },
    }));
  };

  const handleDelete = () => {
    dispatch(requestDeleteTeamPolygonsAsync.request({
      team_id: id,
      callback: () => {
        setModalOpen(false);
        updateTable();
      },
    }));
  };

  return (
    <>
      <PageableTable
        leftControl={(
          <>
            <Button onClick={() => navigate(`/sales-planning/team/${id}?origin=teamPolygons`)}>
              Add Polygon
            </Button>
            {polygonRows?.length > 0 && isTeamDeactivateDeletePolygonsEnabled && (
              <>
                <Button
                  onClick={() => handleOpenModal({
                    title: 'Deactivate Polygons',
                    message: `All polygons will be deactivated for Team "${teamName}". This cannot be undone, would you like to proceed?`,
                    confirmLabel: 'Deactivate',
                    onAction: handleDeactivate,
                  })}
                >
                  Deactivate Polygons
                </Button>
                <Button
                  onClick={() => handleOpenModal({
                    title: 'Delete Polygons',
                    message: `All polygons will be deleted for Team "${teamName}". This cannot be undone, would you like to proceed?`,
                    confirmLabel: 'Delete',
                    onAction: handleDelete,
                  })}
                >
                  Delete Polygons
                </Button>
              </>
            )}
          </>
        )}
        loading={isLoadingDispositions}
        columns={polygonsTableColumns}
        rows={polygonRows}
        pageSize={pageSize}
        setPageSize={setPageSize}
        sortParams={sortParams}
        onSortChange={setSortParams}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        totalCount={totalCount}
      />
      <ConfirmationModal
        isOpened={isModalOpen}
        onCancel={() => setModalOpen(false)}
        onAction={modalConfig.onAction}
        title={modalConfig.title}
        message={modalConfig.message}
        cancelLabel="Cancel"
        confirmLabel={modalConfig.confirmLabel}
        loading={isLoadingDeactivate || isLoadingDelete}
      />
    </>
  );
};

export default TeamPolygonsTable;
