import { dashboardConstants } from '@/lib';
import { salesAreasManagementConstants } from './constants';

const {
  NO_DATA_TO_DISPLAY,
} = dashboardConstants;

const {
  AREA_ID_LABEL,
  AREA_ID_VALUE,
  AREA_NAME_LABEL,
  AREA_NAME_VALUE,
  AREA_VISIBLE_LABEL,
  AREA_VISIBLE_VALUE,
} = salesAreasManagementConstants;

export const getHeadRows = () => (
  [
    {
      value: AREA_ID_LABEL,
      name: AREA_ID_VALUE,
      align: 'left',
      className: 'whitespace-nowrap',
    },
    {
      value: AREA_NAME_LABEL,
      name: AREA_NAME_VALUE,
      align: 'left',
      className: 'whitespace-nowrap',
    },
    {
      value: AREA_VISIBLE_LABEL,
      name: AREA_VISIBLE_VALUE,
      align: 'left',
      className: 'whitespace-nowrap',
    },
  ]
);

export const parseSalesAreasRows = (rows, onSelect) => (
  rows.length ? rows.map((row) => [
    {
      value: row.sales_area_id,
      align: 'left',
    },
    {
      value:
    <button
      type="button"
      onClick={() => {
        onSelect({ area: row });
      }}
      className="text-primary-300 font-medium text-left cursor-pointer focus:outline-none"
    >
      {row.name}
    </button>,
      align: 'left',
    },
    {
      value: row.visible
        ? 'Yes'
        : 'No',
      align: 'left',
    },
  ]) : [
    {
      value: NO_DATA_TO_DISPLAY,
      align: 'center',
      colSpan: 3,
      className: 'py-8',
    },
  ]
);
