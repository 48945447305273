import { createSelector } from 'reselect';

const areaSlice = (state) => state.area;

export const selectAreasList = (state) => state.area.areasList;

export const areasSelector = createSelector(
  areaSlice,
  (state) => state?.areas || [],
);

export const areasTotalSelector = createSelector(
  areaSlice,
  (state) => state?.areasTotal || 0,
);
