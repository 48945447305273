import Api from '@/api';
import { createRequestSaga } from '@/redux/helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import { addSalesAreaAsync, requestSalesAreasAsync, updateSalesAreaAsync } from '@/redux/sales-areas/actions';
import { addToastsAction } from '@/redux/toasts';
import { mapErrorToastsData } from '@/lib/api';
import { toastType } from '@/components';

function* requestSalesAreasSaga({ payload }) {
  const { ...data } = payload;

  try {
    const response = yield call(
      Api.getSalesAreas(),
      { ...data },
    );

    yield put(requestSalesAreasAsync.success(response));
  } catch (error) {
    yield put(addToastsAction(mapErrorToastsData(error)));

    throw error;
  }
}

function* addSalesAreaSaga({ payload }) {
  const { successCallback, data } = payload;

  try {
    const response = yield call(
      Api.addSalesArea(),
      { ...data },
    );

    yield put(addSalesAreaAsync.success(response));

    yield put(addToastsAction([{
      type: toastType.SUCCESS,
      message: 'Sales area successfully added',
      details: null,
    }]));

    if (successCallback) {
      yield call(successCallback, response);
    }
  } catch (error) {
    yield put(addToastsAction(mapErrorToastsData(error)));

    throw error;
  }
}

function* updateSalesAreaSaga({ payload }) {
  const { successCallback, id, data } = payload;

  try {
    const response = yield call(
      Api.updateSalesArea(id),
      { ...data },
    );

    yield put(updateSalesAreaAsync.success(response));

    yield put(addToastsAction([{
      type: toastType.SUCCESS,
      message: 'Sales area successfully updated',
      details: null,
    }]));

    if (successCallback) {
      yield call(successCallback, response);
    }
  } catch (error) {
    yield put(addToastsAction(mapErrorToastsData(error)));

    throw error;
  }
}

export function* salesAreasActionWatcher() {
  yield takeLatest(
    requestSalesAreasAsync.request,
    createRequestSaga(requestSalesAreasSaga, {
      keyNew: 'requestManageSalesAreas',
      errKey: 'requestManageSalesAreas',
      write: false,
    }),
  );
  yield takeLatest(
    addSalesAreaAsync.request,
    createRequestSaga(addSalesAreaSaga, {
      keyNew: 'addManageSalesArea',
      errKey: 'addManageSalesArea',
      write: false,
    }),
  );
  yield takeLatest(
    updateSalesAreaAsync.request,
    createRequestSaga(updateSalesAreaSaga, {
      keyNew: 'updateManageSalesArea',
      errKey: 'updateManageSalesArea',
      write: false,
    }),
  );
}
