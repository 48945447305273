import { useCallback, useEffect, useMemo, useRef } from 'react';
import { MapIcon, XIcon } from '@heroicons/react/outline';
import { CustomFormElement, SideDrawerWrapper } from '@/components';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { validationSchema } from '../lib/validationSchema';
import { salesAreasManagementConstants } from '../lib/constants';
import { addFsExcludeClass, mergeClassName } from '@/lib/utils';
import PropTypes from 'prop-types';
import { addSalesAreaAsync, updateSalesAreaAsync } from '@/redux/sales-areas';
import { useDispatch, useSelector } from 'react-redux';
import { timezonesSelector } from '@/redux/onboarding';
import { defaultSelectOption } from '@/lib/configs';

const {
  AREA_ID_VALUE,
  AREA_NAME_LABEL,
  AREA_NAME_VALUE,
  AREA_LAT_LABEL,
  AREA_LAT_VALUE,
  AREA_LONG_LABEL,
  AREA_LONG_VALUE,
  AREA_TIMEZONE_LABEL,
  AREA_TIMEZONE_VALUE,
  AREA_VISIBLE_LABEL,
  AREA_VISIBLE_VALUE,
  EDIT_AREA_HEADER,
  CREATE_AREA_HEADER,
} = salesAreasManagementConstants;

const defaults = {
  [AREA_ID_VALUE]: '',
  [AREA_NAME_VALUE]: '',
  [AREA_LAT_VALUE]: '',
  [AREA_LONG_VALUE]: '',
  [AREA_TIMEZONE_VALUE]: '',
  [AREA_VISIBLE_VALUE]: false,
};

const ManageSalesAreaSlider = ({
  isOpen,
  onClose,
  salesArea,
  getSalesAreas,
}) => {
  const dispatch = useDispatch();

  const timezones = useSelector(timezonesSelector);

  const methods = useForm({
    defaultValues: defaults,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema, { abortEarly: false }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    getValues,
  } = methods;

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmitForm = useCallback(() => {
    const values = getValues();

    if (salesArea){
      dispatch(updateSalesAreaAsync.request({
        id: salesArea[AREA_ID_VALUE],
        data: values,
        successCallback: () => {
          getSalesAreas();
          closeModal();
        },
      }));
    } else {
      dispatch(addSalesAreaAsync.request({
        data: values,
        successCallback: () => {
          getSalesAreas();
          closeModal();
        },
      }));
    }
  }, [getValues, salesArea, dispatch, getSalesAreas, closeModal]);

  useEffect(() => {
    const formValues = getValues();
    const hasDifferentArea = formValues?.[AREA_ID_VALUE] !== salesArea?.[AREA_ID_VALUE];

    if (hasDifferentArea) {
      const filteredSalesArea = salesArea
        ? Object.keys(defaults).reduce((acc, key) => {
          if (key in salesArea) {
            acc[key] = salesArea[key];
          }

          return acc;
        }, {})
        : defaults;

      reset(filteredSalesArea);
    }
  }, [salesArea, isOpen, reset, getValues]);

  const closeButtonRef = useRef(null);

  const timezoneSelectOptions = useMemo(
    () => [
      ...defaultSelectOption,
      ...timezones.map((timezone) => ({ name: timezone.name, value: timezone.timezone_id })),
    ],
    [timezones],
  );

  return (
    <SideDrawerWrapper isOpened={isOpen} onCloseModal={closeModal} large>
      <div className="px-4 py-4 border-b border-gray-200 sm:px-6">
        <div className="flex items-start justify-between">
          <div className="flex items-center space-x-4 lg:space-x-6">
            <div className="flex items-center justify-center w-10 h-10 rounded-full bg-aptivegreen">
              <MapIcon
                color="white"
                className="w-6 h-6"
                aria-hidden="true"
              />
            </div>
            <div className="space-y-1 text-lg font-bold leading-6 ">
              <h3>{salesArea ? `${EDIT_AREA_HEADER} #${salesArea[AREA_ID_VALUE]} (${salesArea[AREA_NAME_VALUE]})` : CREATE_AREA_HEADER}</h3>
            </div>
          </div>
          <div className="flex items-center ml-3 h-7">
            <button
              type="button"
              className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:ring-2 focus:ring-aptivegreen"
              onClick={closeModal}
              ref={closeButtonRef}
            >
              <span className="sr-only">Close panel</span>
              <XIcon className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <div className="flex-1 px-6 py-5 bg-gray-100 border-b border-gray-200 sm:px-6">
        <FormProvider {...methods}>
          <form noValidate className="pt-4" onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="pt-4 px-3 pb-6 bg-white border border-gray-200 rounded-md shadow-sm">
              <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4">
                <CustomFormElement
                  label={AREA_NAME_LABEL}
                  name={AREA_NAME_VALUE}
                  id={AREA_NAME_VALUE}
                  required
                  colSpan={12}
                  type="text"
                  error={errors?.[AREA_NAME_VALUE]}
                  register={register}
                  className={addFsExcludeClass()}
                />

                <CustomFormElement
                  label={AREA_LAT_LABEL}
                  name={AREA_LAT_VALUE}
                  id={AREA_LAT_VALUE}
                  required
                  colSpan={12}
                  type="text"
                  error={errors?.[AREA_LAT_VALUE]}
                  register={register}
                  className={addFsExcludeClass()}
                />

                <CustomFormElement
                  label={AREA_LONG_LABEL}
                  name={AREA_LONG_VALUE}
                  id={AREA_LONG_VALUE}
                  required
                  colSpan={12}
                  type="text"
                  error={errors?.[AREA_LONG_VALUE]}
                  register={register}
                  className={addFsExcludeClass()}
                />

                <CustomFormElement
                  label={AREA_TIMEZONE_LABEL}
                  name={AREA_TIMEZONE_VALUE}
                  id={AREA_TIMEZONE_VALUE}
                  required
                  colSpan={12}
                  type="select"
                  error={errors?.[AREA_TIMEZONE_VALUE]}
                  register={register}
                  className={addFsExcludeClass()}
                  selectOptions={timezoneSelectOptions}
                />

                <CustomFormElement
                  label={AREA_VISIBLE_LABEL}
                  name={AREA_VISIBLE_VALUE}
                  id={AREA_VISIBLE_VALUE}
                  colSpan={12}
                  type="checkbox"
                  error={errors?.[AREA_VISIBLE_VALUE]}
                  register={register}
                  formElementWrapperClassName="w-full flex flex-row-reverse items-center gap-2 justify-end"
                  elementWrapperClassName="mt-0"
                  className={addFsExcludeClass('w-4 h-4 text-indigo-600 border-gray-300 rounded')}
                />
              </div>
            </div>
            <div className="flex flex-row-reverse px-6 py-6 mt-6 border-t border-gray-200">
              <button
                type="submit"
                className={mergeClassName(
                  'inline-flex justify-center w-full px-4 py-2 text-base text-gray-300 font-medium bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-aptivegreen-500 sm:ml-3 sm:w-40 sm:text-sm',
                  { 'text-white bg-aptivegreen hover:bg-aptivegreen border-transparent': isValid && isDirty },
                )}
                disabled={!isValid || !isDirty}
              >
                Save
              </button>
              <button
                type="button"
                className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-aptivegreen sm:mt-0 sm:ml-3 sm:w-20 sm:text-sm"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </SideDrawerWrapper>
  );
};

ManageSalesAreaSlider.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  salesArea: PropTypes.object,
  getSalesAreas: PropTypes.func,
};

export default ManageSalesAreaSlider;
