import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { CustomFormElement, PhoneNumber } from '@/components/common';
import { useDispatch, useSelector } from 'react-redux';
import { isAdminSelector } from '@/redux/auth';
import { addFsExcludeClass } from '@/lib/utils';
import { dashboardConstants, onboardingConstants } from '@/lib';
import { useEffect, useState } from 'react';
import { getAddressesAsync } from '@/redux/addresses';
import AddressInformation from './AddressInformation';

const {
  FIRST_NAME_LABEL,
  LAST_NAME_LABEL,
  EMAIL_LABEL,
  PHONE_LABEL,
  EMAIL_NAME,
  PHONE_NAME,
} = dashboardConstants;

const {
  FIRST_NAME,
  LAST_NAME,
  WORKDAY_ID,
  WORKDAY_ID_LABEL,
  PEST_ROUTES_ID,
  PEST_ROUTES_ID_LABEL,
  MD_HOLDER_TEXT,
  MD_HOLDER_LABEL,
  MD_HOLDER,
  PASSWORD_NAME,
  REPEAT_PASSWORD_NAME,
  PASSWORD_LABEL,
  REPEAT_PASSWORD_LABEL,
} = onboardingConstants;

const BasicInfo = ({
  user,
  onChangeHandler,
  onBlurTrimSpace,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const dispatch = useDispatch();
  const isAdmin = useSelector(isAdminSelector);
  const [tooltip, setTooltip] = useState('');

  useEffect(() => {
    dispatch(getAddressesAsync.request());
    setTooltip(MD_HOLDER_TEXT);
  }, [dispatch]);

  const canEditField = (fieldName) => {
    return isAdmin && fieldName;
  };

  return (
    <>
      <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
        <CustomFormElement
          label={FIRST_NAME_LABEL}
          name={FIRST_NAME}
          id={FIRST_NAME}
          type="text"
          required
          colSpan={3}
          onChange={onChangeHandler}
          error={errors?.firstName}
          register={register}
          className={addFsExcludeClass()}
          disabled={!isAdmin}
        />
        <CustomFormElement
          label={LAST_NAME_LABEL}
          name={LAST_NAME}
          id={LAST_NAME}
          onChange={onChangeHandler}
          required
          colSpan={3}
          type="text"
          error={errors?.lastName}
          register={register}
          className={addFsExcludeClass()}
          disabled={!isAdmin}
        />
        <PhoneNumber
          label={PHONE_LABEL}
          id={PHONE_NAME}
          name={PHONE_NAME}
          onChange={onChangeHandler}
          required
          colSpan={3}
          type="text"
          error={errors?.phone}
          register={register}
          className={addFsExcludeClass()}
          disabled={!isAdmin}
        />
        <CustomFormElement
          name={EMAIL_NAME}
          id={EMAIL_NAME}
          label={EMAIL_LABEL}
          onChange={onChangeHandler}
          required
          colSpan={3}
          type="text"
          error={errors?.email}
          register={register}
          className={addFsExcludeClass()}
          disabled={!isAdmin}
        />
      </div>
      <hr className="my-10 border-gray-300" />
      <AddressInformation
        canEditField={canEditField}
        onChangeHandler={onChangeHandler}
        onBlurTrimSpace={onBlurTrimSpace}
      />
      <hr className="my-10  border-gray-300" />
      <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
        {isAdmin && (
          <CustomFormElement
            id={WORKDAY_ID}
            name={WORKDAY_ID}
            label={WORKDAY_ID_LABEL}
            type="text"
            onChange={onChangeHandler}
            disabled={!isAdmin}
            register={register}
            error={errors?.[WORKDAY_ID]}
          />
        )}
        <CustomFormElement
          id={PEST_ROUTES_ID}
          name={PEST_ROUTES_ID}
          label={PEST_ROUTES_ID_LABEL}
          type="text"
          onChange={onChangeHandler}
          register={register}
          error={errors?.[PEST_ROUTES_ID]}
          disabled={!isAdmin}
          autoComplete="off"
        />
        <CustomFormElement
          colSpan={3}
          label={MD_HOLDER_LABEL}
          type="checkbox"
          className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-aptivegreen"
          id={MD_HOLDER}
          tooltipMessage={tooltip}
          register={register}
          name={MD_HOLDER}
          error={errors?.[MD_HOLDER]}
          onChange={onChangeHandler}
          disabled={!isAdmin}
          labelPosition="after"
          isInlineWithLabel={true}
          formElementWrapperClassName="flex items-center space-x-2"
        />
      </div>
      {isAdmin && (
        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 mt-6">
          <CustomFormElement
            colSpan={3}
            id={PASSWORD_NAME}
            name={PASSWORD_NAME}
            label={PASSWORD_LABEL}
            type="password"
            register={register}
            error={errors?.password}
            onChange={onChangeHandler}
            disabled={!isAdmin}
            autoComplete="new-password"
            required={!user}
          />
          <CustomFormElement
            colSpan={3}
            id={REPEAT_PASSWORD_NAME}
            name={REPEAT_PASSWORD_NAME}
            label={REPEAT_PASSWORD_LABEL}
            type="password"
            register={register}
            error={errors?.repeatPassword}
            onChange={onChangeHandler}
            disabled={!isAdmin}
            autoComplete="new-password"
            required={!user}
          />
        </div>
      )}
    </>
  );
};

BasicInfo.propTypes = {
  user: PropTypes.object,
  onChangeHandler: PropTypes.func.isRequired,
  onBlurTrimSpace: PropTypes.func.isRequired,
};

export default BasicInfo;
