import { dashboardConstants, onboardingConstants } from '@/lib/constants';
import { ledgerConstants } from '@/modules/Housing/lib';

const {
  FIRST_NAME,
  LAST_NAME,
  ACTIVE_NAME,
  PEST_ROUTES_ID,
  WORKDAY_ID,
  GROUP_NAME,
  UNSUBSCRIBED_NAME,
  APP_ACCESS_NAME,
  VISIBLE_NAME,
  ADDRESS_CITY,
  ADDRESS_STATE,
  ADDRESS_ZIP,
  ADDRESS_ONE,
  ADDRESS_COUNTRY,
  AREA_MANAGEMENT_NAME,
  MD_HOLDER,
  PASSWORD_NAME,
  CURRENT_ADDRESS_ONE,
  CURRENT_ADDRESS_CITY,
  CURRENT_ADDRESS_STATE,
  CURRENT_ADDRESS_ZIP,
  CURRENT_ADDRESS_COUNTRY,
  IS_CURRENT_ADDRESS_DIFFER,
  REPEAT_PASSWORD_NAME,
} = onboardingConstants;

const {
  EMAIL_NAME,
  PHONE_NAME,
} = dashboardConstants;

const {
  TEAM_NAME,
  DEALER_NAME,
} =ledgerConstants;

export const userFormDataAdapter = (data) => {
  const newData = {};
  const keys = [
    { 'user_email': EMAIL_NAME },
    { 'user_active': ACTIVE_NAME },
    { 'user_knock_active': VISIBLE_NAME },
    { 'user_unsubscribe': UNSUBSCRIBED_NAME },
    { 'user_app_access': APP_ACCESS_NAME },
    { 'user_group_id': GROUP_NAME },
    { 'user_team_id': TEAM_NAME },
    { 'user_pest_routes_rep_id': PEST_ROUTES_ID },
    { 'user_workday_id': WORKDAY_ID },
    { 'user_dealer_id': DEALER_NAME },
    { 'area_management': AREA_MANAGEMENT_NAME },
    { 'user_md_holder': MD_HOLDER },
    { 'contact_first_name': FIRST_NAME },
    { 'contact_last_name': LAST_NAME },
    { 'contact_mobile': PHONE_NAME },
    { 'contact_address': CURRENT_ADDRESS_ONE },
    { 'contact_city': CURRENT_ADDRESS_CITY },
    { 'contact_state': CURRENT_ADDRESS_STATE },
    { 'contact_zip': CURRENT_ADDRESS_ZIP },
    { 'contact_country': CURRENT_ADDRESS_COUNTRY },
    { 'contact_is_different_address': IS_CURRENT_ADDRESS_DIFFER },
    { 'contact_permanent_address': ADDRESS_ONE },
    { 'contact_permanent_city': ADDRESS_CITY },
    { 'contact_permanent_state': ADDRESS_STATE },
    { 'contact_permanent_zip': ADDRESS_ZIP },
    { 'contact_permanent_country': ADDRESS_COUNTRY },
  ];

  keys.forEach((item) => {
    const [key, value] = Object.entries(item)[0];

    switch (true) {
      case value === PEST_ROUTES_ID:
        newData[key] = data[value] || 0;
        break;
      case data[value]?.length === 0 || data[value] === null:
        break;
      case value === PHONE_NAME:
        newData[key] = data[value]?.replace(/[^0-9]/g, '') || '';
        break;
      case value === ADDRESS_ZIP:
        newData[key] = data[value]?.replace(/[^0-9]/g, '') || '';
        break;
      default:
        newData[key] = data[value];
        break;
    }
  });

  if (data[PASSWORD_NAME]) {
    newData['user_password'] = data[PASSWORD_NAME];
    newData['user_password_confirmation'] = data[REPEAT_PASSWORD_NAME];
  }

  return newData;
};
