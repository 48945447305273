import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSalesTeamAsync,
  salesAreasSelector,
  updateSalesTeamAsync,
} from '@/redux/sales-teams';
import { ConfirmationModal, CustomFormElement, ErrorBox, Loader, SideDrawerWrapper } from '@/components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { removeAllErrorsAction, saveSalesTeamErrorsSelector } from '@/redux/errors';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { validationSchema } from '@/modules/dashboard/forms/SalesTeamForm/SalesTeamValidationSchema';
import { XIcon } from '@heroicons/react/outline';
import { v4 as uuidv4 } from 'uuid';
import {
  salesTeamsManagementColumnNames,
  salesTeamsManagementColumnLabels,
  visibleRadioConfig,
  deletedRadioConfig,
} from '../lib/constants/salesTeamsManagement';
import { defaultSelectOption } from '@/modules/Housing/lib';
import { saveSalesTeamsLoadingSelector } from '@/redux/loading';

const {
  NAME_COLUMN_NAME,
  SALES_AREA_ID_COLUMN_NAME,
  SALES_AREA_NAME_COLUMN_NAME,
  VISIBLE_COLUMN_NAME,
  DELETED_COLUMN_NAME,
} = salesTeamsManagementColumnNames;

const SalesTeamManagementForm = ({ isOpen, onTeamFormClose, editingTeam, reloadSalesTeams }) => {
  const dispatch = useDispatch();

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const salesAreas = useSelector(salesAreasSelector);
  const formErrors = useSelector(saveSalesTeamErrorsSelector);
  const salesTeamIsSaving = useSelector(saveSalesTeamsLoadingSelector);

  const defaultInfo = useMemo(() => {
    return {
      [NAME_COLUMN_NAME]: editingTeam ? editingTeam.name : '',
      [SALES_AREA_ID_COLUMN_NAME]: editingTeam ? editingTeam.sales_area_id : '',
      [VISIBLE_COLUMN_NAME]: editingTeam ? (+editingTeam.visible).toString() : '1',
      [DELETED_COLUMN_NAME]: editingTeam ? (+editingTeam.deleted).toString() : '0',
    };
  }, [editingTeam]);

  const salesAreasOptions = useMemo(() => ([
    ...defaultSelectOption,
    ...salesAreas.map(({ name, sales_area_id: value }) => ({
      name,
      value,
    })),
  ]), [salesAreas]);

  const methods = useForm({
    defaultValues: defaultInfo,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = methods;

  const closeButtonRef = useRef(null);

  const closeForm = useCallback(() => {
    // Clear out the form for next time
    dispatch(removeAllErrorsAction());
    reset(defaultInfo);
    onTeamFormClose();
  }, [defaultInfo, dispatch, onTeamFormClose, reset]);

  const handleSubmitForm = useCallback(() => {
    const editingTeamId = editingTeam ? editingTeam.sales_team_id : null;

    const data = {
      salesTeamId: editingTeamId,
      data: getValues(),
      successCallback: () => {
        closeForm();
        reloadSalesTeams();
      },
    };

    dispatch(editingTeamId ? updateSalesTeamAsync.request(data) : createSalesTeamAsync.request(data));
  }, [editingTeam, closeForm, dispatch, getValues, reloadSalesTeams]);

  const onChangeField = (event) => {
    const { name, value } = event.target || event;

    setValue(name, value, { shouldValidate: true });
  };

  const onSubmitForm = () => {
    const isVisible = getValues(VISIBLE_COLUMN_NAME);
    const isDeleted = getValues(DELETED_COLUMN_NAME);

    if (isVisible !== '1' || isDeleted === '1') {
      setConfirmationOpen(true);
    } else {
      handleSubmitForm();
    }
  };

  const onConfirmationCancel = () => {
    setConfirmationOpen(false);
  };

  const onConfirmationConfirm = () => {
    handleSubmit(handleSubmitForm)();

    setConfirmationOpen(false);
  };

  const errorRows = useMemo(() => {
    return formErrors?.length ? formErrors.map((error) => (
      <ErrorBox message={error.message} key={uuidv4()} />
    )) : [];
  }, [formErrors]);

  useEffect(() => {
    dispatch(removeAllErrorsAction());
    reset(defaultInfo);
  }, [isOpen, defaultInfo, dispatch, reset]);

  return (
    <SideDrawerWrapper isOpened={isOpen} onCloseModal={closeForm} large>
      <div className="px-4 py-4 border-b border-gray-200 sm:px-6">
        <div className="flex items-start justify-between">
          <div className="flex items-center space-x-4 lg:space-x-6">
            <div className="space-y-1 text-lg font-bold leading-6 ">
              <h3>{`${editingTeam ? 'Edit' : 'Add'} Team ${editingTeam ? `#${editingTeam.sales_team_id} (${editingTeam.name})` : ''}`}</h3>
            </div>
          </div>
          <div className="flex items-center ml-3 h-7">
            <button
              type="button"
              className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:ring-2 focus:ring-aptivegreen"
              onClick={closeForm}
              ref={closeButtonRef}
            >
              <span className="sr-only">Close panel</span>
              <XIcon className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <div className="flex-1 px-6 py-5 bg-gray-100 border-b border-gray-200 sm:px-6">
        {errorRows?.length > 0 && errorRows}
        <FormProvider {...methods}>
          <form noValidate className="pt-4" onSubmit={handleSubmit(onSubmitForm)}>
            <div className="grid gap-y-6">
              <CustomFormElement
                label={salesTeamsManagementColumnLabels[NAME_COLUMN_NAME]}
                name={NAME_COLUMN_NAME}
                id={NAME_COLUMN_NAME}
                type="text"
                required
                onChange={onChangeField}
                error={errors?.[NAME_COLUMN_NAME]}
                register={register}
              />
              <CustomFormElement
                label={salesTeamsManagementColumnLabels[SALES_AREA_NAME_COLUMN_NAME]}
                name={SALES_AREA_ID_COLUMN_NAME}
                id={SALES_AREA_ID_COLUMN_NAME}
                type="select"
                selectOptions={salesAreasOptions}
                required
                onChange={onChangeField}
                error={errors ? errors[SALES_AREA_ID_COLUMN_NAME] : null}
                register={register}
              />
              <CustomFormElement
                label={salesTeamsManagementColumnLabels[VISIBLE_COLUMN_NAME]}
                name={VISIBLE_COLUMN_NAME}
                id={VISIBLE_COLUMN_NAME}
                type="radio"
                required
                onChange={onChangeField}
                orientation="horizontal"
                error={errors ? errors[VISIBLE_COLUMN_NAME] : null}
                register={register}
                radioOptions={visibleRadioConfig}
              />
              <CustomFormElement
                label={salesTeamsManagementColumnLabels[DELETED_COLUMN_NAME]}
                name={DELETED_COLUMN_NAME}
                id={DELETED_COLUMN_NAME}
                type="radio"
                required
                onChange={onChangeField}
                orientation="horizontal"
                error={errors ? errors[DELETED_COLUMN_NAME] : null}
                register={register}
                radioOptions={deletedRadioConfig}
              />
            </div>
            <div className="flex flex-row-reverse px-6 py-6 mt-6 border-t border-gray-200">
              <button
                type="submit"
                disabled={salesTeamIsSaving}
                className="items-center inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-aptivegreen hover:bg-aptivegreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-aptivegreen-500 sm:ml-3 sm:w-40 sm:text-sm"
              >
                {salesTeamIsSaving ? <Loader color="white" /> : 'Save'}
              </button>
              <button
                type="button"
                className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-aptivegreen sm:mt-0 sm:ml-3 sm:w-20 sm:text-sm"
                onClick={closeForm}
              >
                Cancel
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      <ConfirmationModal
        isOpened={confirmationOpen}
        modalWidth="max-w-[592px] w-full"
        onCancel={onConfirmationCancel}
        onAction={onConfirmationConfirm}
        title="Would you like to hide the team?"
        message="By deleting or making a team invisible, you will no longer see it in the teams list."
        cancelLabel="Cancel"
        confirmLabel="Confirm"
      />
    </SideDrawerWrapper>
  );
};

SalesTeamManagementForm.propTypes = {
  isOpen: PropTypes.bool,
  onTeamFormClose: PropTypes.func,
  editingTeam: PropTypes.shape({
    sales_team_id: PropTypes.number,
    sales_area_id: PropTypes.number,
    name: PropTypes.string,
    visible: PropTypes.bool,
    deleted: PropTypes.bool,
  }),
  reloadSalesTeams: PropTypes.func,
};

export default SalesTeamManagementForm;
