import { useDispatch, useSelector } from 'react-redux';
import { SalesAreasManagementTable } from '../components';
import { useEffect } from 'react';
import { requestTimezonesAsync, timezonesSelector } from '@/redux/onboarding';

const SalesAreasManagement = () => {
  const dispatch = useDispatch();

  const timezones = useSelector(timezonesSelector);

  useEffect(() => {
    if (timezones.length === 0){
      dispatch(requestTimezonesAsync.request());
    }
  }, [dispatch, timezones.length]);

  return <SalesAreasManagementTable />;
};

export default SalesAreasManagement;
