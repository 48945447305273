import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { UploadIcon, SearchIcon } from '@heroicons/react/outline';
import { Loader } from '@/components';
import { mergeClassName } from '@/lib/utils';

const FileInput = ({
  inputFileName,
  inputFileId,
  name,
  id,
  fileType,
  register,
  onChange,
  disabled,
  fileTypes,
  fileFormats,
  isFileLoading,
  isDragging,
  setIsDragging,
  cursorClass,
  previewSrc,
  withPreview,
  localFile,
  fileName,
  isPreviewLoading,
  hasError,
  baseClasses,
}) => {
  const handleDragEnter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  }, [setIsDragging]);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  }, [setIsDragging]);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  }, [setIsDragging]);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const files = event.dataTransfer.files;

    if (files.length > 0) {
      onChange({
        target: {
          name: inputFileName,
          type: 'file',
          files: files,
        },
      });
    }
  }, [onChange, inputFileName, setIsDragging]);

  return (
    <>
      <input
        {...register(inputFileName)}
        name={inputFileName}
        id={inputFileName}
        type="file"
        className="hidden"
        accept={fileType ? fileTypes : ''}
        onChange={onChange}
        disabled={disabled}
      />
      <input
        {...register(name)}
        name={name}
        id={id}
        value={fileName || ''}
        type="hidden"
      />
      {!fileName?.length && (
        <div
          {...(!isFileLoading && {
            onDragEnter: handleDragEnter,
            onDragLeave: handleDragLeave,
            onDragOver: handleDragOver,
            onDrop: handleDrop,
          })}
          className={mergeClassName(
            'flex justify-around items-center border-2 p-6 text-center rounded-lg bg-gray-50',
            isDragging ? 'border-blue-500 bg-gray-300' : 'border-gray-300',
            cursorClass,
            hasError ? baseClasses?.['errorClasses'] : 'border-dashed',
          )}
        >
          {localFile && previewSrc && withPreview && !isPreviewLoading ? (
            <img src={previewSrc} alt="Preview" className="max-w-[150px] pb-2" />
          ) : null}
          {isPreviewLoading && <Loader className="p-4 justify-start" color="#2F73DA" />}
          <label htmlFor={inputFileId} className={`block ${isFileLoading ? 'pointer-events-none' : cursorClass}`}>
            <div className="grid gap-2">
              <UploadIcon className="w-5 h-5 m-auto text-gray-500" />
              <div className="flex m-auto">
                <span className="font-semibold text-sm text-gray-500">Click to upload</span>&nbsp;
                <span className="font-normal text-sm text-gray-500">or drag and drop</span>
              </div>
              <p className="text-xs font-semibold text-gray-500">Upload a file {fileFormats}</p>
              <div className="flex gap-[3px] items-center px-4 mt-2 bg-aptiveblue text-white rounded-lg w-[110px] m-auto">
                <SearchIcon className="w-8 h-8 search" />
                <span className="text-xs whitespace-nowrap">Browse File</span>
              </div>
            </div>
          </label>
        </div>
      )}
    </>
  );
};

FileInput.propTypes = {
  inputFileName: PropTypes.string.isRequired,
  inputFileId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fileTypes: PropTypes.string,
  fileFormats: PropTypes.string,
  isFileLoading: PropTypes.bool,
  isDragging: PropTypes.bool,
  setIsDragging: PropTypes.func.isRequired,
  cursorClass: PropTypes.string,
  previewSrc: PropTypes.string,
  withPreview: PropTypes.bool,
  localFile: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  fileName: PropTypes.string,
  isPreviewLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  baseClasses: PropTypes.shape({
    errorClasses: PropTypes.string,
    standardClasses: PropTypes.string,
  })
};

export default FileInput;
