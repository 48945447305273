import { Table } from '@/components';
import { dashboardConstants } from '@/lib';
import { usersLoadingSelector } from '@/redux/loading';

import {
  requestUsersAsync,
  requestUserGroupsAsync,
  updateUserMdStatusAsync,
  manageUsersSelector,
  manageUserGroupsSelector,
  manageUsersTotalSelector,
} from '@/redux/users';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHeadRows, parseUserRows } from './utils';
import { SearchBar, Button } from '@/components/common';
import dashboard from '@/lib/constants/dashboard';
import {
  AddFilterButton,
  FilterDropdownButton,
} from '@/modules/Housing/components/common';
import UserForm from './UserForm';
import { usersManagementConstants } from '../../lib';

const initialPageSize = 10;
const initialPage = 1;
const initialSearch = '';

const yesNoOptions = [{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }];
const activeInactiveOptions = [{ name: 'Active', value: 1 }, { name: 'Inactive', value: 0 }];

const UsersManagementTable = () => {
  const dispatch = useDispatch();
  const users = useSelector(manageUsersSelector);
  const usersTotal = useSelector(manageUsersTotalSelector);
  const usersLoading = useSelector(usersLoadingSelector);
  const groups = useSelector(manageUserGroupsSelector);

  const [groupOptions, setGroupOptions] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filterValueOptions, setFilterValueOptions] = useState([]);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [selectedPage, setSelectedPage] = useState(initialPage);
  const [searchText, setSearchText] = useState(initialSearch);
  const [salesManagementUserInfo, setSalesManagementUserInfo] = useState(null);
  const [isUserFormOpen, setUserFormOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const onPageChange = useCallback(({ selected }) => {
    setSelectedPage(selected);
  }, []);

  const onSearchClick = useCallback(({ searchText }) => {
    setSearchText(searchText);
    setSelectedPage(initialPage);
  }, []);

  const onCheckUser = (user) => {
    dispatch(updateUserMdStatusAsync.request({ userId: user.id }));
    getUsers({ search: searchText, selectedPage, pageSize });
  };

  const getUsers = useCallback(
    ({ search, selectedPage, pageSize }) => {
      const params = {
        page: {
          number: selectedPage,
          size: pageSize,
        },
        filter: {},
      };

      if (search) {
        params.filter.search = search;
      }

      if (filters.length) {
        filters.map((filter) => {
          if (filter.type.value === 'user_group') {
            params.filter[filter.type.value] = filter.value.map((thisValue) => {
              return thisValue.value;
            });
          } else {
            params.filter[filter.type.value] = filter.value[0].value;
          }
        });
      }

      dispatch(requestUsersAsync.request(params));
    },
    [filters, dispatch],
  );

  const getUserGroups = useCallback(() => {
    dispatch(requestUserGroupsAsync.request());
  }, [dispatch]);

  useEffect(() => {
    const options = [];
    groups.map((group) => {
      options.push({
        name: group.name,
        value: group.user_group_id,
      });
    });
    setGroupOptions(options);
  }, [groups]);

  useEffect(() => {
    getUserGroups();
  }, [getUserGroups]);

  useEffect(() => {
    getUsers({ search: searchText, selectedPage, pageSize });
  }, [getUsers, filters, pageSize, searchText, selectedPage]);

  const onActionCompleted = () => {
    getUsers({ search: searchText, selectedPage, pageSize });
  };

  const onAddNewClick = () => {
    setUserFormOpen(true);
  };

  const handleCloseUserForm = (withReload = false) => {
    setSelectedUser(null);
    setUserFormOpen(false);
    if (withReload) {
      onActionCompleted();
    }
  };

  const onEditUserClick = (user_id, user_name, channel_ids) => {
    setSalesManagementUserInfo({ id: user_id, name: user_name, channel_ids: channel_ids });
    setSelectedUser({ user_id, user_name, channel_ids });
    setUserFormOpen(true);
  };

  const userRows = parseUserRows(users, onCheckUser, onActionCompleted, onEditUserClick, usersLoading);

  const filterTypeOptions = [
    {
      onClick: () => setFilterValueOptions(yesNoOptions),
      label: 'App Access',
      value: 'app_access',
      type: 'dropdownRadio',
    },
    {
      onClick: () => setFilterValueOptions(yesNoOptions),
      label: 'Archived',
      value: 'archived',
      type: 'dropdownRadio',
    },
    {
      onClick: () => setFilterValueOptions(yesNoOptions),
      label: 'MD Holder',
      value: 'md_holder',
      type: 'dropdownRadio',
    },
    {
      onClick: () => setFilterValueOptions(activeInactiveOptions),
      label: 'Status',
      value: 'status',
      type: 'dropdownRadio',
    },
    {
      onClick: () => setFilterValueOptions(yesNoOptions),
      label: 'Unsubscribed',
      value: 'unsubscribed',
      type: 'dropdownRadio',
    },
    {
      onClick: () => setFilterValueOptions(groupOptions),
      label: 'User Group',
      value: 'user_group',
      type: 'dropdown',
    },
    {
      onClick: () => setFilterValueOptions(yesNoOptions),
      label: 'Visible',
      value: 'knock_active',
      type: 'dropdown',
    },
  ];

  return (
    <>
      {(
        <div className="p-2 gap-2 border-b bg-white self-stretch flex items-center justify-between">
          <SearchBar
            inputName={dashboard.USER_MANAGEMENT_SEARCH_NAME}
            searchText={searchText}
            onSearchClick={onSearchClick}
            placeholder={dashboardConstants.SEARCH_REPS}
            disabled={Boolean(usersLoading)}
            // `inputClassName` includes all default classes except for `lg:w-64`. This is a bad idea, but it is safer than changing the `SearchBar` component.
            inputClassName="w-52 p-2.5 text-sm text-gray-500 bg-white grow border border-gray-200 border-r-transparent rounded-l-md focus:z-10 focus:border-aptivegreen focus:border-r-aptivegreen focus:outline-none focus:ring-1 focus:ring-aptivegreen"
          />
          <div className="mr-4 flex items-center gap-6">
            {filters.map((filter, index) => {
              let options, type;
              switch (filter.type.value) {
                case 'status':
                  options = activeInactiveOptions;
                  type = 'dropdownRadio';
                  break;
                case 'unsubscribed':
                  options = yesNoOptions;
                  type = 'dropdownRadio';
                  break;
                case 'app_access':
                  options = yesNoOptions;
                  type = 'dropdownRadio';
                  break;
                case 'knock_active':
                  options = yesNoOptions;
                  type = 'dropdownRadio';
                  break;
                case 'user_group':
                  options = groupOptions;
                  type = 'dropdownRadio';
                  break;
                case 'md_holder':
                  options = yesNoOptions;
                  type = 'dropdownRadio';
                  break;
                case 'archived':
                  options = yesNoOptions;
                  type = 'dropdownRadio';
                  break;
                default:
                  break;
              }

              return (
                <FilterDropdownButton
                  buttonClassName="border border-gray-200 rounded-lg px-4 py-2 gap-1 inline-flex items-center"
                  iconClassName="w-4 h-4 text-gray-600"
                  labelClassName="text-gray-600 text-based font-normal sm:text-xs leading-none"
                  filterValueOptions={options}
                  filters={filters}
                  setFilters={setFilters}
                  key={index}
                  index={index}
                  type={type}
                />
              );
            })}

            <AddFilterButton
              labelClassName="text-gray-600 text-right font-normal sm:text-xs leading-none border-none shadow-none"
              buttonClassName="items-center"
              iconClassName="w-0 h-0"
              filterTypeOptions={filterTypeOptions}
              filterValueOptions={filterValueOptions}
              setFilters={setFilters}
              filters={filters}
              label={'+ Add Filters'}
            />
            <div className="flex items-center gap-4">
              <Button
                color={'default'}
                onClick={() => setFilters([])}
                className="text-gray-600 text-right font-normal sm:text-xs leading-none border-none shadow-none"
              >
                Clear Filters
              </Button>
              <Button
                color="green"
                onClick={onAddNewClick}
                className="px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-aptivegreen"
              >
                {usersManagementConstants.CREATE_USER}
              </Button>
            </div>
            <UserForm
              isOpen={isUserFormOpen}
              onClose={handleCloseUserForm}
              groups={groups}
              selectedUser={selectedUser}
              salesManagementUserInfo={selectedUser ? salesManagementUserInfo : undefined}
            />
          </div>
        </div>
      )}

      <div className="px-8">
        <div className="mt-10">
          <Table
            loading={usersLoading}
            thead={{
              rows: getHeadRows(),
            }}
            tbody={{
              rows: userRows,
            }}
            paginator={{
              rowCount: usersTotal,
              pageSize: pageSize,
              setPageSize: setPageSize,
              onPageChange: onPageChange,
              selectedPage: selectedPage,
              initialPage: initialPage,
            }}
          />
        </div>
      </div>
    </>
  );
};

UsersManagementTable.propTypes = {};

export default UsersManagementTable;
