import classNames from 'classnames';

export const generateBaseClasses = (type = 'text', baseWrapperClassName = '') => {
  const placeholderTextColor = {
    text: '300',
    date: '900',
  };

  const interactionType = ['radio', 'checkbox', 'fileWithPreview'].includes(type) ? 'hover' : 'focus';

  const standardClasses = classNames(
    `${interactionType}:ring-aptivegreen`,
    `${interactionType}:border-aptivegreen`,
    'border-gray-300',
    'focus:ring-aptivegreen',
    `placeholder-gray-${placeholderTextColor[type] ?? '300'}`,
    baseWrapperClassName,
  );

  const errorClasses = classNames(
    `${interactionType}:ring-2`,
    `${interactionType}:ring-red-500`,
    `${interactionType}:border-red-500`,
    'focus:outline-none',
    'focus:ring-red-500',
    'ring-red-500',
    'ring-1',
    'border-red-300',
    'placeholder-red-300',
    baseWrapperClassName,
  );

  return {
    standardClasses,
    errorClasses,
  };
};
