import { Table } from '@/components';
import { Button } from '@/components/common';
import { useSelector, useDispatch } from 'react-redux';
import { manageSalesAreasLoadingSelector } from '@/redux/loading';
import {
  requestSalesAreasAsync,
  salesAreasSelector,
  salesAreasTotalSelector,
} from '@/redux/sales-areas';
import { useCallback, useEffect, useState } from 'react';
import ManageSalesAreaSlider from './ManageSalesAreaSlider';
import { getHeadRows, parseSalesAreasRows } from '../lib/utils';
import { salesAreasManagementConstants } from '../lib/constants';

const initialPageSize = 10;
const initialPage = 1;

const SalesAreasManagementTable = () => {
  const loading = useSelector(manageSalesAreasLoadingSelector);
  const salesAreasTotal = useSelector(salesAreasTotalSelector);
  const salesAreas = useSelector(salesAreasSelector);

  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(initialPageSize);
  const [selectedPage, setSelectedPage] = useState(initialPage);
  const [salesAreasRows, setSalesAreasRows] = useState([]);
  const [isManageAreaSliderOpen, setIsManageAreaSliderOpen] = useState(false);
  const [selectedArea, setSelectedArea] = useState(null);

  const getSalesAreas = useCallback(() => {
    const params = {
      page: {
        number: selectedPage,
        size: pageSize,
      },
    };

    dispatch(requestSalesAreasAsync.request(params));
  }, [dispatch, selectedPage, pageSize]);

  const onPageChange = useCallback(({ selected }) => {
    setSelectedPage(selected);
  }, []);

  const onSelectArea = useCallback(({ area }) => {
    setSelectedArea(area);
    setIsManageAreaSliderOpen(true);
  }, [setSelectedArea, setIsManageAreaSliderOpen]);

  const onCreateAreaClick = useCallback(() => {
    setSelectedArea(null);
    setIsManageAreaSliderOpen(true);
  }, [setIsManageAreaSliderOpen]);

  const onCloseManageAreaSlider = useCallback(() => {
    setIsManageAreaSliderOpen(false);
  }, [setIsManageAreaSliderOpen]);

  useEffect(() => {
    getSalesAreas();
  }, [getSalesAreas, pageSize, selectedPage]);

  useEffect(() => {
    if (!loading) {
      setSalesAreasRows(parseSalesAreasRows(salesAreas, onSelectArea));
    }
  }, [loading, onSelectArea, salesAreas]);

  return (
    <>
      <div className="p-2 gap-2 border-b bg-white self-stretch flex items-center justify-between">
        <div className="mr-4 flex items-center gap-6 ml-auto">
          <div className="flex items-center gap-4">
            <Button
              color="green"
              onClick={onCreateAreaClick}
              className="px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-aptivegreen"
            >
              {salesAreasManagementConstants.CREATE_AREA_BUTTON_LABEL}
            </Button>
          </div>
        </div>
      </div>
      <div className="px-8">
        <div className="mt-10">
          <Table
            isStickyHeader
            loading={loading}
            thead={{
              rows: getHeadRows(),
            }}
            tbody={{
              rows: salesAreasRows,
            }}
            paginator={{
              rowCount: salesAreasTotal,
              pageSize: pageSize,
              setPageSize: setPageSize,
              onPageChange: onPageChange,
              selectedPage: selectedPage,
              initialPage: initialPage,
            }}
          />
        </div>
        <ManageSalesAreaSlider
          isOpen={isManageAreaSliderOpen}
          onClose={onCloseManageAreaSlider}
          salesArea={selectedArea}
          getSalesAreas={getSalesAreas}
        />
      </div>
    </>
  );
};

export default SalesAreasManagementTable;
