import { CustomFormElement } from '@/components';
import { mapErrorToastsData } from '@/lib/api';
import { addFsExcludeClass } from '@/lib/utils';
import Api from '@/modules/Housing/api';
import { defaultSelectOption, ledgerConstants, payStatus } from '@/modules/Housing/lib';
import { paymentMethodsSelector, paymentTypesSelector } from '@/modules/Housing/redux/apartment';
import { branchesSummariesSelector } from '@/modules/Housing/redux/area';
import { dealersSelector } from '@/modules/Housing/redux/partnership';
import { addToastsAction } from '@/redux/toasts';
import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import MdSearch from './MdSearch';
import { useFeatureFlag } from 'configcat-react';

const {
  DEALER_NAME,
  DEALER_LABEL,
  BRANCH_NAME,
  BRANCH_LABEL,
  TEAM_NAME,
  TEAM_LABEL,
  PARTNERSHIP_NAME,
  PARTNERSHIP_LABEL,
  APARTMENT_IDS_NAME,
  APARTMENT_ID_LABEL,
  APARTMENT_ID_NAME,
  VENDOR_NAME,
  VENDOR_LABEL,
  PAYEE_NAME,
  PAYEE_LABEL,
  PAYMENT_TYPE_NAME,
  PAYMENT_TYPE_LABEL,
  PAYMENT_METHOD_NAME,
  PAYMENT_METHOD_LABEL,
  AMOUNT_TO_PAY_NAME,
  AMOUNT_TO_PAY_LABEL,
  FURNITURE_DAMAGED_NAME,
  FURNITURE_DAMAGED_LABEL,
  REP_UTILITIES_NAME,
  REP_UTILITIES_LABEL,
  APARTMENT_CHARGES_NAME,
  APARTMENT_CHARGES_LABEL,
  AMOUNT_PAID_NAME,
  AMOUNT_PAID_LABEL,
  DATE_PAID_NAME,
  DATE_PAID_LABEL,
  DATE_DUE_NAME,
  DATE_DUE_LABEL,
  IS_OVERNIGHT_PAYMENT_REQUEST_NAME,
  IS_OVERNIGHT_PAYMENT_REQUEST_LABEL,
  OVERNIGHT_PAYMENT_TOOLTIP,
  LEDGER_MD_HOLDER_FIELD_FLAG_NAME,
} = ledgerConstants;

const LedgerInfo = ({
  onPartnershipChange,
  onTeamChange,
  onBranchChange,
  onDealerChange,
  onChangeHandler,
  canEditField,
  ledgerId,
  payStatusId,
  recruitingSeasonId,
  onMdChange,
}) => {
  const { value: isLedgerMdHolderFieldEnabled } = useFeatureFlag(
    LEDGER_MD_HOLDER_FIELD_FLAG_NAME,
    false,
  );

  const dispatch = useDispatch();

  const dealers = useSelector(dealersSelector);
  const branches = useSelector(branchesSummariesSelector);
  const paymentTypes = useSelector(paymentTypesSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);

  const [partnerships, setPartnerships] = useState([]);
  const [isPartnershipsLoading, setIsPartnershipsLoading] = useState(false);

  const [teams, setTeams] = useState([]);
  const [isTeamsLoading, setIsTeamsLoading] = useState(false);

  const [apartments, setApartments] = useState([]);
  const [isApartmentsLoading, setIsApartmentsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext();
  const {
    [DEALER_NAME]: dealerId,
    [BRANCH_NAME]: branchId,
    [TEAM_NAME]: teamId,
    apartment_id,
    apartment_ids,
    amount_paid,
    amount_to_pay,
    furniture_damaged,
    rep_utilities,
    apartment_charges,
    is_overnight_payment_request,
  } = getValues();

  useEffect(() => {
    if (!dealerId) {
      return;
    }

    setIsPartnershipsLoading(true);

    Api.getPartnerships({
      ...(dealerId && { dealer_id: dealerId }),
      ...(recruitingSeasonId && { recruiting_season_id: recruitingSeasonId }),
    }).then(({ data }) => {
      const partnerships = data.map(({ attributes: { id, name } }) => ({
        value: id,
        name,
      }));

      setPartnerships(partnerships);

      setIsPartnershipsLoading(false);
    }).catch((error) => {
      setPartnerships([]);
      setIsPartnershipsLoading(false);

      dispatch(addToastsAction(mapErrorToastsData(error)));
    });
  }, [dealerId, recruitingSeasonId, dispatch]);

  useEffect(() => {
    if (!dealerId || !branchId) {
      return;
    }

    setIsTeamsLoading(true);

    Api.getTeamsSummaries({
      ...(dealerId && { dealer_id: dealerId }),
      ...(branchId && { branch_id: branchId }),
      ...(recruitingSeasonId && { recruiting_season_id: recruitingSeasonId }),
    }).then(({ data }) => {
      const teams = data.map(({ attributes: { id, name } }) => ({
        value: id,
        name,
      }));

      setTeams(teams);
      setIsTeamsLoading(false);
    }).catch((error) => {
      setTeams([]);
      setIsTeamsLoading(false);

      dispatch(addToastsAction(mapErrorToastsData(error)));
    });
  }, [dealerId, branchId, recruitingSeasonId, dispatch]);

  useEffect(() => {
    if (!teamId) {
      return;
    }

    setIsApartmentsLoading(true);

    Api.getApartmentSummaries({
      ...(teamId && { team_id: teamId }),
      ...(recruitingSeasonId && { recruiting_season_id: recruitingSeasonId }),
    }).then(({ data }) => {
      const apartments = data.map(({ attributes: { id, unit_id } }) => ({
        value: id,
        label: unit_id,
      }));

      setApartments(apartments);
      setIsApartmentsLoading(false);
    }).catch((error) => {
      setApartments([]);
      setIsApartmentsLoading(false);

      dispatch(addToastsAction(mapErrorToastsData(error)));
    });
  }, [teamId, recruitingSeasonId, dispatch]);

  const teamSelectOptions = useMemo(() => [
    {
      value: '',
      name: dealerId && branchId ? '-- Select --' : '-- Select Dealer and Branch First --',
    },
    ...teams,
  ], [dealerId, branchId, teams]);

  const partnershipSelectOptions = useMemo(() => [
    { value: '', name: dealerId ? '-- Select --' : '-- Select Dealer First --' },
    ...partnerships,
  ], [dealerId, partnerships]);

  return (
    <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
      <CustomFormElement
        colSpan={3}
        id={DEALER_NAME}
        name={DEALER_NAME}
        label={DEALER_LABEL}
        type="select"
        selectOptions={[...defaultSelectOption, ...dealers]}
        onChange={onDealerChange}
        register={register}
        error={errors?.[DEALER_NAME]}
        disabled={!canEditField(DEALER_NAME)}
        required
        className={addFsExcludeClass()}
      />
      <CustomFormElement
        colSpan={3}
        id={BRANCH_NAME}
        name={BRANCH_NAME}
        label={BRANCH_LABEL}
        type="select"
        selectOptions={[...defaultSelectOption, ...branches]}
        onChange={onBranchChange}
        register={register}
        error={errors?.[BRANCH_NAME]}
        disabled={!canEditField(BRANCH_NAME)}
        required
        className={addFsExcludeClass()}
      />
      <CustomFormElement
        colSpan={3}
        id={TEAM_NAME}
        name={TEAM_NAME}
        label={TEAM_LABEL}
        type="select"
        selectOptions={teamSelectOptions}
        onChange={onTeamChange}
        register={register}
        error={!canEditField(TEAM_NAME) || !dealerId || !branchId ? null : errors?.team_id}
        disabled={!canEditField(TEAM_NAME) || !dealerId || !branchId}
        required
        className={addFsExcludeClass()}
        loading={isTeamsLoading}
      />
      <CustomFormElement
        colSpan={3}
        id={PARTNERSHIP_NAME}
        name={PARTNERSHIP_NAME}
        label={PARTNERSHIP_LABEL}
        type="select"
        selectOptions={partnershipSelectOptions}
        onChange={onPartnershipChange}
        register={register}
        error={!canEditField(PARTNERSHIP_NAME) || !dealerId ? null : errors?.partnership_id}
        disabled={!canEditField(PARTNERSHIP_NAME) || !dealerId}
        required
        className={addFsExcludeClass()}
        loading={isPartnershipsLoading}
      />
      <CustomFormElement
        colSpan={3}
        id={ledgerId ? APARTMENT_ID_NAME : APARTMENT_IDS_NAME}
        name={ledgerId ? APARTMENT_ID_NAME : APARTMENT_IDS_NAME}
        label={APARTMENT_ID_LABEL}
        type="multiSelect"
        isMulti={!ledgerId}
        options={teamId ? apartments : []}
        onChange={onChangeHandler}
        placeholder={teamId ? '-- Select --' : '-- Select Team First --'}
        value={ledgerId ? apartment_id : apartment_ids}
        register={register}
        error={
          !canEditField(ledgerId ? APARTMENT_ID_NAME : APARTMENT_IDS_NAME) || !teamId ?
            null : errors?.[ledgerId ? APARTMENT_ID_NAME : APARTMENT_IDS_NAME]
        }
        disabled={
          !canEditField(ledgerId ? APARTMENT_ID_NAME : APARTMENT_IDS_NAME) || !teamId
        }
        required
        className={addFsExcludeClass()}
        styles={{
          singleValue: (provided) => ({ ...provided, color: 'black' }),
          placeholder: (provided) => ({
            ...provided,
            color: !canEditField(ledgerId ? APARTMENT_ID_NAME : APARTMENT_IDS_NAME) || !teamId ?
              'inherit': 'black',
          }),
        }}
        formElementWrapperClassName="text-black"
        loading={isApartmentsLoading}
      />
      {isLedgerMdHolderFieldEnabled && (
        <MdSearch {...{ canEditField, errors, onMdChange }} />
      )}
      <CustomFormElement
        colSpan={3}
        id={VENDOR_NAME}
        name={VENDOR_NAME}
        label={VENDOR_LABEL}
        type="text"
        onChange={onChangeHandler}
        register={register}
        disabled={!canEditField(VENDOR_NAME)}
        error={errors?.vendor_number}
        className={addFsExcludeClass()}
      />
      <CustomFormElement
        colSpan={3}
        id={PAYMENT_TYPE_NAME}
        name={PAYMENT_TYPE_NAME}
        label={PAYMENT_TYPE_LABEL}
        type="select"
        selectOptions={paymentTypes}
        onChange={onChangeHandler}
        register={register}
        disabled={!canEditField(PAYMENT_TYPE_NAME)}
        error={errors?.payment_type_id}
        className={addFsExcludeClass()}
      />
      <CustomFormElement
        colSpan={3}
        id={PAYMENT_METHOD_NAME}
        name={PAYMENT_METHOD_NAME}
        label={PAYMENT_METHOD_LABEL}
        type="select"
        selectOptions={paymentMethods}
        onChange={onChangeHandler}
        register={register}
        disabled={!canEditField(PAYMENT_METHOD_NAME)}
        error={errors?.payment_method_id}
        className={addFsExcludeClass()}
      />
      <CustomFormElement
        colSpan={3}
        label={PAYEE_LABEL}
        id={PAYEE_NAME}
        name={PAYEE_NAME}
        type="text"
        register={register}
        disabled={!canEditField(PAYEE_NAME)}
        error={errors?.[PAYEE_NAME]}
        onChange={onChangeHandler}
        className={addFsExcludeClass()}
      />
      <CustomFormElement
        colSpan={3}
        id={AMOUNT_TO_PAY_NAME}
        name={AMOUNT_TO_PAY_NAME}
        label={AMOUNT_TO_PAY_LABEL}
        type="currency"
        value={amount_to_pay?.toString()}
        mask={{
          allowNegative: true,
          allowDecimal: true,
          decimalSymbol: '.',
          decimalLimit: 2,
        }}
        onChange={onChangeHandler}
        register={register}
        disabled={!canEditField(AMOUNT_TO_PAY_NAME)}
        error={errors?.amount_to_pay}
        className={addFsExcludeClass()}
      />
      <CustomFormElement
        colSpan={3}
        id={FURNITURE_DAMAGED_NAME}
        name={FURNITURE_DAMAGED_NAME}
        label={FURNITURE_DAMAGED_LABEL}
        type="currency"
        value={furniture_damaged?.toString()}
        mask={{
          allowNegative: true,
          allowDecimal: true,
          decimalSymbol: '.',
          decimalLimit: 2,
        }}
        onChange={onChangeHandler}
        register={register}
        disabled={!canEditField(FURNITURE_DAMAGED_NAME)}
        error={errors?.furniture_damaged}
        className={addFsExcludeClass()}
      />
      <CustomFormElement
        colSpan={3}
        id={REP_UTILITIES_NAME}
        name={REP_UTILITIES_NAME}
        label={REP_UTILITIES_LABEL}
        type="currency"
        value={rep_utilities?.toString()}
        mask={{
          allowNegative: true,
          allowDecimal: true,
          decimalSymbol: '.',
          decimalLimit: 2,
        }}
        onChange={onChangeHandler}
        register={register}
        disabled={!canEditField(REP_UTILITIES_NAME)}
        error={errors?.rep_utilities}
        className={addFsExcludeClass()}
      />
      <CustomFormElement
        colSpan={3}
        id={APARTMENT_CHARGES_NAME}
        name={APARTMENT_CHARGES_NAME}
        label={APARTMENT_CHARGES_LABEL}
        type="currency"
        value={apartment_charges?.toString()}
        mask={{
          allowDecimal: true,
          decimalSymbol: '.',
          decimalLimit: 2,
          allowNegative: true,
        }}
        onChange={onChangeHandler}
        register={register}
        disabled={!canEditField(APARTMENT_CHARGES_NAME)}
        error={errors?.apartment_charges}
        className={addFsExcludeClass()}
      />
      <CustomFormElement
        colSpan={3}
        id={AMOUNT_PAID_NAME}
        name={AMOUNT_PAID_NAME}
        label={AMOUNT_PAID_LABEL}
        type="currency"
        value={amount_paid?.toString()}
        mask={{
          allowNegative: true,
          allowDecimal: true,
          decimalSymbol: '.',
          decimalLimit: 2,
        }}
        onChange={onChangeHandler}
        register={register}
        disabled={!canEditField(AMOUNT_PAID_NAME)}
        error={errors?.amount_paid}
        className={addFsExcludeClass()}
      />
      <CustomFormElement
        colSpan={3}
        id={DATE_PAID_NAME}
        name={DATE_PAID_NAME}
        label={DATE_PAID_LABEL}
        type="date"
        onChange={onChangeHandler}
        showYearDropdown
        register={register}
        disabled={!canEditField(DATE_PAID_NAME)}
        error={errors?.date_paid}
        className={addFsExcludeClass()}
      />
      <CustomFormElement
        colSpan={3}
        id={DATE_DUE_NAME}
        name={DATE_DUE_NAME}
        label={DATE_DUE_LABEL}
        type="date"
        onChange={onChangeHandler}
        showYearDropdown
        register={register}
        disabled={!canEditField(DATE_DUE_NAME)}
        error={errors?.date_due}
        className={addFsExcludeClass()}
      />
      <div data-for={'overnight_payment'} data-tip={OVERNIGHT_PAYMENT_TOOLTIP} className="inline sm:col-span-3">
        <CustomFormElement
          colSpan={3}
          label={IS_OVERNIGHT_PAYMENT_REQUEST_LABEL}
          type="checkbox"
          className="w-4 h-4 text-indigo-600 border-gray-300 rounded"
          id={IS_OVERNIGHT_PAYMENT_REQUEST_NAME}
          register={register}
          name={IS_OVERNIGHT_PAYMENT_REQUEST_NAME}
          panelWrapperClassName="justify-items-end"
          error={errors?.is_overnight_payment_status}
          onChange={onChangeHandler}
          disabled={!canEditField(IS_OVERNIGHT_PAYMENT_REQUEST_NAME) && payStatusId === payStatus[0].value}
          checked={is_overnight_payment_request}
        />
        <ReactTooltip
          className="normal-case"
          id={'overnight_payment'}
          place="top"
          effect="solid"
        />
      </div>
    </div>
  );
};

LedgerInfo.propTypes = {
  canEditField: PropTypes.func,
  onTeamChange: PropTypes.func,
  onBranchChange: PropTypes.func,
  onDealerChange: PropTypes.func,
  onChangeHandler: PropTypes.func,
  onMdChange: PropTypes.func,
  ledgerId: PropTypes.number,
  payStatusId: PropTypes.number,
  recruitingSeasonId: PropTypes.number,
};

export default LedgerInfo;
