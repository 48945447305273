export const usersManagementConstants = {
  LEAD_MANAGEMENT_SEARCH_NAME: 'lead',
  SEARCH_USERS: 'Search Users',
  CREATE_USER: 'Create User',

  ARCHIVED_USER_TYPE: 'user',

  VIEW_USER_BUTTON: 'View User',
  QUIT_FORM_CONFIRMATION_BUTTON: 'Quit Form',
  RETURN_TO_FORM_BUTTON: 'Return to Form',
  QUIT_FORM_CONFIRMATION_TITLE: 'Quit Form Confirmation',
  QUIT_FORM_CONFIRMATION_MESSAGE: 'Are you sure you want to quit without saving?',
};

export const repTypeSelectOptions = [
  {
    label: 'Users',
    value: usersManagementConstants.ARCHIVED_USER_TYPE,
  },
];
