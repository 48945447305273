import { CustomFormElement } from '@/components';
import { addFsExcludeClass } from '@/lib/utils';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { salesChannelsConstants } from '@/modules/AdminTools/lib/constants';

const {
  NAME,
  NAME_LABEL,
  STATUS,
  STATUS_LABEL,
} = salesChannelsConstants;

const SalesChannelInfo = ({
  onChangeHandler,
}) => {
  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext();
  const {
    [STATUS]: status,
  } = getValues();

  return (
    <div className="grid grid-cols-1 mt-3 gap-y-6 gap-x-4">
      <CustomFormElement
        colSpan={3}
        id="sales-channel-name"
        name={NAME}
        label={NAME_LABEL}
        type="text"
        onChange={onChangeHandler}
        register={register}
        error={errors?.[NAME]}
        className={addFsExcludeClass()}
        required
      />
      <CustomFormElement
        colSpan={3}
        label={STATUS_LABEL + (status ? ' (Active)' : ' (Inactive)')}
        type="checkbox"
        className="w-4 h-4 text-indigo-600 border-gray-300 rounded"
        id="sales-channel-status"
        register={register}
        name={STATUS}
        panelWrapperClassName="justify-items-end"
        error={errors?.[STATUS]}
        onChange={onChangeHandler}
      />
    </div>
  );
};

SalesChannelInfo.propTypes = {
  onChangeHandler: PropTypes.func,
};

export default SalesChannelInfo;
