import * as Api from '../api';

const api = process.env.REACT_APP_ONB_API;

export const deleteUser = (userId) => Api.remove({ path: `/api/v1/users/${userId}`, api });

export const restoreUser = (userId) => Api.put({ path: `/api/v1/users/${userId}/restore`, api });

export const createUser = Api.post({ path: '/api/v1/users', api });

export const updateUser = (userId)  => Api.put({ path: `/api/v1/users/${userId}`, api });

export const getUsers = () => Api.get({ path: '/api/v1/users/all', api });

export const getUser = (userId) => Api.get({ path: `/api/v1/users/${userId}`, api });

export const getUserGroups = () => Api.get({ path: '/api/v1/user-groups', api });

export const updateMdHolderStatus = (userId) => Api.post({ path: `/api/v1/users/${userId}/update-md-holder-status`, api });

export const updateUserSalesChannels = (userId) => Api.put({ path: `/api/v1/users/${userId}/update-sales-channels`, api });
