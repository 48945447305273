import * as yup from 'yup';
import {
  REQUIRED,
  MIN_2_CHARS,
  MAX_255_CHARS,
  LATITUDE_REGEX,
  LONGITUDE_REGEX,
} from '@/lib/validations';
import { salesAreasManagementConstants } from './constants';

const {
  AREA_NAME_VALUE,
  AREA_LAT_VALUE,
  AREA_LONG_VALUE,
  AREA_VISIBLE_VALUE,
  AREA_LAT_LABEL,
  AREA_LONG_LABEL,
  AREA_TIMEZONE_VALUE,
} = salesAreasManagementConstants;

export const validationSchema = yup.object().shape({
  [AREA_NAME_VALUE]: yup
    .string()
    .checkWhiteSpacesOnly(REQUIRED)
    .required(REQUIRED)
    .min(2, MIN_2_CHARS)
    .max(255, MAX_255_CHARS),
  [AREA_LAT_VALUE]: yup
    .string()
    .required(REQUIRED)
    .test(
      'lat-regex',
      `Invalid ${AREA_LAT_LABEL}`,
      (value) => value && value.toString().match(LATITUDE_REGEX),
    ),
  [AREA_LONG_VALUE]: yup
    .string()
    .required(REQUIRED)
    .test(
      'long-regex',
      `Invalid ${AREA_LONG_LABEL}`,
      (value) => value && value.toString().match(LONGITUDE_REGEX),
    ),
  [AREA_TIMEZONE_VALUE]: yup
    .string()
    .required(REQUIRED),
  [AREA_VISIBLE_VALUE]: yup
    .bool()
    .required(REQUIRED),
});
